import React from 'react'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from './GetDroneSlider.module.css'
import DroneCard from '../DroneCard/DroneCard';
import toolkit from '../../assets/store/toolkit.png'
import drone from '../../assets/store/drone.png'
import box from '../../assets/store/box.png'


function CustomPrevArrow(props) {
    const { onClick } = props;
    return (
        <button
            // className="custom-arrow-banner custom-prev-arrow-banner"
            className={`${styles.customarrow} ${styles.customprevarrow}`}
            onClick={onClick}
        >
            <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
        </button>
    );
}


function CustomNextArrow(props) {
    const { onClick } = props;
    return (
        <button
            // className="custom-arrow-banner custom-next-arrow-banner"
            className={`${styles.customarrow} ${styles.customnextarrow}`}
            onClick={onClick}
        >
            <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
        </button>
    );
}

var settings = {
    className: styles.slickstyles,
    dots: true,
    // autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: false,
    // fade: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};
function GetDroneSlider() {
    return (<>

        <div className={styles.container}>

            <h2 className={styles.heading}>Things You Will GetWith This Drone</h2>
            <div className={styles.cardCon}>

                <Slider {...settings}>
                    {data.map((item) => {
                        return (



                            <DroneCard data={item} />

                        )
                    })}

                </Slider>




            </div>
        </div>
    </>)
}

export default GetDroneSlider
const data = [
    {
        id: 1,
        head: " Tool kit",
        name: " Maintenance Kit ",
        img: toolkit
    },
    {
        id: 2,
        head: "Drone Buddy ",
        name: " Remote Controller ",
        img: drone
    },
    {
        id: 1,
        head: " Drone Box",
        name: " Easy To Carry ",
        img: box
    },
]