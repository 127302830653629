import React, { useEffect, useRef, useState } from "react";
import PhoneIcon from "../../assets/images/bxs_phone-call.svg";
import MailIcon from "../../assets/images/footermail.png";
import LocationIcon from "../../assets/images/footerloction.png";
import MapImage from "../../assets/images/footerMap.png";
import fb from "../../assets/images/icons8-facebook 1.svg";
import insta from "../../assets/images/icons8-instagram.svg";
import linkedin from "../../assets/images/icons8-linkedin.svg";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { api_call } from "../../Utils/Network";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: "#fff",
}));
const Footer = () => {
  const [contactInfo, setContactInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    // try {
    //   const response = await api_call.get("/products/v1/aerobot/contact/us");

    //   const data = response?.data?.data;
    //   // console.log(homePageData, 'homePageData');
    //   if (data !== undefined || data !== null) {
    //     setContactInfo(data[0]);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    api_call
    .get(`/products/v1/aerobot/contact/us`)
    .then((res)=>{
      const data = res?.data?.data;
      // console.log(homePageData, 'homePageData');
      if (data !== undefined || data !== null) {
        setContactInfo(data[0]);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "auto",
        height: "auto",
        // backgroundColor: "#1F1F1F",
        background: `linear-gradient(180deg, #216FB9 0%, #000000 100%)`,

        // borderRadius: "64px 64px 0 0",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        margin={0}
        width={"100%"}
      >
        <Grid item xs={12} md={12} lg={4}>
          <Item>
            <div className={styles.column1}>
              <h1>AEROBOTT</h1>
              <p className={styles.content}>
                Our company, Dronetech Solution Private Limited is a
                Mumbai-based Indian UAV manufacturing and training company that
                creates professional drone solutions for businesses and the
                public. The company was founded to expand the flying school
                industry and provide RPAS training by globally accepted
                standards. In India, we will design, prototype, and manufacture
                all of our products.
              </p>
            </div>
            <div className={styles.footer_linkMob}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={4}
              >
                <Grid item sm={12} md={12}>
                  <Item>
                    <div className={styles.footer_nav}>
                      <h2 className={styles.Link_head}>Explore</h2>
                      <ul className={styles.footer_links_list}>
                        <li>
                          <Link>Courses</Link>
                        </li>
                        <li>
                          <Link>Certified Courses</Link>
                        </li>
                        <li>
                          <Link>Industry Solutions</Link>
                        </li>
                        <li>
                          <Link>Hire a Pilot</Link>
                        </li>
                      </ul>
                    </div>
                  </Item>
                </Grid>
                <Grid item sm={12} md={12}>
                  <Item>
                    <div className={styles.footer_nav}>
                      <h2 className={styles.Link_head}>General</h2>
                      <ul className={styles.footer_links_list}>
                        <li>
                          <Link>About Us</Link>
                        </li>
                        <li>
                          <Link>Support</Link>
                        </li>
                        <li>
                          <Link>Privacy Policy</Link>
                        </li>
                        <li>
                          <Link>Refund Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </div>
            <div className={styles.column1_flex}>
              <div className={styles.col_1}>
                <img src={PhoneIcon} alt="PhoneIcon" />
              </div>
              <div className={styles.col_2}>
              {/* <a
                  href={`tel:+91 9004711184`}
                  className={styles.contactText}
                >
                  +91 9004711184
                </a> */}
                <a
                  href={`tel:${contactInfo?.contact_info}`}
                  className={styles.contactText}
                >
                  {contactInfo?.contact_info}
                </a>
                {/* <p> +91 9004711184</p> */}
              </div>
            </div>
            <div className={styles.column1_flex}>
              <div className={styles.col_1}>
                <img src={MailIcon} alt="MailIcon" />
              </div>
              <div className={styles.col_2}>
              {/* <a
                  href={`mailto:info@aerobott.com`}
                  className={styles.contactText}
                >
                  info@aerobott.com
                </a> */}
                <a
                  href={`mailto:${contactInfo?.email}`}
                  className={styles.contactText}
                >
                  {contactInfo?.email}
                </a>
                {/* <p>info@aerobott.com</p> */}
              </div>
            </div>
            <div className={styles.column1_flex}>
              <div className={styles.col_1}>
                <img src={LocationIcon} alt="LocationIcon" />
              </div>
              <div className={styles.col_2}>
              {/* <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    "35/151, 1st floor, shree Laxmi Vijay Industrial premises, new link road, sab tv lane, andheri west mumbai 400053 Landmark-opp. Hotel shabri")}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactText}
                >
                  35/151, 1st floor, shree Laxmi Vijay Industrial premises, new
                  link road, sab tv lane, andheri west mumbai 400053 Landmark-
                  opp. Hotel shabri
                </a> */}
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    contactInfo?.location
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactText}
                >
                  {contactInfo?.location}
                </a>
                {/* <p>
                  35/151, 1st floor, shree Laxmi Vijay Industrial premises, new
                  link road, sab tv lane, andheri west mumbai 400053 Landmark-
                  opp. Hotel shabri
                </p> */}
              </div>
            </div>
          </Item>
        </Grid>
        <Grid item xs={2} md={3} lg={2}>
          <Item>
            <div className={styles.footer_links}>
              <h2 className={styles.Link_head}>Explore</h2>
              <ul className={styles.footer_links_list}>
                <li>
                  <Link>Courses</Link>
                </li>
                <li>
                  <Link>Certified Courses</Link>
                </li>
                <li>
                  <Link>Industry Solutions</Link>
                </li>
                <li>
                  <Link>Hire a Pilot</Link>
                </li>
              </ul>
            </div>
          </Item>
        </Grid>
        <Grid item xs={2} md={3} lg={2}>
          <Item>
            <div className={styles.footer_links}>
              <h2 className={styles.Link_head}>General</h2>
              <ul className={styles.footer_links_list}>
                <li>
                  <Link>About Us</Link>
                </li>
                <li>
                  <Link>Support</Link>
                </li>
                <li>
                  <Link>Privacy Policy</Link>
                </li>
                <li>
                  <Link>Refund Policy</Link>
                </li>
              </ul>
            </div>
          </Item>
        </Grid>
        <Grid item xs={7} md={5} lg={4}>
          <Item>
            <div className={styles.column4}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.355642226416!2d72.82450337601257!3d19.13590363208101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6186a1d2871%3A0x9672e4c4fc228fa6!2sLaxmi%20Vijay%2C%20SAB%20TV%20Rd%2C%20Laxmi%20Industrial%20Estate%2C%20Suresh%20Nagar%2C%20Andheri%20West%2C%20Mumbai%2C%20Maharashtra%20400102!5e0!3m2!1sen!2sin!4v1694083075166!5m2!1sen!2sin"
                width="90%"
                height="300"
                style={{ border: 0, borderRadius: "20px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <img src={MapImage} alt="PhoneIcon" /> */}
            </div>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <div className={styles.socialMediaLinks}>
              <a href="https://www.facebook.com/Aerobott/" target="_blank"><img src={fb} alt="fb" /></a>
              <a href="https://www.instagram.com/aerobott_drone/?igshid=MTk0NTkyODZkYg%3D%3D" target="_blank"><img src={insta} alt="insta" /></a>
              <a href="https://www.linkedin.com/company/aerobott/" target="_blank"><img src={linkedin} alt="linkedin" /></a>
              <p className="copyright">All Rights Reserved | Aerobott 2023</p>
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Footer;
