import React from 'react'
import styles from './Offers.module.css'
import offerimg from '../../assets/battery/offerimg.png'
import { Button } from '@mui/material'
function Offers() {
    return (
        <div className={styles.container}>

            <h2 className={styles.head}>Special Offers</h2>
            <div className={styles.coupon}>
                {data.map((item) => {
                    return (


                        <div className={styles.couponCon}>

                            <div className={styles.details}>
                                <div >
                                    <img src={offerimg}></img>
                                </div>
                                <div>


                                    <p className={styles.name}>Save 120 More By Adding this Book</p>
                                    <span>CODE: TRYGK</span>
                                </div>
                            </div>
                            <Button variant='text' className={styles.apply}>Apply</Button>
                        </div>
                    )
                })}
            </div>
            <div className={styles.show}>
                <Button variant='text' >Show More</Button>
            </div>
        </div>
    )
}

export default Offers
const data = [
    { id: 1 },
    { id: 2 }
]