import React from "react";
import styles from "./index.module.css";
import img1 from "../../../assets/images/achievement/img1.png";
import img2 from "../../../assets/images/achievement/img2.png";
import img3 from "../../../assets/images/achievement/img3.png";
import img4 from "../../../assets/images/achievement/img4.png";
import img5 from "../../../assets/images/achievement/img5.png";
import img6 from "../../../assets/images/achievement/img6.png";

function Aboutus() {
  return (
    <section className={styles.aboutUs}>
      <div className={styles.leftContainer}>
        <h3 className={styles.sectionHeading}>Why Choose Aerobott ?</h3>
        <p className={styles.para}>
          Aerobott is a leading manufacturer and seller of training and
          surveillance drones used across different industries. We also provide
          drones as a service for Agricultural Services, Survey and Mapping,
          Photography, Videography and more.
        </p>
        <p className={styles.para}>
          In addition, we call out all drone enthusiasts to our one-stop drone
          training platform. We are your destination for becoming a licensed and
          DGCA-certified drone pilot. Our 5-day drone pilot training course is
          focused on equipping our students with the skills and techniques
          necessary for using it across various industries.
        </p>
      </div>
      <div className={styles.rightContainer}>
        {datanew?.map((v) => {
          return (
            <>
              <div className={styles.card}>
                <div className={styles.imgContainer}>
                  <img src={v?.img} alt={v?.title} />
                </div>
                <h4>{v?.title}</h4>
              </div>
            </>
          );
        })}
      </div>
    </section>
  );
}

export default Aboutus;

const datanew = [
  {
    id: 1,
    img: img1,
    title: "top drone pilot training courses in India",
  },
  {
    id: 2,
    img: img2,
    title: "More than 100+ satisfied customers",
  },
  {
    id: 3,
    img: img3,
    title: "Manufacturers and sells Drones",
  },
  {
    id: 4,
    img: img4,
    title: "Hire our Drone as a Service",
  },
  {
    id: 5,
    img: img5,
    title: "Job Assistance",
  },
  {
    id: 6,
    img: img6,
    title: "Get RCP Certificate",
  },
];
