import React from 'react'
import styles from './index.module.css'

const SpecializationComponent = ({specializesData }) => {
  return (
    <div className={styles.specializes}>
          <h3 className={styles.pilotName}>Specializes in </h3>
          <div className={styles.specializeBoxs}>
            {specializesData.map((info) => (
              <div className={styles.specializeBox}>
                <p>{info.spcial}</p>
              </div>
            ))}
          </div>
        </div>
  )
}

export default SpecializationComponent