import React, { useState } from 'react'
import styles from './index.module.css'
import wlpic1 from '../../../assets/images/wlpic1.png'
import wlpic2 from '../../../assets/images/wlpic2.png'

function Whishlist() {
  const [wishlist, setWishlist] = useState(wishlistapi)
  const incrementCounter = (itemId) => {
    const updatedWishlist = wishlist.map((item) => {
      if (item.id === itemId) {
        return { ...item, qty: (item.qty || 0) + 1 }
      }
      return item
    })
    console.log('Incrementing counter:', updatedWishlist)
    setWishlist(updatedWishlist)
  }

  const decrementCounter = (itemId) => {
    const updatedWishlist = wishlist.map((item) => {
      if (item.id === itemId && item.qty > 1) {
        return { ...item, qty: item.qty - 1 }
      }
      return item
    })
    console.log('Decrementing counter:', updatedWishlist)
    setWishlist(updatedWishlist)
  }

  console.log('Rendered with wishlist:', wishlist)
  return (
    <div className={styles.WhishlistBox}>
      <h2 className={styles.heading}>Wishlist</h2>
      {wishlistapi.map((infolist) => {
        console.log(infolist, 'infolist')

        return (
          <div key={infolist?.id} className={styles.WhishListContainer}>
            <div className={styles.wlimg}>
              <img src={infolist?.Image} alt={infolist?.WLname} />
            </div>
            <div className={styles.wldetails}>
              <div className={styles.mobileShow}>
              <h4 className={styles.wlname}>{infolist?.WLname}</h4>
              <p className={styles.wlPrice}>{infolist?.wlPrice}</p>
              <p className={styles.batchTime}>{infolist?.WLBatch}</p>
              <div className={styles.dayslist}>
                {infolist?.days?.map((info) => {
                  return (
                    <>
                      <span className={styles.days} key={info?.id}>
                        {' '}
                        {info?.day}{' '}
                      </span>
                    </>
                  )
                })}
              </div>
              </div>
              {infolist?.Batchtype === 1 && <p className={styles.onlineBatch}>Online</p> }
              {infolist?.Batchtype === 2 && <p className={styles.offlineBatch}>Offline</p> }
              
            </div>
            <div className={styles.quntntityBTN}>
              <div className={styles.incdecBtn}>
                <button
                  className={styles.PMBtn}
                  onClick={() => incrementCounter(infolist.id)}
                >
                  +
                </button>
                <h5>{wishlist[infolist?.id - 1]?.qty}</h5>
                <button
                  className={styles.PMBtn}
                  onClick={() => decrementCounter(infolist.id)}
                >
                  -
                </button>
              </div>
            </div>
            <div className={styles.btnsGroup}>
              <button className={styles.buyBtn}>Buy Now</button>
              <button className={styles.removeBtn}>Remove from cart</button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Whishlist

const wishlistapi = [
  {
    id: 1,
    Image: wlpic1,
    WLname: 'Training Program 01 ',
    wlPrice: '₹ 55,000.00',
    qty: 3,
    WLBatch: 'Batch 01',
    days: [
      {
        id: 1,
        day: 'MON',
      },
      {
        id: 2,
        day: 'WED',
      },
      {
        id: 3,
        day: 'FRI',
      },
    ],
    Batchtype: 1,
  },
  {
    id: 2,
    Image: wlpic2,
    WLname: 'Drone Name 01',
    wlPrice: '₹ 70,000.00',
    qty: 1,
  },
  {
    id: 3,
    Image: wlpic1,
    WLname: 'Drone Name 02',
    wlPrice: '₹ 50,000.00',
    qty: 6,
  },
  {
    id: 4,
    Image: wlpic2,
    WLname: 'Training Program 02 ',
    wlPrice: '₹ 65,000.00',
    qty: 2,
    WLBatch: 'Batch 03',
    days: [
      {
        id: 1,
        day: 'MON',
      },
      {
        id: 2,
        day: 'THU',
      },
    ],
    Batchtype: 2,
  },
]
