import React, { useState } from 'react'
import styles from './Product.module.css'
import drone from '../../assets/battery/drone.png'

function Product() {
    const [quantities, setQuantities] = useState(data.map(() => 0))

    const increment = (index) => {
        const newQuantities = [...quantities]
        newQuantities[index] += 1
        setQuantities(newQuantities)
    }

    const decrement = (index) => {
        const newQuantities = [...quantities]
        newQuantities[index] -= 1
        setQuantities(newQuantities)
    }

    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <thead>
                    <tr className={styles.tableHeading}>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {data.map((item, index) => {
                        return (
                            <tr key={item.id} className={styles.data}>
                                <td className={styles.abouttd}>
                                    <div className={styles.about}>
                                        <div>
                                            <img src={item.img} alt={item.name} />
                                        </div>
                                        <p>
                                            <span className={styles.desc}>{item.name}</span>
                                            <br />
                                            <span className={styles.desc}>{item.about}</span>
                                        </p>
                                    </div>
                                </td>
                                <td className={styles.price}>
                                    <span className={styles.off}>{item.off}</span>
                                    <p>{item.price}</p>
                                </td>
                                <td className={styles.quantity}>
                                    <div className={styles.numbers}>
                                        <button onClick={() => decrement(index)}>-</button>
                                        {quantities[index]}
                                        <button onClick={() => increment(index)}>+</button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Product

const data = [
    {
        id: 1,
        img: drone,
        name: "Drone Training: ",
        about: "50 Drills to Improve Your Flying Skills",
        off: "₹ 5000",
        price: "₹38,232",
    },
    {
        id: 2,
        img: drone,
        name: "Drone Training: ",
        about: "50 Drills to Improve Your Flying Skills",
        off: "₹ 5000",
        price: "₹38,232",
    },
]
