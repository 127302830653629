import React, { useEffect } from 'react'
import styles from "./index.module.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from '@mui/material';
import service1 from "../../../assets/NewHomePage/1st.png"
import { useNavigate } from 'react-router-dom';

function CustomPrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className="custom-arrow-banner custom-prev-arrow-banner"
        className={`${styles.customarrow} ${styles.customprevarrow}`}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
      </button>
    );
  }
  
  function CustomNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className="custom-arrow-banner custom-next-arrow-banner"
        className={`${styles.customarrow} ${styles.customnextarrow}`}
        onClick={onClick}
      >
        <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
      </button>
    );
  }
  
  var settings = {
    className: styles.slickstyles,
    // dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    // fade: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

function OurServices({data}) {

  console.log(data, "OurServices");
  const navigate = useNavigate();


  const handelnav = (cardData) => {
      if(cardData?.title == "Drone Pilot Training"){
        navigate(`/courses`)
      }
  }
 
  return (
    <>
      <Slider {...settings}>
      {
              data?.map((info, i) => {
                return (
                  <>
                    <div className={styles.card} key={info?.id}>

                      <div className={styles.cardImg}>
                        <img src={info?.image} alt={info?.title} />
                      </div>
                      <h4 className={styles.cardTitle}>{info?.title}</h4>
                      <p className={styles.cardText}>{info?.description}</p>
                      <Button variant='outlined' onClick={() => handelnav(info)} className={styles.outLinedBtn}>Learn more</Button>
                    </div>
                  </>
                )
              })
            }
      </Slider>
    </>
  )
}

export default OurServices
