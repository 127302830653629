import React, { useState } from 'react'
import styles from './index.module.css'
import { OfflineBatch, OnlineBatch } from '../BatchComponent/BatchComponent'

const DroneOrderComponent = ({ info, onUpdateCounter, onCancel }) => {
  const [counter, setCounter] = useState(1)
  const incrementCounter = () => {
    setCounter(counter + 1)
    onUpdateCounter(counter + 1)
  }

  const decrementCounter = () => {
    if (counter > 1) {
      setCounter(counter - 1)
      onUpdateCounter(counter - 1)
    }
  }
  console.log(info?.isCouers, "showhide data");
  const showthis = info.isCouers
  return (
    <div key={info.id} className={styles.orderBox}>
      <div className={styles.leftside}>
        <div className={styles.droneImg}>
          <img src={info?.img} alt="" />
        </div>
        <div className={styles.nameAmount}>
          <p className={styles.dName}>{info?.dName}</p>
          <p className={styles.dAmount}> ₹ {info?.dPrice}</p>
          {showthis && (<div className={styles.couresdetails}>
            <p className={styles.orderBatch}>{info?.WLBatch}</p>
              {
                info?.days.map((days) =>{
                  return(
                    <span>{days.day}</span>
                  )
                })
              }
            {info?.Batchtype === 1 && <OnlineBatch />}
              {info?.Batchtype === 2 && <OfflineBatch /> }
          </div>)}
          {!showthis && (<div className={styles.mobileOn}>
            <p className={styles.qtyHeading}> Qty</p>
            <p className={styles.qtyShow}>{counter} </p>
            {/* <div className={styles.incdecBtn}>
              <button className={styles.PMBtn} onClick={incrementCounter}>
                +
              </button>
              <h5>{counter}</h5>
              <button className={styles.PMBtn} onClick={decrementCounter}>
                -
              </button>
            </div> */}
          </div>)}
          
        </div>
      </div>
      <div className={styles.center}>
        <p className={styles.qtyHeading}> Qty</p>
        <p className={styles.qtyShow}>{counter} </p>
        {/* <div className={styles.incdecBtn}>
          <button className={styles.PMBtn} onClick={incrementCounter}>
            +
          </button>
          <h5>{counter}</h5>
          <button className={styles.PMBtn} onClick={decrementCounter}>
            -
          </button>
        </div> */}
      </div>
      <div className={styles.rightSide}>
        <p className={styles.dDate} style={{color: showthis ? '#FC8A00' : '#14AE5C'}}>{info?.delivaryDate}</p>
        <button className={styles.cancelBtn} onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  )
}

export default DroneOrderComponent
