import React from 'react'
import styles from './StoreCards.module.css'
function StoreCards({ data }) {
    return (
        <div className={styles.container}>

            <img src={data.img} style={{ borderRadius: "30px" }}></img>




        </div>
    )
}

export default StoreCards