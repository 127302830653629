import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "../components/Layout";
import Home from "../pages/Home/index";
import Contact from "../pages/Contact/index";
import DAS from "../pages/DroneAsAService/DroneAsService";
import PilotsDetails from "../pages/PilotsDetails/pilotsDetails";
import QuotePage from "../pages/QuotePage/QuotePage";
import {
  DASLayout,
  AccountOverviewLayout,
  AccountOverviewLayoutSide,
} from "../Layouts/Layouts";
import AccountOverview from "../pages/AccountOverview/AccountOverview";
import ExcellenceCentre from "../pages/ExcellenceCentre/ExcellenceCentre";
import JobComponent from "../components/AccountOverviewComponent/JobComponent/JobComponent";
import Order from "../components/AccountOverviewComponent/OrdersComponent/Order";
import JobDetails from "../pages/JobDetailsPage/JobDetailsPage";
import ProfileComponent from "../components/AccountOverviewComponent/ProfileCompnent/ProfileComponent";
import ReciptPage from "../pages/ReciptPage/ReciptPage";
import Login from "../Authentication/Login";
import Careers from "../pages/Carrer/Carrers";
import ProductDetails from "../pages/ProductDetails/ProductDetails";
import HomePage from "../pages/NewHomePage/HomePage";
import CouresListingPage from "../pages/CouresListingPage/CouresListingPage";
import CouresDetailsPage from "../pages/CouresDetailsPage/CouresDetailsPage";
import ServiceListingPage from "../pages/ServiceListingPage/ServiceListingPage";
import ServiceDetails from "../pages/ServiceDetails/ServiceDetails";
import DroneShop from "../pages/DroneShopPage/DroneShopPage";
import DroneStorePage from "../pages/DroneStorePage/DroneStorePage";
import BatteryDetailsPage from "../pages/BatteryDetailsPage/BatteryDetailsPage";
import CartPage from "../pages/CartPage/CartPage";
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    <Route path="/login" element={<Login/>} />
    <Route path="/login/:num" element={<Login/>} />
    <Route path="/" element={<Layout />}>
      {/* <Route index element={<Home />} /> */}
      <Route index element={< HomePage/>} />

      <Route path="DAS" element={<DASLayout />}>
        <Route index element={<DAS />} />
        <Route path="pilotsDetails" element={<PilotsDetails />} />
        <Route path="quotesform" element={<QuotePage />} />
      </Route>
      <Route path="account-overview/*" element={<AccountOverviewLayout />}>
        <Route index element={<AccountOverview />} />
        <Route path="account-overview/profile-setting" element={<AccountOverviewLayoutSide />}>
          <Route index element={<ProfileComponent />} />
          <Route path="jobs" element={<JobComponent />} />
          <Route path="orders" element={<Order />} />
        </Route>
      </Route>
      <Route path="excellence-center" element={<ExcellenceCentre />} />
      <Route path="jobdetails" element={<JobDetails />} />
      <Route path="recipt" element={<ReciptPage />} />
      <Route path="contact" element={<Contact />} />
      <Route path="careers" element={<Careers />} />
      <Route path="/product/:id" element={<ProductDetails/>} />
      <Route path="newhome" element={< HomePage/>} />
      <Route path="courses" element={<CouresListingPage/>} />
      <Route path="courses/:ids" element={<CouresDetailsPage/>} />
      <Route path="service" element={<ServiceListingPage/>} />
      <Route path="service/:slug" element={<ServiceDetails/>} />
      <Route path="*" element={<div>Error</div>} />
      <Route path="droneShop" element={<DroneShop/>} />
      <Route path="store" element={<DroneStorePage/>} />
      <Route path="batteryDetails" element={<BatteryDetailsPage/>} />

      <Route path="cart" element={<CartPage/>} />


    </Route>
    </>
  )
);

function Mainroutes() {
  return <RouterProvider router={router} />;
}

export default Mainroutes;
