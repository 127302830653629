import React from 'react'
import styles from './DroneShopPage.module.css'
import Catogory from '../../components/Catogory/Catogory'
import BuyDrones from '../../components/BuyDrones/BuyDrones'
import Battery from '../../components/CustomCards/CustomCards'
import CardSlider from '../../components/BatterySlider/BatterySlider'
import BatterySlider from '../../components/BatterySlider/BatterySlider'
import PropellerSlider from '../../components/PropellerSlider/PropellerSlider'
import CameraSlider from '../../components/CameraSlider/CameraSlider'
import ControllerSlider from '../../components/ControllerSlider/ControllerSlider'
function DroneShop() {

  return (
    <div className={styles.container}>
<Catogory/>

<BuyDrones/>
<BatterySlider/>
<PropellerSlider/>
<CameraSlider/>
<ControllerSlider/>
    </div>
  )
}

export default DroneShop