import React from 'react'
import styles from './BatteryCard.module.module.css'
import b1 from '../../assets/battery/b1.png'
import b2 from '../../assets/battery/b2.png'
import b3 from '../../assets/battery/b3.png'
import fullBaterry from '../../assets/battery/fullBaterry.png'
import Specification from '../Specification/Specification'
import Features from '../Features/Features'


function BatteryCard() {
    return (<>
        <div className={styles.container}>
            <div className={styles.batteryDetails}>
                <h2 className={styles.bateryHead}>mPower 6S 25200 mAh Lithium Ion Drone Battery Pack with XT90-S Anti Spark Connector</h2>

                <p className={styles.desc}>mPower 6S 25200 mAh Lithium Ion Battery Pack is a high-quality battery for all popular odels of 10, 12 and 16-litre agricultural drones in India. A set of mPower 6S 25200sprays over 650 acres. Ships within 2 da ys. It is India's first battery to carry a 6 month or 80% DoD warranty, whichever occurs first. If your drone uses a different battery cover or the size mentioned would not fit your drone, please send a custom enquiry or reach out to us over call or WhatsApp.</p>

                <div className={styles.imgCon}>

                    <div className={styles.leftImg}>
                        {data.map((item) => {
                            return (
                                <div className={styles.images}>
                                    <img src={item.img}></img>
                                </div>
                            )
                        })}
                    </div>
                    <div className={styles.rightImg}>
                        <img src={fullBaterry}></img>
                    </div>





                </div>
            </div>
    
            <section>
            <Specification />
        </section>
        <section>
            <Features />
        </section>
        </div>
        
    </>)
}

export default BatteryCard
const data = [
    {
        id: 1,
        img: b3
    },
    {
        id: 2,
        img: b2
    },
    {
        id: 3,
        img: b1
    },
    {
        id: 4,
        img: b2
    },
    {
        id: 5,
        img: b1
    },
]