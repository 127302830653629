import React from 'react'
import styles from './index.module.css'
import dronPic from '../../../assets/images/orderDronImg.png'
import DroneOrderComponent from '../../DroneOrderComponent/DroneOrderComponent'
function Order() {
  const handleCounterUpdate = (updatedCounter) => {
    console.log('Counter updated:', updatedCounter);
    // You can perform any necessary actions here
  };
  const handleCancel = () => {
    console.log('Item cancelled');
    // Handle cancellation logic
  };
  return (
    <div className={styles.listingBox}>
    <h4>Order</h4>
    <p>Item</p>
    {droneOrder.map((info) => {
      return (
    <DroneOrderComponent 
    key={info.id}
    info={info}
    onUpdateCounter={handleCounterUpdate}
    onCancel={handleCancel} />
    )
  })}
</div>
  )
}

export default Order


const droneOrder = [
  {
    id: 1,
    img: dronPic,
    dName: 'd1',
    dPrice: '60,000',
    delivaryDate: 'Delivered on 23 May’23',
    isCouers:false,
  },
  {
    id: 2,
    img: dronPic,
    dName: 'd2',
    dPrice: '50,000',
    delivaryDate: 'Delivered on 23 May’23',
    isCouers:false,
  },
  {
    id: 3,
    img: dronPic,
    dName: 'Training Course 01',
    dPrice: '45,000',
    delivaryDate: 'Activated on 12 March’23 ',
    isCouers:true,
    WLBatch: 'Batch 01',
    days: [
      {
        id: 1,
        day: 'MON',
      },
      {
        id: 2,
        day: 'WED',
      },
      {
        id: 3,
        day: 'FRI',
      },
    ],
    Batchtype: 1,
  },
  {
    id: 4,
    img: dronPic,
    dName: 'd4',
    dPrice: '70,000',
    delivaryDate: 'Delivered on 23 May’23',
    isCouers:false,
  },
  {
    id: 5,
    img: dronPic,
    dName: 'd2',
    dPrice: '50,000',
    delivaryDate: 'Delivered on 23 May’23',
    isCouers:false,
  },
  {
    id: 6,
    img: dronPic,
    dName: 'd3',
    dPrice: '45,000',
    delivaryDate: 'Delivered on 23 May’23',
    isCouers:true,
    WLBatch: 'Batch 02',
    days: [
      {
        id: 1,
        day: 'MON',
      },
      {
        id: 2,
        day: 'WED',
      },
      {
        id: 3,
        day: 'FRI',
      },
    ],
    Batchtype: 2,
  },
]