import React from 'react'
import styles from './Faqs.module.css'
import AccordionUsage from '../AccordionTransition/AccordionTransition'

function Faqs() {
    return (
        <div className={styles.con}>
            <h3>FAQ’S</h3>
{data.map((item)=>{
    return(


            <AccordionUsage data={item}/>
        )
    })}
        </div>
    )
}

export default Faqs
const data=[
    {id:1,
        no:"01",
        name:" Where can I watch?",
        desc:" Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla.",
    },
    {id:2,
        no:"02",

        name:" Where can I watch?",
        desc:" Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla.",
    },
    {id:3,
        no:"03",

        name:" Where can I watch?",
        desc:" Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla.",
    },
    {id:4,
        no:"04",

        name:" Where can I watch?",
        desc:" Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla.",
    },
]