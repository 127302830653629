import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import styles from "./contactForm.module.css";
import phone from "../../assets/images/phone-call.png";
import email from "../../assets/images/email.png";
import location from "../../assets/images/location-.png";
import twitter from "../../assets/images/twitter.png";
import insta from "../../assets/images/insta.png";
import discord from "../../assets/images/dicord.png";
import circle from "../../assets/images/contact-form-desk.png";
import paperPlane from "../../assets/images/letter_send 1.png";
import { api_call } from "../../Utils/Network";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  boxShadow: "none",
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const ContactForm = () => {
  const [contactInfo, setContactInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue =
      type === "radio" ? value : type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
    // Reset validation errors when user starts typing
    if (name === "first_name") {
      setFirstNameError(false);
    } else if (name === "last_name") {
      setLastNameError(false);
    } else if (name === "email") {
      setEmailError(false);
    } else if (name === "phone") {
      setPhoneError(false);
    }
  };

   // Regular expressions for phone number and email validation
   const phoneRegex = /^\d{10}$/; // Assumes a 10-digit phone number format
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form submission or other actions with the form data

    // Validate required fields
    if (formData.first_name === "") {
      setFirstNameError(true);
      return;
    }
    if (formData.last_name === "") {
      setLastNameError(true);
      return;
    }
    if (formData.email === "" || !emailRegex.test(formData.email)) {
      setEmailError(true);
      return;
    }
    if (formData.phone === "" || !phoneRegex.test(formData.phone)) {
      setPhoneError(true);
      return;
    }
    console.log(formData, "posthit");

    api_call.post("base/v1/contact_us/", formData)

     // Clear the form after submission
     setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });

    // Reset the error state variables
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setPhoneError(false);
  };

  //////////////////////////////// api call

  const fetchData = async () => {
    setLoading(true);
    // try {
    //   const response = await api_call.get("/products/v1/aerobot/contact/us");

    //   const data = response?.data?.data;
    //   // console.log(homePageData, 'homePageData');
    //   if (data !== undefined || data !== null) {
    //     setContactInfo(data[0]);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    api_call
    .get(`/products/v1/aerobot/contact/us`)
    .then((res)=>{
      const data = res?.data?.data;
      // console.log(homePageData, 'homePageData');
      if (data !== undefined || data !== null) {
        setContactInfo(data[0]);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(contactInfo, "contactInfo");
  return (
    <>
      <section className={styles.ContactForm}>
        <h3 className={styles.sectionHeading}>Contact us </h3>
        <div className={styles.sectionPara}>
          <p>
            Write us your queries and our team will get back to you immediatly
          </p>
        </div>
        <div className={styles.contactFormCardContainer}>
          <div className={styles.leftSideContainer}>
            <div className={styles.cardTop}>
              <h3 className={styles.formHeading}>{contactInfo?.title}</h3>
              <p className={styles.formPara}>{contactInfo?.description}</p>
            </div>
            <div className={styles.cardCenter}>
              <div className={styles.logoText}>
                <div className={styles.contactLogo}>
                  <img src={phone} alt="" />
                </div>
                {/* <a
                  href={`tel:+91 9004711184`}
                  className={styles.contactText}
                >
                  +91 9004711184
                </a> */}
                <a
                  href={`tel:${contactInfo?.contact_info}`}
                  className={styles.contactText}
                >
                  {contactInfo?.contact_info}
                </a>
              </div>
              <div className={styles.logoText}>
                <div className={styles.contactLogo}>
                  <img src={email} alt="" />
                </div>
                {/* <a
                  href={`mailto:info@aerobott.com`}
                  className={styles.contactText}
                >
                  info@aerobott.com
                </a> */}
                <a
                  href={`mailto:${contactInfo?.email}`}
                  className={styles.contactText}
                >
                  {contactInfo?.email}
                </a>
              </div>
              <div className={styles.logoText}>
                <div className={styles.contactLogo}>
                  <img src={location} alt="" />
                </div>
                {/* <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    "35/151, 1st floor, shree Laxmi Vijay Industrial premises, new link road, sab tv lane, andheri west mumbai 400053 Landmark-opp. Hotel shabri")}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactText}
                >
                  35/151, 1st floor, shree Laxmi Vijay Industrial premises, new
                  link road, sab tv lane, andheri west mumbai 400053 Landmark-
                  opp. Hotel shabri
                </a> */}
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    contactInfo?.location
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactText}
                >
                  {contactInfo?.location}
                </a>
              </div>
            </div>
            <div className={styles.socialMedia}>
              <div className={styles.socialLogo}>
                <a href={contactInfo?.twitter} target="_blank">
                  <img src={twitter} />
                </a>
                <a href={contactInfo?.insta_info} target="_blank">
                  <img src={insta} />
                </a>
                <a href={contactInfo?.facebook} target="_blank">
                  <img src={discord} />
                </a>
              </div>
            </div>
            <div className={styles.cardbottumimg}>
              <img src={circle} alt="" />
            </div>
          </div>
          <div className={styles.rightSideContainer}>
            <Item>
              <form onSubmit={handleSubmit}>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    <Grid item xs={6}>
                      <Item>
                        <TextField
                          name="first_name"
                          label="First Name"
                          value={formData.first_name}
                          onChange={handleChange}
                          variant="standard"
                          // required
                          error={firstNameError}
                          helperText={
                            firstNameError ? "First Name is required" : ""
                          }
                          sx={{ width: "100%" }}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <TextField
                          name="last_name"
                          label="Last Name"
                          value={formData.last_name}
                          onChange={handleChange}
                          // required
                          variant="standard"
                          error={lastNameError}
                          helperText={
                            lastNameError ? "Last Name is required" : ""
                          }
                          sx={{ width: "100%" }}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <TextField
                          name="email"
                          label="Email"
                          type="email"
                          value={formData.email}
                          onChange={handleChange}
                          variant="standard"
                          // required
                          error={emailError}
                          helperText={emailError ? "Email is required" : ""}
                          sx={{ width: "100%" }}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <TextField
                          name="phone"
                          label="Phone Number"
                          value={formData.phone}
                          onChange={handleChange}
                          variant="standard"
                          // required
                          error={phoneError}
                          helperText={
                            phoneError ? "Phone Number is required" : ""
                          }
                          sx={{ width: "100%" }}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"left", marginLeft:"20px"}}>
                      <FormLabel id="demo-row-radio-buttons-group-label" >
                        Select Subject
                      </FormLabel>
                      <RadioGroup
                        row
                        name="subject"
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={formData.subject}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Hire a Pilot"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Course Customisation"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="Service Enqiury"
                        />
                        <FormControlLabel
                          value="4"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <Item>
                        <TextField
                          name="message"
                          label="Message"
                          multiline
                          rows={2}
                          value={formData.message}
                          onChange={handleChange}
                          variant="standard"
                          sx={{ width: "100%" }}
                        />
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
                <div className={styles.submitBtn} style={{ textAlign: "end" }}>
                  <Button
                    type="submit"
                    // disabled
                    variant="contained"
                    className={styles.cardBtn}
                  >
                    Send Message
                  </Button>
                </div>
              </form>
            </Item>
            <div className={styles.paperPlane}>
              <img src={paperPlane} alt />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
