import React, { useEffect, useState } from 'react'
import HomeBanner from '../../components/HomeBanner/HomeBanner';
import styles from "./index.module.css";
import bgimg1 from "../../assets/NewHomePage/letter_send2.png"
import bgimg2 from "../../assets/NewHomePage/letter_send3.png"
import bgimg3 from "../../assets/NewHomePage/letter_send4.png"
import service1 from "../../assets/NewHomePage/1st.png"
import dtpvideo from "../../assets/NewHomePage/dtpvideo.mp4"
import dtpvideoMob from "../../assets/NewHomePage/dtpvideoMob.mp4"
import { api_call } from '../../Utils/Network';
import { Button } from '@mui/material';
import DroneasService from '../../components/DroneasService/DroneasService';
import daas1 from "../../assets/NewHomePage/daas1.png"
import daas2 from "../../assets/NewHomePage/daas2.png"
import daas3 from "../../assets/NewHomePage/daas3.png"
import leftback from "../../assets/NewHomePage/pageBackleft.png"
import rightback from "../../assets/NewHomePage/pageBackright.png"
import OurDrone from '../../components/OurDrone/OurDrone';
import ourDrone1 from "../../assets/NewHomePage/drone1.png"
import ourDrone2 from "../../assets/NewHomePage/drone2.png"
import ecimg1 from "../../assets/NewHomePage/ec1.png"
import ecimg2 from "../../assets/NewHomePage/ec2.png"
import ecimg3 from "../../assets/NewHomePage/ec3.png"
import ecimg4 from "../../assets/NewHomePage/ec4.png"
import CourosalSlider from '../../components/CourosalSlider/CourosalSlider';
import SuccessStories from '../../components/HomePageNewComponent/SuccessStories/SuccessStories';
import NewsArtical from '../../components/HomePageNewComponent/NewsArtical/NewsArtical';
import OurServices from '../../components/HomePageNewComponent/OurServices/OurServices';

function HomePage() {
  const [loading, setLoading] = useState(false);
  const [likeValues, setLikeValues] = useState({});
  const [bannerData, setBannerData] = useState({});
  const [cardData, setCardData] = useState([]);
  const [speakWith, setSpeakWith] = useState({});
  const [droneCardData, setDroneCardData] = useState([]);
  const [membership, setMembership] = useState({});
  const [eCenter, setECenter] = useState({});
  const [rentDP, setRentDP] = useState({});

  const fetchData = async () => {
    setLoading(true);
    api_call
      .get(`products/v1/home_screen`)
      .then((response) => {
        // loading false
        console.log(response);
        const homePageData = response?.data?.data;
        if (homePageData !== undefined || homePageData !== null) {
          setBannerData(homePageData[0]?.data[0]);
          setCardData(homePageData[1]?.data);
          setSpeakWith(homePageData[6]?.data[0]);
          setDroneCardData(homePageData[2]?.data);
          setMembership(homePageData[3]?.data[0]);
          setECenter(homePageData[4]);
          setRentDP(homePageData[5]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <HomeBanner />

      <section className={`${styles.OurServices} ${styles.relative}`} >
        <h2 className={styles.sectionHeading}><span>Our </span> services</h2>
        <div className={styles.serviceCardlisting}>
          {/* <CourosalSlider cardsPerSlide={3} arrows={true} >
            {
              cardData?.map((info, i) => {
                return (
                  <>
                    <div className={styles.card} key={info?.id}>

                      <div className={styles.cardImg}>
                        <img src={service1} alt={info?.title} />
                      </div>
                      <h4 className={styles.cardTitle}>{info?.title}</h4>
                      <p className={styles.cardText}>{info?.description}</p>
                      <Button variant='outlined' className={styles.outLinedBtn}>Learn more</Button>
                    </div>
                  </>
                )
              })
            }
          </CourosalSlider> */}
          <OurServices data= {cardData} />
        </div>

        <div className={`${styles.OSbgone} ${styles.absolute}`} >
          <img src={bgimg1} alt='bg_img' />
        </div>
        <div className={`${styles.OSbgtwo} ${styles.absolute}`} >
          <img src={bgimg2} alt='bg_img' />
        </div>
        <div className={`${styles.OSbgthree} ${styles.absolute}`} >
          <img src={bgimg3} alt='bg_img' />
        </div>

      </section>
      <section className={`${styles.dptcontainer}`}>
        <h2 className={styles.sectionHeading}><span>Drone </span> pilot Taining</h2>
        <div className={`${styles.videoContainer} ${styles.relative}`}>


          <video
            className={styles.dtpVideodesk}
            autoPlay
            loop
            muted
          >
            <source src={dtpvideo} type="video/mp4" />
          </video>
          <video
            className={styles.dtpVideomobile}
            autoPlay
            loop
            muted
          >
            <source src={dtpvideoMob} type="video/mp4" />
          </video>
          <div className={styles.onvideoText}>
            <div className={styles.lefttext}>
              <h4>Gain Mastery over Skies with the Best Drone Pilot Course</h4>
              <p>Join the DGCA-certified top Drone flying course in just 5 days, designed for dreamers to soar to new heights</p>
              <h5>Our Training program:-</h5>
              <ul>
                <li>2 days Theory class</li>
                <li>1 Day Simulation Class</li>
                <li>2 Days Drone Flying Ground Training</li>
              </ul>
            </div>
            <div>
              <Button variant='outlined' className={styles.outLinedBtn} >
                Join now
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* <section className={`${styles.daasContainer} ${styles.relative}`}>
        <h2 className={styles.sectionHeading}><span>Drones </span> as a service</h2>
        <div className={styles.daasInnerContainer}>
          <div className={styles.daasListing}>
            {
              daasData?.map((v, i) => {
                return (
                  <DroneasService data={v} />
                )
              })
            }
          </div>
          
        </div>
        <div className={`${styles.serviceLeftback} ${styles.absolute}`}>
          <img src={leftback} alt />
        </div>
      </section> */}

      {/* <section className={`${styles.ourDrone} ${styles.relative}`}>
        <h2 className={styles.sectionHeading}><span>OUR </span> DRONES</h2>
        <div className={styles.DroneInnerConatiner}>
          {
            ourDroneData?.map((v, i) => {
              return (
                <OurDrone key={i} data={v} />
              )
            })
          }
        </div>
      </section> */}
      <section className={`${styles.eCContainer} ${styles.relative}`}>
        <h2 className={styles.sectionHeading}><span>Excellence </span> centre</h2>
        <div className={styles.sectionPara}>
          <p>
            Aerobott is devoted to excellence in teaching, learning, research
            and developing quality UAV pilots capable of executing multiple
            application projects.
          </p>
        </div>
        <div className={styles.centerAlign}>
          <button className={`${styles.outLinedBtn}`}>
            Explore our excellency center
          </button>
        </div>
        <div className={styles.ecListingContainer}>

          {
            ecData?.map((v, i) => {
              return (
                <>
                  <div key={v?.id} className={styles.ecContainer}>
                    <div className={styles.imgContainer}>
                      <img src={v?.img} alt={v?.title} />
                    </div>
                    <h4>{v?.title}</h4>
                  </div>
                </>
              )
            })
          }

        </div>
      </section>
      <section className={styles.SuccessStories}>
        <h2 className={styles.sectionHeading}><span>Success </span> stories</h2>

        <SuccessStories />
      </section>

      {/* <section className={styles.NewsArtical}>
        <h2 className={styles.sectionHeading}><span>News </span>& articles</h2>

        <NewsArtical />
      </section> */}
      
    </>
  )
}

export default HomePage

const daasData = [
  {
    id: 1,
    img: daas1,
    title: "Data - Driven Precision farming",
    serviceType: 'Agrispray',
    decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
    rating: 4.3,
    dronevalue: " ₹200"
  },
  {
    id: 2,
    img: daas2,
    title: "Data - Driven Precision farming",
    serviceType: 'Agrispray',
    decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
    rating: 4.3,
    dronevalue: " ₹200"
  },

  {
    id: 3,
    img: daas3,
    title: "Data - Driven Precision farming",
    serviceType: 'Agrispray',
    decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
    rating: 2.3,
    dronevalue: " ₹200"
  },
]

const ourDroneData = [
  {
    id: 1,
    img: ourDrone1,
    title: "Drone Name 01",
    desc: "Triple-Lens Flagship Camera Drone",
  },
  {
    id: 2,
    img: ourDrone2,
    title: "Drone Name 02",
    desc: "Triple-Lens Flagship Camera Drone",
  },
]

const ecData = [
  {
    id: 1,
    img: ecimg1,
    title: "Certified Instructors",
  },
  {
    id: 2,
    img: ecimg2,
    title: "Training Certificate",
  },
  {
    id: 3,
    img: ecimg3,
    title: "DGCA Approved Training",
  },
  {
    id: 4,
    img: ecimg4,
    title: "Practical Training",
  },
]