import React, { useState } from 'react'
import styles from './index.module.css'
// import Paper from '@mui/material/Paper'
// import InputBase from '@mui/material/InputBase'
// import IconButton from '@mui/material/IconButton'
// import SearchIcon from '@mui/icons-material/Search'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import PlaceIcon from '@mui/icons-material/Place'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
// import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer'
import Rating from '@mui/material/Rating'
import SocialDistanceIcon from '@mui/icons-material/SocialDistance'
import pilotsImg from '../../assets/images/pilotspic.png'
// import { Navigate } from 'react-router-dom'
import MapWithMarkers from '../../components/MapComponent/MapwithMarkers'
import MapSerach from '../../components/MapComponent/MapSerach'

export default function DroneAsService() {
  const [service, setService] = useState('')
  const [levle, setLevel] = useState('')
  const [capabilities, setCapabilities] = useState('')
  const [iconData, setIconData] = useState(1)
  const [open, setOpen] = useState(false)
  const [tabOpen, setTabOpne] = useState(1)
  const [selectedLatLng, setSelectedLatLng] = useState(null)
  const handleLatLngUpdate = (lat , lng) => {
    setSelectedLatLng(lat, lng);
    console.log(lat, lng , 'selectedLatLngdddd');
  };
  console.log(selectedLatLng , 'selectedLatLng');

  const handleSelectChange = (event) => {
    console.log('seasdasdadtAge', event.target.value)
    setService(event.target.value)
  }
  const handleLevelChange = (event) => {
    console.log('seasdasdadtAge', event.target.value)
    setLevel(event.target.value)
  }
  const handleCapabilitiesChange = (event) => {
    console.log('seasdasdadtAge', event.target.value)
    setCapabilities(event.target.value)
  }
  const handelSetActive = (id) => {
    setIconData(id)
    console.log(id, 'handelSetActive')
    setTabOpne(id)
    // info.active= !info.active;
    // console.log(info,"dfkjshdfkshfshfkjsf");
  }

  const handleDrawer = (value) => {
    console.log(value, 'handleDrawer')
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // const profilHandle = () =>{
  //   Navigate('/pilots_detail/${id}')
  // }
  // //////////////////////////////////
  const containerElement = <div className={styles.fullScreenMap} />
  const mapElement = <div style={{ height: '100%', borderRadius: '5px' }} />
  //////////////////////////////////////////

  const [address, setAddress] = useState('')

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress)
    console.log(newAddress, "setAddress");
  }
  
  // ////////////////////////////////////////
  return (
    <>
      <section className={styles.mainContainer}>
        <div className={styles.innerContainer}>
          <div className={styles.leftSearchContainer}>
            <form className={styles.serachForm}>
              <div className={styles.headerBtn}>
                <h4>Search Pilot’s Near You</h4>
                <div>
                  {iconsData.map((info) => {
                    // console.log(info.id,"dfkjshdfkshfshfkjsf");

                    return (
                      <div
                        key={info.id}
                        className={`${styles.locationMark} ${
                          info.id === iconData ? styles.locationMarkActive : ''
                        }`}
                        onClick={() => handelSetActive(info.id)}
                      >
                        {info.searchByIcon}
                      </div>
                    )
                  })}
                  {/* <PlaceIcon className={styles.locationMark} />
                <FormatListBulletedIcon /> */}
                </div>
              </div>
              {/* <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '25px',
                }}
              >
                <IconButton sx={{ p: '10px' }} aria-label="menu">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by Name"
                  inputProps={{ 'aria-label': 'search google maps' }}
                  onChange={handleAddressChange}
                  
                />
              </Paper> */}
              <MapSerach
                placeholder="Custom Placeholder Text"
                value={address}
                onChange={handleAddressChange}
                onSelectLatLng={handleLatLngUpdate}
              />
              <div className={styles.fieldHeader}>
                <h5>Address</h5>
                <TextField
                  hiddenLabel
                  id="outlined-basic"
                  variant="outlined"
                  style={{
                    backgroundColor: '#ffffff',
                    width: '100%',
                    borderRadius: '5px',
                  }}
                />
              </div>
              <div className={styles.fieldHeader}>
                <h5>Service</h5>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={service}
                      onChange={handleSelectChange}
                      style={{
                        backgroundColor: '#ffffff',
                        width: '100%',
                        borderRadius: '5px',
                        color: '#000000',
                      }}
                    >
                      {serviceData.map((info) => (
                        <MenuItem value={info}>{info}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className={styles.fieldHeader}>
                <h5>Level</h5>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={levle}
                      onChange={handleLevelChange}
                      style={{
                        backgroundColor: '#ffffff',
                        width: '100%',
                        borderRadius: '5px',
                        color: '#000000',
                      }}
                    >
                      {serviceData.map((info) => (
                        <MenuItem value={info}>{info}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className={styles.fieldHeader}>
                <h5>Capabilities</h5>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={capabilities}
                      onChange={handleCapabilitiesChange}
                      style={{
                        backgroundColor: '#ffffff',
                        width: '100%',
                        borderRadius: '5px',
                        color: '#000000',
                      }}
                    >
                      {serviceData.map((info) => (
                        <MenuItem value={info}>{info}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className={styles.checkboxContainer}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'white',
                          },
                        }}
                      />
                    }
                    label="Show Radius "
                    style={{
                      color: '#fff',
                    }}
                  />
                </FormGroup>
              </div>
              <div className={styles.kilometerIndicator}>
                {indicatorData.map((info) => (
                  <div>
                    <span
                      className={styles.indicatorColors}
                      style={{ backgroundColor: `${info.color}` }}
                    ></span>
                    <span className={styles.indicatorText}>{info.km}</span>
                  </div>
                ))}
              </div>
            </form>
            <div className={styles.btnContainer}>
              <button className={styles.cancelBtn}>Cancel</button>
              <button className={styles.serachBtn}>Search</button>
            </div>
          </div>

          <div className={styles.rightMapCantainer}>
            <div className={styles.mobileSerach}>
              <h4>Search Pilot’s Near You</h4>
              <div className={styles.mobileHeaderBtn}>
              <MapSerach
                placeholder="Custom Placeholder Text"
                value={address}
                onChange={handleAddressChange}
                onSelectLatLng={handleLatLngUpdate}
              />
                <div className={styles.mobileTabBtn}>
                  {iconsData.map((info) => {
                    // console.log(info.id,"dfkjshdfkshfshfkjsf");

                    return (
                      <div
                        key={info.id}
                        className={`${styles.locationMark} ${
                          info.id === iconData ? styles.locationMarkActive : ''
                        }`}
                        onClick={() => handelSetActive(info.id)}
                      >
                        {info.searchByIcon}
                      </div>
                    )
                  })}
                  {/* <PlaceIcon className={styles.locationMark} />
                <FormatListBulletedIcon /> */}
                </div>
              </div>
            </div>
            {tabOpen === 1 && (
              // <iframe
              //   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7539.399125796426!2d72.85504315869137!3d19.12083165276537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c82e791896cd%3A0x83435e5c02f92800!2sTata%20Consultancy%20Services!5e0!3m2!1sen!2sin!4v1689075521216!5m2!1sen!2sin"
              //   className={styles.fullScreenMap}
              //   allowfullscreen=""
              //   loading="lazy"
              //   referrerpolicy="no-referrer-when-downgrade"
              // ></iframe>
              <MapWithMarkers
                containerElement={containerElement}
                mapElement={mapElement}
                selectedLatLng={selectedLatLng} 
              />
            )}
            {tabOpen === 2 && (
              <div className={styles.mainPilotsCardContainer}>
                <h3 className={styles.noOfPilotsFound}>68 Pilots Found</h3>
                <p>Nearest to you</p>
                <div className={styles.pilotsCardListing}>
                  {pilotscardApi.map((info) => {
                    return (
                      <>
                        <div className={styles.pilotsCard}>
                          {/* <div className={styles.pilotsImg}>
                            <img src={pilotsImg} alt="" />
                          </div> */}
                          <div className={styles.pilotsDetails}> 
                            <div className={styles.pilotsImg}>
                              <img src={pilotsImg} alt="" />
                            </div>
                            <div className={styles.imgNameRating}>
                              <h3>Company Name / Pilot Name</h3>
                              <div className={styles.ratingAndDistance}>
                                <div className={styles.ratingsToShow}>
                                  <Rating
                                    name="read-only"
                                    value="3"
                                    readOnly
                                    max={1}
                                  />
                                  <span>4.0 (12)</span>
                                </div>
                                <div className={styles.distanceAway}>
                                  <SocialDistanceIcon />{' '}
                                  <span>1.4 km away </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.pricingDecs}>
                            <div className={styles.pricingDetails}>
                              <span>Licences #1245667</span>
                              <span> ₹2000 Insurance</span>
                              <span>₹2000 per/ hr</span>
                            </div>
                            <p className={styles.pilotsDesc}>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat. Duis aute irure
                              dolor in reprehenderit
                            </p>
                          </div>

                          <div className={styles.pilotsCardBtn}>
                            <button
                              className={styles.viewBtn}
                              // onClick={profilHandle}
                            >
                              View Profile
                            </button>
                            <button className={styles.secdQuoteBtn}>
                              Send Quote
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  })}
                  {/* <div className={styles.pilotsCard}>
                    <div className={styles.pilotsImg}>
                      <img src={pilotsImg} alt="" />
                    </div>
                    <div className={styles.pilotsDetails}>
                      <h3>Company Name / Pilot Name</h3>
                      <div className={styles.ratingAndDistance}>
                        <div className={styles.ratingsToShow}>
                          <Rating name="read-only" value="3" readOnly />
                          <span>4.0 (12)</span>
                        </div>
                        <div className={styles.distanceAway}>
                          <SocialDistanceIcon /> <span>1.4 km away </span>
                        </div>
                      </div>
                      <div className={styles.pricingDetails}>
                        <span>Licences #1245667</span>
                        <span> ₹2000 Insurance</span>
                        <span>₹2000 per/ hr</span>
                      </div>
                      <p className={styles.pilotsDesc}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit
                      </p>
                      <div className={styles.pilotsCardBtn}>
                        <button className={styles.viewBtn}>View Profile</button>
                        <button className={styles.secdQuoteBtn}>
                          Send Quote
                        </button>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            )}
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7539.399125796426!2d72.85504315869137!3d19.12083165276537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c82e791896cd%3A0x83435e5c02f92800!2sTata%20Consultancy%20Services!5e0!3m2!1sen!2sin!4v1689075521216!5m2!1sen!2sin"
              className={styles.fullScreenMap}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}
            <div className={styles.mapFloatBtn}>
              <button className={styles.serachBtnMobile} onClick={handleDrawer}>
                Search Pilot
              </button>
            </div>
          </div>

          <div className={styles.deckstopHide}>
            <CustomDrawer
              open={open}
              callBackFunction={handleDrawer}
              side="bottom"
            >
              <div className={styles.drawerContainer}>
                <form className={styles.serachForm}>
                  <div className={styles.headerBtn}>
                    <h4>Enter details to find pilot’s near you</h4>
                    {/* <div>
                    {iconsData.map((info) => {
                      // console.log(info.id,"dfkjshdfkshfshfkjsf");

                      return (
                        <div
                          key={info.id}
                          className={`${styles.locationMark} ${
                            info.id === iconData
                              ? styles.locationMarkActive
                              : ''
                          }`}
                          onClick={() => handelSetActive(info.id)}
                        >
                          {info.searchByIcon}
                        </div>
                      )
                    })}
                    
                  </div> */}
                  </div>
                  {/* <Paper
                  component="form"
                  sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '25px',
                  }}
                >
                  <IconButton sx={{ p: '10px' }} aria-label="menu">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by Name"
                    inputProps={{ 'aria-label': 'search google maps' }}
                  />
                </Paper> */}
                  <div className={styles.fieldHeader}>
                    <h5>Address</h5>
                    <TextField
                      hiddenLabel
                      id="outlined-basic"
                      variant="outlined"
                      style={{
                        backgroundColor: '#ffffff',
                        width: '100%',
                        borderRadius: '5px',
                      }}
                    />
                  </div>
                  <div className={styles.fieldHeader}>
                    <h5>Service</h5>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={service}
                          onChange={handleSelectChange}
                          style={{
                            backgroundColor: '#ffffff',
                            width: '100%',
                            borderRadius: '5px',
                            color: '#000000',
                          }}
                        >
                          {serviceData.map((info) => (
                            <MenuItem value={info}>{info}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div className={styles.fieldHeader}>
                    <h5>Level</h5>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={levle}
                          onChange={handleLevelChange}
                          style={{
                            backgroundColor: '#ffffff',
                            width: '100%',
                            borderRadius: '5px',
                            color: '#000000',
                          }}
                        >
                          {serviceData.map((info) => (
                            <MenuItem value={info}>{info}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div className={styles.fieldHeader}>
                    <h5>Capabilities</h5>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={capabilities}
                          onChange={handleCapabilitiesChange}
                          style={{
                            backgroundColor: '#ffffff',
                            width: '100%',
                            borderRadius: '5px',
                            color: '#000000',
                          }}
                        >
                          {serviceData.map((info) => (
                            <MenuItem value={info}>{info}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div className={styles.checkboxContainer}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // defaultChecked
                            sx={{
                              color: 'white',
                              '&.Mui-checked': {
                                color: 'white',
                              },
                            }}
                          />
                        }
                        label="Show Radius "
                        style={{
                          color: '#fff',
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className={styles.kilometerIndicator}>
                    {indicatorData.map((info) => (
                      <div>
                        <span
                          className={styles.indicatorColors}
                          style={{ backgroundColor: `${info.color}` }}
                        ></span>
                        <span className={styles.indicatorText}>{info.km}</span>
                      </div>
                    ))}
                  </div>
                </form>
                <div className={styles.btnContainer}>
                  <button className={styles.cancelBtn} onClick={handleClose}>
                    Cancel
                  </button>
                  <button className={styles.serachBtn}>Search</button>
                </div>
              </div>
            </CustomDrawer>
          </div>
        </div>
      </section>
    </>
  )
}

const serviceData = [
  'service_1',
  'service_2',
  'service_3',
  'service_4',
  'service_5',
  'service_6',
]

const iconsData = [
  {
    id: 1,
    searchByIcon: <PlaceIcon />,
    active: 'true',
  },
  {
    id: 2,
    searchByIcon: <FormatListBulletedIcon />,
    active: 'false',
  },
]

const indicatorData = [
  {
    color: '#FFCD29',
    km: '100 km',
  },
  {
    color: '#4ECB71',
    km: '10 km',
  },
  {
    color: '#F30A0A',
    km: '5 km',
  },
]

const pilotscardApi = [
  {
    id: 1,
    img: pilotsImg,
    Name: 'abc',
    P_Rating: 4,
    avg_rating: 12,
    distance: ' 1.4 km away',
    licences: 'Licences #1245667',
    insurance: '₹2000 Insurance',
    charges: '₹2000 per/ hr',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit',
  },
  {
    id: 2,
    img: pilotsImg,
    Name: 'pqr',
    P_Rating: 2,
    avg_rating: 10,
    distance: ' 1.4 km away',
    licences: 'Licences #1245667',
    insurance: '₹2000 Insurance',
    charges: '₹2000 per/ hr',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit',
  },
  {
    id: 3,
    img: pilotsImg,
    Name: 'xyz',
    P_Rating: 3,
    avg_rating: 15,
    distance: ' 1.4 km away',
    licences: 'Licences #1245667',
    insurance: '₹2000 Insurance',
    charges: '₹2000 per/ hr',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit',
  },
  {
    id: 4,
    img: pilotsImg,
    Name: 'abc',
    P_Rating: 4,
    avg_rating: 12,
    distance: ' 1.4 km away',
    licences: 'Licences #1245667',
    insurance: '₹2000 Insurance',
    charges: '₹2000 per/ hr',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit',
  },
  {
    id: 5,
    img: pilotsImg,
    Name: 'lmn',
    P_Rating: 4,
    avg_rating: 12,
    distance: ' 1.4 km away',
    licences: 'Licences #1245667',
    insurance: '₹2000 Insurance',
    charges: '₹2000 per/ hr',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit',
  },
]
