import React from 'react'
import styles from './index.module.css'
import BackButton from '../../components/BackButton/BackButton'

function ReciptPage() {

  let statusClass="";
  if (jobdetails[0].PaymentSatus === 1){
    statusClass =styles.pending;
  }else if (jobdetails[0].PaymentSatus  === 2) {
    statusClass = styles.received;
  } else if (jobdetails[0].PaymentSatus  === 3) {
    statusClass = styles.overdue;
  }
  return (
    <section className={styles.riciptDetailsContainer}>
        <div className={styles.topLine}>
          <div className={styles.lefSide}>
            <BackButton />
            <h1 className={styles.jobHeading}>Job ID {jobdetails[0].id}</h1>
          </div>
          <div className={styles.rightSide}>
            <h3 className={`${styles.paymentTag} ${statusClass}`}>Received</h3>
          </div>
        </div>
        <div className={styles.detailsContainer}>
            <div className={styles.box}>
                <p className={styles.headings}>Client Name</p>
                <p className={styles.details}>{jobdetails[0].ClientName}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>Job Type</p>
                <p className={styles.details}>{jobdetails[0].jobType}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>Job Duration</p>
                <p className={styles.details}>{jobdetails[0].jobDureation}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>From Date</p>
                <p className={styles.details}>{jobdetails[0].fromdate}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>To Date</p>
                <p className={styles.details}>{jobdetails[0].toDate}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>Duration</p>
                <p className={styles.details}>{jobdetails[0].duration}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>No of Days</p>
                <p className={styles.details}>{jobdetails[0].noOfDays}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>Drone Needed</p>
                <p className={styles.details}>{jobdetails[0].droneneed}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>Drone Requirements</p>
                <p className={styles.details}>{jobdetails[0].dronrequirment}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>Level</p>
                <p className={styles.details}>{jobdetails[0].level}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>Capabilities</p>
                <p className={styles.details}>{jobdetails[0].capabilities}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>Multiple Pilots</p>
                <p className={styles.details}>{jobdetails[0].multipelPilots}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>Budget </p>
                <p className={styles.details}>{jobdetails[0].budget}</p>
            </div>
            <div className={styles.box}>
                <p className={styles.headings}>Address of the site</p>
                <p className={styles.details}>{jobdetails[0].address}</p>
            </div>
        </div>
       
    </section>
  )
}

export default ReciptPage


const  jobdetails=[
  {
      id:1,
      ClientName:"Agro Pvt Ltd",
      jobType:"Aerial Photography",
      jobDureation:"Project Bases",
      fromdate:"12 June’23 ",
      toDate:"20 June’23",
      duration:"6 Hrs Daily",
      noOfDays:9 ,
      droneneed:"Yes",
      dronrequirment:"3DR Iris",
      level:"Intermediate",
      capabilities:"4k Videos",
      multipelPilots:"No",
      budget:"₹2,500.00",
      address:"Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016",
      PaymentSatus:2,
  }
]