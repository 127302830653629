import React from 'react'
import styles from './index.module.css'
import pilotpic from '../../../assets/images/POpagepilotPic.png'
import camera from '../../../assets/images/photo_camera.png'
import Specialist from '../../SpecializationComponent/SpecializationComponent'
import EditIcon from '@mui/icons-material/Edit'

function ProfileSetting({ onButtonClick }) {
  const valueSend = () => {
    const valueSend = 5
    onButtonClick(valueSend)
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.ProfileContainer}>
        <div className={styles.mobileshow}>
          <h3 className={styles.titleheading}>Profile Setting</h3>
          <button className={styles.editBtn} onClick={valueSend}>
            <EditIcon /> Edit
          </button>
        </div>
        <div className={styles.imageContainer}>
          <img src={pilotpic} />
          <button className={styles.cameraEdit}>
            <img src={camera} />
          </button>
        </div>
        <div className={styles.detailContainer}>
          <div className={styles.topLine}>
            <h1 className={styles.pilotName}>{pilotDetail[0].pName}</h1>
            <p className={styles.pilotlice}>
              Licence No: {pilotDetail[0].plicence}
            </p>
            <div className={styles.mobileHide}>
              <button className={styles.editBtn} onClick={valueSend}>
                <EditIcon /> Edit
              </button>
            </div>
          </div>
          <div className={styles.details}>
            <p className={styles.pilotDetail}>
              Phone No :{pilotDetail[0].pNumber}
            </p>
            <p className={styles.pilotDetail}>
              Email ID: {pilotDetail[0].pEmail}
            </p>
            <p className={styles.pilotDetail}>
              Licence No: {pilotDetail[0].plicence}
            </p>
            <p className={styles.pilotDetail}>
              Insurance Coverage: {pilotDetail[0].insuranceCoverage}
            </p>
            <p className={styles.pilotDetail}>{pilotDetail[0].place}</p>
            <p className={styles.pilotDetail}>
              Avg Rate: {pilotDetail[0].rate}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.pAbout}>
        <h3>About Me</h3>
        <p>{pilotDetail[0].AboutMe}</p>
      </div>
      <Specialist specializesData={specialistData} />
    </div>
  )
}

export default ProfileSetting

const pilotDetail = [
  {
    id: 1,
    pName: 'Ronak Sheth',
    pNumber: '+91 123456788',
    pEmail: 'person@mail.com',
    plicence: '#12345',
    insuranceCoverage: '₹1200.00',
    place: 'Mumbai, maharashtra',
    rate: '₹2000 / per hour',
    AboutMe:
      'Aerial and ground based Images and video, Commercial Real Estate , Acreage, construction, real estate and ranches. Clients success is our success!  Aerial and ground based Images and video, Commercial Real Estate , Acreage, construction, real estate and ranches. Clients success is our success!',
  },
]

const specialistData = [
  {
    id: 1,
    spcial: 'Aerial School Photography',
  },
  {
    id: 2,
    spcial: 'Aerial Wedding Videography',
  },
  {
    id: 3,
    spcial: 'Construction Survey',
  },
  {
    id: 4,
    spcial: 'Aerial School Photography',
  },
  {
    id: 5,
    spcial: 'Construction Survey',
  },
  {
    id: 6,
    spcial: 'Aerial Wedding Videography',
  },
]
