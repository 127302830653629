import React from "react";
import styles from "./index.module.css";
import location from "../../assets/course Listing/location.png";
import language from "../../assets/course Listing/language.png";
import Rating from "@mui/material/Rating";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CouresCard({ data }) {
  const navigate = useNavigate();


  const handleRedirection = (id) => {
    navigate(`${id}`)
  }
  return (
    <>
      <div className={`${styles.courseCard}`}>
        <div className={styles.leftOuterContainer}>
          <div className={styles.leftContainer}>
            <img src={data?.banner} alt="" />
          </div>
          <div className={styles.Centerdetils}>
            <div>
              <h3 className={styles.title}>{data?.name}</h3>
              <div className={styles.desc}  dangerouslySetInnerHTML={{__html : data?.descriptions}}></div>
              <div className={`${styles?.locationLang}`}>
                <div className={`${styles?.locations}`}>
                  <img src={location} />
                  {data?.locations?.map((l, i) => {
                    return <span>{l} </span>;
                  })}
                </div>
                <div className={`${styles?.language}`}>
                  <img src={language} />
                  {data?.languages?.map((l, i) => {
                    return <span>{l} </span>;
                  })}
                </div>
              </div>
            </div>
            <div className={styles.ratingwitCta}>
              <div className={styles.ratingCtype}>
                <h4>
                  {data?.rating}{" "}
                  <Rating
                    name="half-rating-read"
                    precision={0.5}
                    value={data?.rating}
                    readOnly
                  />
                </h4>
                <h5 className={ `${styles.status} ${data?.modes == "Online" ? styles.online :  data?.modes == "Offline" ? styles.offline : data?.modes == "Hybrid" ?  styles.hybrid : ""  }`}>
                  {/* {data?.courseType == 1
                    ? "Online"
                    : data?.courseType == 2
                    ? "Offline"
                    : data?.courseType == 3
                    ? "Hybrid"
                    : ""}{" "} */}
                    {
                      data?.modes
                    }
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mobilecenter}>
          <div className={styles.rating}>
            <div className={styles.ratingCtype}>
              <h4>
                {data?.rating}{" "}
                <Rating
                  name="half-rating-read"
                  size="small"
                  precision={0.5}
                  value={data?.rating}
                  readOnly
                />
              </h4>
              {/* <span className={styles.coursetypetext}>
                {data?.courseType == 1
                  ? "Online"
                  : data?.courseType == 2
                  ? "Offline"
                  : data?.courseType == 3
                  ? "Hybrid"
                  : ""}{" "}
              </span> */}
              <h5 className={ `${styles.status} ${data?.modes == "Online" ? styles.online :  data?.modes == "Offline" ? styles.offline : data?.modes == "Hybrid" ?  styles.hybrid : ""  }`}>
                  {/* {data?.courseType == 1
                    ? "Online"
                    : data?.courseType == 2
                    ? "Offline"
                    : data?.courseType == 3
                    ? "Hybrid"
                    : ""}{" "} */}
                    {
                      data?.modes
                    }
                </h5>
            </div>
          </div>
          <div className={`${styles?.locationLangM}`}>
            <div className={`${styles?.locations}`}>
              <img src={location} />
              {data?.locations?.map((l, i) => {
                return <span>{l?.place},</span>;
              })}
            </div>
            <div className={`${styles?.language}`}>
              <img src={language} />
              {data?.languages?.map((l, i) => {
                return <span>{l?.lan},</span>;
              })}
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <h4>  {data?.lowest_mrp_price ? `₹ ${data?.lowest_mrp_price} ` : "" }</h4>
          <Button variant="contained" onClick={() => handleRedirection(data?.slug)}>Book Now</Button>
        </div>
      </div>
    </>
  );
}

export default CouresCard;
