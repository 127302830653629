import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function MultiCarousel({
    infinite = true,
    showDots = true,
    arrows = true,
    desktop = 1,
    ...props
  }) {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: desktop,
          slidesToSlide: desktop,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: desktop,
          slidesToSlide: desktop,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      };
  return (
    <div style={{ height: "100%" }}>
      {props.children && props.children.length > 0 && (
        <Carousel
          ssr
          arrows={arrows}
          responsive={responsive}
          infinite={infinite}
          showDots={showDots}
        >
          {props.children}
        </Carousel>
      )}
    </div>
  )
}

export default MultiCarousel