import React from 'react'
import styles from './Catogory.module.css'
import Drone from '../../assets/catogory/Drone.png'
import Batteries from '../../assets/catogory/Batteries.png'
import Propellers from '../../assets/catogory/Propellers.png'
import Carbon from '../../assets/catogory/Carbon.png'
import Camera from '../../assets/catogory/Camera.png'
import Controller from '../../assets/catogory/Controller.png'

function Catogory() {
  return (
    <div className={styles.container}>
<h2 className={styles.head}>   Shop By  <span>Category</span></h2>

<div className={styles.icons}>
{
    data.map((item,id)=>{
        return(

            <div className={styles.iconCon}>
                <div className={styles.img}>
                    <img src={item.icon}></img>
                </div>
                <p>{item.name}</p>
            </div>
        )
    })
}
</div>



    </div>
  )
}

export default Catogory
const data=[
    {
        id:1,
        icon:  Drone,
        name:"Drone",
    },
    {
        id:2,
        icon:  Batteries,
        name:"Batteries",
    },
    {
        id:3,
        icon: Propellers ,
        name:"Propellers",
    },
    {
        id:4,
        icon:Carbon  ,
        name:"Carbon Tube",
    },
    {
        id:5,
        icon: Camera ,
        name:"Camera",
    },
    {
        id:6,
        icon: Controller ,
        name:"Controller",
    },
]