import React, { useEffect, useRef, useState } from "react";
import styles from "./home.module.css";
// import backgroundImage from '../../assets/images/bannerImage.png'
import downArrow from "../../assets/images/bannerdownbtn.png";
import Share from "../../components/Share/Share";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Membership from "../../components/MembershipContainer/index";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ContactForm from "../../components/ContactForm/index";
import { api_call } from "../../Utils/Network";
import { formatRupees } from "../../Utils/HelperFunctions";
// import contacUs from '../../components/ContactForm/index'
import bannervideo from "../../assets/images/bannerVideo.mp4";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DronAsServices from "../../components/HomePageNewComponent/DronAsServicesCard/DronAsServices";
import dsimg1 from "../../assets/images/dronasservices/Agriculture.png"
import dsimg2 from "../../assets/images/dronasservices/Photography.png"
import dsimg3 from "../../assets/images/dronasservices/Land Survey.png"
import dsimg4 from "../../assets/images/dronasservices/Inspection.png"
import Aboutus from "../../components/HomePageNewComponent/Achievements/Aboutus";
import SuccessStories from "../../components/HomePageNewComponent/SuccessStories/SuccessStories";
import NewsArtical from "../../components/HomePageNewComponent/NewsArtical/NewsArtical";
import DronePilotTraining from "../../components/HomePageNewComponent/DronePilotTraining/DronePilotTraining";


function Home() {
  const [loading, setLoading] = useState(false);
  const [likeValues, setLikeValues] = useState({});
  const [bannerData, setBannerData] = useState({});
  const [cardData, setCardData] = useState([]);
  const [speakWith, setSpeakWith] = useState({});
  const [droneCardData, setDroneCardData] = useState([]);
  const [membership, setMembership] = useState({});
  const [eCenter, setECenter] = useState({});
  const [rentDP, setRentDP] = useState({});
  const navigate = useNavigate();

  const likebtnHandel = (index) => {
    setLikeValues((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const fetchData = async () => {
    setLoading(true);
    api_call
      .get(`products/v1/home_screen`)
      .then((response) => {
        // loading false
        console.log(response);
        const homePageData = response?.data?.data;
        if (homePageData !== undefined || homePageData !== null) {
          setBannerData(homePageData[0]?.data[0]);
          setCardData(homePageData[1]?.data);
          setSpeakWith(homePageData[6]?.data[0]);
          setDroneCardData(homePageData[2]?.data);
          setMembership(homePageData[3]?.data[0]);
          setECenter(homePageData[4]);
          setRentDP(homePageData[5]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // if (newData !== undefined || newData !== null ){
  //   setBannerData(newData)
  // }
  // const bannerData = newData[0]?.data[0];
  // const cardDatanew = newData?.data[0]?.data;
  const handlenavClick = (id) => {
    console.log(id, "homePageData");
    if (id != "") {
      navigate(`/product/${id}`);
    }
  };

  var settings = {
    className: styles.slickstyles,
    // dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: false,
    fade: true,
    autoplaySpeed: 6000,
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <section className={styles.bannerContainer}>
        {/* <div
          className={styles.bannerBackground}
          style={{
            backgroundImage: bannerData ? `url(${bannerData?.image})` : "none",
          }}
        ></div> */}
        <video
          className={styles.bannerVideo}
          autoPlay
          loop
          muted
          // poster={bannerData?.image}
        >
          <source src={bannervideo} type="video/mp4" />
        </video>
        <div className={styles.bannerText}>
          {/* <h4 className={styles.bannerWelcome}>{bannerData?.text_one}</h4> */}
          <Slider {...settings}>
            {bannertext?.map((v, i) => {
              return (
                <h1 key={v?.id} className={styles.bannerWelcome}>
                  {v?.text}
                </h1>
              );
            })}
          </Slider>
          {/* <h1 className={styles.bannerHeading}>{bannerData?.text_two}</h1>
          <p className={styles.bannerPara}>{bannerData?.text_three}</p> */}
          <div className={styles.downArrow}>
            <img src={downArrow} alt="" />
          </div>
        </div>
      </section>
      <section className={styles.ourServises}>
        <h3 className={styles.sectionHeading}>Our Services</h3>
        <div className={styles.cardSection}>
          {cardData?.map((info) => {
            return (
              <>
                <div className={styles.card} key={info?.id}>
                  <h4 className={styles.cardTitle}>{info?.title}</h4>
                  <div className={styles.cardImg}>
                    <img src={info?.image} alt={info?.title} />
                  </div>
                  <p className={styles.cardText}>{info?.description}</p>
                  <button className={styles.cardBtn}>Know More</button>
                </div>
              </>
            );
          })}
        </div>
      </section>
      {/* <section className={styles.speakWith}>
        <div>
          <video className={styles.bannerVideo} autoPlay loop muted>
            <source src={bannervideo} type="video/mp4" />
          </video>
        </div>
        <div className={styles.speakWithText}>
          <h4 className={styles.speakWithHeading}>{speakWith?.title}</h4>
          <p className={styles.speakWithPara}>{speakWith?.description}</p>
          <button className={styles.cardBtn}>
            {speakWith?.redirection_text}
          </button>
        </div>
      </section> */}
      <section className={styles.droneCards}>
        <h3 className={styles.sectionHeading}>Our Drones</h3>
        {/* {
          droneCardData?.map((info)=>{
            console.log(info,"andjfbskdjf");
            return(
              <>
              {info?.id}
              </>
            )
          })
        } */}
        <div className={styles.droneCardListing}>
          {droneCardData?.map((info, index) => {
            const likeValue = likeValues[index] || false;

            const mrp = formatRupees(info?.mrp);
            console.log(info, "andjfbskdjf");
            return (
              <>
                <div className={styles.droneCard}>
                  <div className={styles.droneLikeShare}>
                    <button
                      className={styles.likeBtn}
                      onClick={() => likebtnHandel(index)}
                    >
                      {likeValue ? (
                        <FavoriteIcon style={{ color: "red" }} />
                      ) : (
                        <FavoriteBorderOutlinedIcon />
                      )}
                    </button>
                    <Share />
                  </div>
                  <div className={styles.droneImg}>
                    <img src={info?.thumbnail} alt={info?.title} />
                    <p className={styles.overviewtext}>For Representation Purpose only</p>
                  </div>
                  <h4 className={styles.droneName}>{info?.title}</h4>
                  {/* <h5 className={styles.dronePrice}>{mrp}</h5> */}
                  {/* <span>Colours</span> */}
                  <div className={styles.droneColorBar}>
                    {info?.colour?.map((colorValue) => (
                      <div
                        className={styles.droneColor}
                        style={{ backgroundColor: colorValue?.name }}
                        key={colorValue?.id}
                      ></div>
                    ))}
                  </div>
                  <div className={styles.buttonCon}>
                    <div className={styles.knowmorebtn}>
                      <button className={styles.knowmoreforcard}>Know More <KeyboardDoubleArrowRightIcon /> </button>
                    </div>
                    <Button
                      className={styles.cardBtn}
                      variant="contained"
                      onClick={() => handlenavClick(info?.id)}
                    >
                      Enquire Now
                    </Button>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        {/* <div className={styles.droneCard}>
          <div className={styles.droneLikeShare}>
            <button className={styles.likeBtn}>
                  <FavoriteBorderOutlinedIcon />
            </button>
            <Share />
          </div>
          <div className={styles.droneImg}>
            <img src={drone1} alt='drone1' />
          </div>
          <h4 className={styles.droneName}>Photography Drone</h4>
          <h5 className={styles.dronePrice}>₹50,000</h5>
          <span>Colours</span>
          <div className={styles.droneColorBar}>
            <div className={styles.droneColor}></div>
          </div>
          <div><button className={styles.cardBtn}>Buy</button></div>
        </div> */}
      </section>
      {/* <section>
        <Membership membership={membership} />
      </section> */}
      <DronePilotTraining />
      <section className={styles.excellenceCenterContainer}>
        <h3 className={styles.sectionHeading}>{eCenter?.title}</h3>
        <div className={styles.sectionPara}>
          <p>
            Aerobott is devoted to excellence in teaching, learning, research
            and developing quality UAV pilots capable of executing multiple
            application projects.
          </p>
        </div>
        <button className={styles.cardBtn}>
          Explore our excellency center
        </button>
        <div className={styles.excellenceCenterCards}>
          {eCenter?.data?.map((info) => {
            return (
              <>
                <div className={styles.excellenceCenterCard}>
                  <div className={styles.excellenceCenterImg}>
                    <img src={info.image} alt={info.title} />
                  </div>
                  <p>{info.title}</p>
                </div>
              </>
            );
          })}
        </div>
      </section>
      <section className={styles.rentContainer}>
        <h3 className={styles.sectionHeading}>{rentDP?.title}</h3>
        <div className={styles.sectionPara}>
          <p>
            Aerobott is devoted to excellence in teaching, learning, research
            and developing quality UAV pilots capable of executing multiple
            application projects.
          </p>
        </div>
        <button className={styles.cardBtn}>Rent A drone Today!</button>
        <div className={styles.rentingCards}>
          {rentDP?.data?.map((info) => {
            return (
              <>
                <div className={styles.rentingCard} key={info?.id}>
                  <div className={styles.rentingImg}>
                    <img src={info.image} alt={info.title} />
                  </div>
                  <p>{info.title}</p>
                </div>
              </>
            );
          })}
        </div>
      </section>
      <section className={styles.droneAsaService}>
      <h3 className={styles.sectionHeading}>Drones As A Service</h3>
          <div className={`${styles.ServiceListings}`}>
            <DronAsServices values={DroneServices} />
          </div>
      </section>

      {/* <section className={styles.aboutUs}> */}
        <Aboutus />
        <SuccessStories />

        <NewsArtical />
      {/* </section> */}
      {/* <section> */}
      <ContactForm />

      {/* </section> */}
    </>
  );
}

export default Home;

const bannertext = [
  {
    id: 1,
    text: "Explore Unparalleled Aerial Solutions with the Best Drones in India",
  },
  {
    id: 2,
    text: "Transform from Novice to Expert with our DGCA Certified Specialized Drone Pilot Training Course",
  },
  {
    id: 3,
    text: "Discover the cutting-edge mastery with the best drone agriculture, survey and mapping services in India",
  },
  {
    id: 4,
    text: "Scale up your projects to perfection; Hire a pilot for your location for seamless flights! ",
  },
];

const DroneServices = [
  {
    id: 1,
    img:dsimg1,
    title: "Agriculture Services ",
    description:
      "Drone technology is an advanced aerial solution to monitor and manage the fields. It helps provide accurate solutions to farmers, capturing real-time data on moisture levels, crop health, pest infections and other requirements. ",
  },
  {
    id: 2,
    img:dsimg2,
    title: "Survey & Mapping",
    description:
      "Introducing drone technology in survey and mapping makes the entire process efficient and cost-effective. Drones can help transform the whole working of the industry using high-resolution image capturing, real-time data acquired from construction sites, geographic mapping, incorporating 3D models, and more. The technology is a one-stop solution for streamlining the workflow.",
  },
  {
    id: 3,
    img:dsimg3,
    title: "Photography & Videography",
    description:
      "Photography and videography can soar notches higher by incorporating drones to capture enchanting shots. From cinematic aerial shots to taking visual storytelling to a new paradigm, capture images with stunning precision. Welcome awe-inspiring storytelling with a touch of breathtaking views and visual treats.",
  },
  {
    id: 4,
    img:dsimg2,
    title: "Drone Parts & Repairs",
    description:
      "Keep your Drone flying and soaring high in the sky, reaching peak performance. Trust our expert technicians here to maximise the lifespan of your drone investments. Quick fixes, replacing damaged parts and minimising downtime to ensure top-notch performance, we always help keep your Drone mission-ready!",
  },
  {
    id: 5,
    img:dsimg4,
    title: "Commercial Inspection Services",
    description:
      "Incorporating drones in commercial inspection services adds value as well as cost optimization. It can transform the entire inspection and data analytics process by capturing precise data, inspecting pipelines, landscapes, powerlines, solar panels and an array of infrastructure, where technology will minimize the risks related to manual data collection.",
  }
];


