import React from 'react'
import styles from './SubHader.module.css'
import Drone from '../../assets/catogory/Drone.png'
import Batteries from '../../assets/catogory/Batteries.png'
import Propellers from '../../assets/catogory/Propellers.png'
import Carbon from '../../assets/catogory/Carbon.png'
import Camera from '../../assets/catogory/Camera.png'
import Controller from '../../assets/catogory/Controller.png'

function SubHader() {
  return (
   <>
     <hr className={styles.hr} />
            <div className={styles.headCon}>
                <div className={styles.header}>

                    {data.map((item) => {
                        return (



                            <div className={styles.iconCon}>
                                <div className={styles.icon}>

                                    <img src={item.icon} ></img>
                                </div>
                                <p className={styles.descName}>{item.name}</p>
                            </div>



                        )
                    })}



                </div>
            </div>
   
   
   
   </>
  )
}

export default SubHader
const data = [
    {
        id: 1,
        icon: Drone,
        name: "Drone",
    },
    {
        id: 2,
        icon: Batteries,
        name: "Batteries",
    },
    {
        id: 3,
        icon: Propellers,
        name: "Propellers",
    },
    {
        id: 4,
        icon: Carbon,
        name: "Carbon Tube",
    },
    {
        id: 5,
        icon: Camera,
        name: "Camera",
    },
    {
        id: 6,
        icon: Controller,
        name: "Controller",
    },
]
