import React from 'react'
import styles from './BuyDrones.module.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from '@mui/material';
import logo1 from '../../assets/BuyDrones/logo1.png'
import logo2 from '../../assets/BuyDrones/logo2.png'
import logo3 from '../../assets/BuyDrones/logo3.png'
import img1 from '../../assets/BuyDrones/img1.png'
import img2 from '../../assets/BuyDrones/img2.png'
import img3 from '../../assets/BuyDrones/img3.png'
import yes from '../../assets/BuyDrones/yes.png'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
function CustomPrevArrow(props) {
    const { onClick } = props;
    return (
        <button
            // className="custom-arrow-banner custom-prev-arrow-banner"
            className={`${styles.customarrow} ${styles.customprevarrow}`}
            onClick={onClick}
        >
            <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
        </button>
    );
}

function CustomNextArrow(props) {
    const { onClick } = props;
    return (
        <button
            // className="custom-arrow-banner custom-next-arrow-banner"
            className={`${styles.customarrow} ${styles.customnextarrow}`}
            onClick={onClick}
        >
            <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
        </button>
    );
}

var settings = {
    className: styles.slickstyles,
    // dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    // fade: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};




function BuyDrones() {
    return (
        <div className={styles.container}>
            <h2 className={styles.head}>Buy <span>DGCA</span> Certified Drones</h2>



            <Slider {...settings}>


                {data.map((item, id) => {
                    return (

                        <>
                            <div className={styles.cards}>

                                <div className={styles.img}>
                                    <img src={item.image}></img>
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.logos}>

                                        <div> <img src={item.logo}></img></div>
                                        <div className={styles.certified}>
                                            <div>
                                                <img src={item.yesIcon}></img>
                                            </div>
                                            <p>{item.cerified}</p>
                                        </div>

                                    </div>
                                    <div className={styles.desc}>
                                        <p className={styles.company}>{item.company}</p>
                                        <h4>{item.name}</h4>
                                        <p className={styles.company}>{item.made}</p>
                                    </div>
                                    <div  className={styles.btn}>
                                    <Button variant="contained" endIcon={<ArrowOutwardIcon/>}>View Details</Button>
                                </div>
                                </div>
                            </div>




                        </>
                    )
                })
                }

            </Slider>
        </div >
    )
}

export default BuyDrones
const data = [
    {
        id: 1,
        image: img1,
        logo: logo1,
        yesIcon: yes,
        cerified: "DGCA Certified",
        company: "Garuda Aerospace",
        name: 'Kisan Agri Drone V.1',
        made: "Made in India",

    },
    {
        id: 2,
        image: img2,
        logo: logo2,
        yesIcon: yes,
        cerified: "DGCA Certified",
        company: "Garuda Aerospace",
        name: 'SYENA- H10',
        made: "Made in India",

    }, {
        id: 2,
        image: img3,
        logo: logo3,
        yesIcon: yes,
        cerified: "DGCA Certified",
        company: "Garuda Aerospace",
        name: 'Agricopter AG365',
        made: "Made in India",

    },
]