import React from 'react'
import back from '../../assets/images/backBtn.png'
import styles from './index.module.css'

function BackButton({ url, text, style = {} }) {
  const handleClick = (url) => {
    window.location.href = url
  }

  return (
    <>
      {/* <div className={styles.mainContainer}> */}
        <div className={styles.backButton} onClick={() => handleClick(url)}>
          <img src={back} alt="" />
        </div>
      {/* </div> */}
    </>
  )
}

export default BackButton
