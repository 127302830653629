import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./BreadCrumbs.module.css";
import { isNumeric, removeNonAlphabeticCharacters } from '../../components/Utility/Helper'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const Breadcrumbs = () => {

    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);

    const validPathnames = pathnames.filter(
        (name) => !isNumeric(name) && removeNonAlphabeticCharacters(name)
    ); // change for breadcrumb ,remove nubmers from url

    console.log(validPathnames, "validPathnames");
    return (
        <nav aria-label="breadcrumb">
            <div className={styles.breadCrumbContainer}>
                {validPathnames.map((name, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                    const isLast = index === validPathnames.length - 1;
                    name = removeNonAlphabeticCharacters(name);
                    return isLast ? (
                        <div
                            key={name}
                            className={`${styles.bread} ${styles.activeBreadCrumb}`}
                            aria-current="page"
                        >
                            {index === 0 && (
                             <ArrowBackIcon/>
                            )}
                         
                            <span> / </span>{name}
                           
                        </div>
                    ) : (
                        <div
                            key={name}
                            className={`${styles.bread} ${styles.inActiveBreadCrumb}`}
                        >
                            {index === 0 && (
                        <ArrowBackIcon/>
                            )}
                            <Link to={routeTo}>
                            <span> / </span>  {name}
                            </Link>
                        </div>
                    );
                })}
            </div>
        </nav>
    );
};

export default Breadcrumbs;
