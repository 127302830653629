import React from 'react'
import styles from './DroneStorePage.module.css'
import yes from '../../assets/BuyDrones/yes.png'
import logoMarut from '../../assets/store/logoMarut.png'
import back from '../../assets/store/back.png'
import StoreSlider from '../../components/StoreSlider/StoreSlider'
import { Button } from '@mui/material'
import ProductDetails from '../ProductDetails/ProductDetails'
import ProductSpecification from '../../components/ProductSpecification/ProductSpecification'
import GetDroneSlider from '../../components/GetDroneSlider.js/GetDroneSlider'
import Video from '../../components/Video/Video'
import Faqs from '../../components/Faqs/Faqs'
import SubHader from '../../components/SubHader/SubHader'

function DroneStorePage() {
    return (
        <>
        <SubHader/>
            {/* <hr className={styles.hr} />
            <div className={styles.headCon}>
                <div className={styles.header}>

                    {data.map((item) => {
                        return (



                            <div className={styles.iconCon}>
                                <div className={styles.icon}>

                                    <img src={item.icon} ></img>
                                </div>
                                <p className={styles.descName}>{item.name}</p>
                            </div>



                        )
                    })}



                </div>
            </div> */}
            <div className={styles.container}>

                <div className={styles.top}>

                    <div className={styles.back}>
                        <img src={back}></img>
                    </div>
                    <div>

                        <span className={styles.backText}>   Back to all</span>

                        <p className={styles.desc}>  <span>Drones</span>/ Marut Drones Agricopter AG36 </p>
                    </div>



                </div>

                <hr className={styles.hr}></hr>

                <div className={styles.bottom}>
                    <div className={styles.left}>
                        <img src={logoMarut}></img>
                    </div>
                    <div className={styles.right}>



                        <div>
                            <img src={yes}></img>

                        </div>
                        <p>DGCA Certified</p>
                    </div>


                </div>




            </div>
            <StoreSlider />
            <div className={styles.sliderDesc}>
                <div className={styles.sliderL}>

                    <p className={styles.company}> <span>Marut Drones</span>
                        <br />
                        Agricopter AG365 </p>
                </div>
                <div className={styles.sliderRight}>
                    <span>₹ 4,56,892</span>
                    <button variant="contained" className={styles.buy}>Buy Now</button>
                </div>
            </div>

            <ProductSpecification />

            <Video/>
            <GetDroneSlider />
            <Faqs/>
        </>
    )
}

export default DroneStorePage
// const data = [
//     {
//         id: 1,
//         icon: Drone,
//         name: "Drone",
//     },
//     {
//         id: 2,
//         icon: Batteries,
//         name: "Batteries",
//     },
//     {
//         id: 3,
//         icon: Propellers,
//         name: "Propellers",
//     },
//     {
//         id: 4,
//         icon: Carbon,
//         name: "Carbon Tube",
//     },
//     {
//         id: 5,
//         icon: Camera,
//         name: "Camera",
//     },
//     {
//         id: 6,
//         icon: Controller,
//         name: "Controller",
//     },
// ]
