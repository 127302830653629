import React from "react";
import styles from "./index.module.css";
import Share from "../../components/Share/Share";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";

function TrainingProgramCard({ info, index, likeValues, likebtnHandel }) {
    const likeValue = likeValues[index] || false;
  return (
    <div className={styles.card}>
      <div className={styles.imgContainer}>
        <img src={info?.trainingImg} alt={info?.title} />
        <div className={styles.droneLikeShare}>
          <button
            className={styles.likeBtn}
            onClick={() => likebtnHandel(index)}
          >
            {likeValue ? (
              <FavoriteIcon style={{ color: "red" }} />
            ) : (
              <FavoriteBorderOutlinedIcon />
            )}
          </button>
          <Share />
        </div>
      </div>
      <div className={styles.cardInfoBox}>
        <h4 className={styles.cardTitle}>{info?.title}</h4>
        <div className={styles.iconContainer}>
            <p className={styles.iconBox}><img src={info?.locationIcon} alt="" />{info?.place} </p>
            <p className={styles.iconBox}><img src={info?.eventIcon} alt="" />{info?.dateinfo} </p>
        </div>
        <p className={styles.trainingInfo}>{info?.trainingdec}</p>
        
      </div>
    </div>
  );
}

export default TrainingProgramCard;
