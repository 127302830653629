import React, { useState } from 'react'
import styles from './index.module.css'
import pilotspic from '../../../assets/images/pilotpic-po.png'
import mail from '../../../assets/images/email.png'
import dronPic from '../../../assets/images/orderDronImg.png'
import DroneOrderComponent from '../../DroneOrderComponent/DroneOrderComponent'

function ProfileComponent() {
  const handleCounterUpdate = (updatedCounter) => {
    console.log('Counter updated:', updatedCounter);
    // You can perform any necessary actions here
  };
  const handleCancel = () => {
    console.log('Item cancelled');
    // Handle cancellation logic
  };
  // const droneOrder = [
  //   // ...
  // ];

  const pilotdetails = [
    {
      id: 1,
      pilotpic: 'pilotspic',
      pname: 'Aerouser_pxHXTdytBxlr',
      pEmail: 'abcddadsd1254@gmail.com',
      pmailIcon: '',
      jobcompleted: 121,
      upComingJob: 5,
      earning: '₹24000',
    },
  ]
  ////////////// email modification function ///////////////////////////////////

  const email = pilotdetails[0].pEmail
  console.log(email, '12132edqdeqwdqdqd')
  const hideEmail = (email) => {
    const atIndex = email.indexOf('@')
    const dotIndex = email.lastIndexOf('.com')

    if (atIndex !== -1 && dotIndex !== -1 && dotIndex > atIndex) {
      const username = email.substring(0, atIndex) // Extract the username part
      const domain = email.substring(atIndex + 1, dotIndex) // Extract the domain part

      const hiddenUsername =
        username.substring(0, 2) + '*'.repeat(username.length - 2) // Hide most of the username
      const hiddenDomain = '*'.repeat(domain.length) // Hide the domain

      return hiddenUsername + '@' + hiddenDomain + '.com'
    }

    return email
  }

  const hiddenEmail = hideEmail(email)
///////////////////////end/////////////////////////////////////////////////////////////////
  return (
    <>
      <div className={styles.mainBox}>
        <div className={styles.topDetailsBar}>
          <div className={styles.profileCard}>
            <div className={styles.profilePic}>
              <img src={pilotspic} alt="" />
            </div>
            <div className={styles.profileText}>
              <h5 className={styles.pname}>{pilotdetails[0].pname}</h5>
              <div className={styles.email}>
                <span>
                  <img src={mail} />
                </span>
                <p>{hiddenEmail}</p>
              </div>
              <button className={styles.mmaBtn}>Manage My Account</button>
            </div>
          </div>
          <div className={styles.jobBox}>
            <p>Jobs Completed</p>
            <h3>{pilotdetails[0].jobcompleted}</h3>
          </div>
          <div className={styles.jobBox}>
            <p>Upcoming Jobs</p>
            <h3>{pilotdetails[0].upComingJob}</h3>
          </div>
        </div>

        {/* listing box start */}
        <div className={styles.listingBox}>
          <h4>Order</h4>
          <p>Item</p>
          {droneOrder.map((info) => {
            return (
          <DroneOrderComponent 
          key={info.id}
          info={info}
          onUpdateCounter={handleCounterUpdate}
          onCancel={handleCancel} />
          )
        })}
      </div>
       
      </div>
    </>
  )
}

export default ProfileComponent

const droneOrder = [
  {
    id: 1,
    img: dronPic,
    dName: 'd1',
    dPrice: '60,000',
    delivaryDate: 'Delivered on 23 May’23',
  },
  {
    id: 2,
    img: dronPic,
    dName: 'd2',
    dPrice: '50,000',
    delivaryDate: 'Delivered on 23 May’23',
  },
  {
    id: 3,
    img: dronPic,
    dName: 'd3',
    dPrice: '45,000',
    delivaryDate: 'Delivered on 23 May’23',
  },
  {
    id: 4,
    img: dronPic,
    dName: 'd4',
    dPrice: '70,000',
    delivaryDate: 'Delivered on 23 May’23',
  },
]
