import React, { useState } from 'react'
import styles from './index.module.css'
import TextField from '@mui/material/TextField'
import LocationSelect from '../../MapComponent/MapSerach'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

function EditProfile() {
    const [specializesInfo, setSpecializesInfo] = useState([
        {
            id: 1,
            ischeck: true,
            specializ: 'Aerial Mapping',
          },
          {
            id: 2,
            ischeck: true,
            specializ: 'Aerial Photography',
          },
          {
            id: 3,
            ischeck: true,
            specializ: 'Aerial Videography',
          },
          {
            id: 4,
            ischeck: false,
            specializ: 'TV/Film',
          },
          {
            id: 5,
            ischeck: true,
            specializ: 'Land Survey',
          },
          {
            id: 6,
            ischeck: false,
            specializ: 'Agriculture Survey',
          },
          {
            id: 7,
            ischeck: true,
            specializ: 'Crop Health Analysis',
          },
          {
            id: 8,
            ischeck: false,
            specializ: 'GIS Survey',
          },
          {
            id: 9,
            ischeck: false,
            specializ: 'Aerial Inspection',
          },
          {
            id: 10,
            ischeck: true,
            specializ: 'Thermal Imaging',
          },
    ])

    const handleCheckboxChange = (id) => {
        const updatedSpecializesInfo = specializesInfo.map((info) =>
          info.id === id ? { ...info, ischeck: !info.ischeck } : info
        );
        setSpecializesInfo(updatedSpecializesInfo);
      };
      console.log(specializesInfo , 'CheckBox')

  return (
    <div className={styles.editProfileContainer}>
      <form className={styles.formContainer}>
        <div className={styles.textfield}>
          <TextField
            id="outlined-basic"
            label="Full Name"
            variant="outlined"
            sx={{ width: '100%' }}
          />
        </div>
        <div className={styles.textfield}>
          <TextField
            id="outlined-basic"
            label="Licence No"
            variant="outlined"
            sx={{ width: '100%' }}
          />
        </div>
        <div className={styles.textfield}>
          <TextField
            id="outlined-basic"
            label="Insurance Coverage"
            variant="outlined"
            sx={{ width: '100%' }}
          />
        </div>
        <div className={styles.textfield}>
          <TextField
            id="outlined-basic"
            label="Avg Rate"
            variant="outlined"
            sx={{ width: '100%' }}
          />
        </div>
        <div className={styles.textfield}>
          <TextField
            id="outlined-basic"
            label="Mobile No."
            variant="outlined"
            sx={{ width: '100%' }}
          />
        </div>
        <div className={styles.textfield}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            sx={{ width: '100%' }}
          />
        </div>

        <div className={styles.textfield}>
          <TextField
            id="outlined-basic"
            label="Addres"
            variant="outlined"
            sx={{ width: '100%' }}
          />
        </div>
        <div className={styles.fullWidth}>
          <h3>About Me</h3>
          <TextField
            id="outlined-basic"
            label="About"
            variant="outlined"
            multiline
            rows={3}
            sx={{ width: '100%' }}
          />
        </div>
        <div className={styles.fullWidth}>
          <h3>Specialized In</h3>
          <div>
            {specializesInfo.map((info) => {
              
              return (
                <>
                  <FormControlLabel
                    key={info.id}
                    control={
                      <Checkbox
                        
                        label={info.specializ}
                        checked={info.ischeck}
                        onChange={() => handleCheckboxChange(info.id)}
                      />
                    }
                    label={info.specializ}
                  ></FormControlLabel>
                </>
              
              )
            })}
          </div>
        </div>
        <div className={styles.btnGroup}>
            <button className={styles.saveBtn}>Save</button>
            <button className={styles.cancleBtn}>Cancel</button>
        </div>
      </form>
    </div>
  )
}

export default EditProfile


