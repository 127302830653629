import React, { useState } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import styles from './index.module.css'
import LocationOnIcon from "@mui/icons-material/LocationOn";

const MapSerach = ({ placeholder,onSelectLatLng }) => {
  const [address, setAddress] = useState('')


const handleChange = (newAddress) => {
  setAddress(newAddress)
}

const handelSelect = (selectedAddress) => {
  console.log("QQQQQQQqq" , selectedAddress);
  geocodeByAddress(selectedAddress)
    .then((results) => getLatLng(results[0]))
    .then((latLng) => onSelectLatLng(latLng)) 
    .catch((error) => console.error('Error', error))
}

return (
  <>
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handelSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
            <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '5px',
                }}
              >
                <IconButton sx={{ p: '10px' }} aria-label="menu">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                //   placeholder="Search by Name"
                //   inputProps={{ 'aria-label': 'search google maps' }}
                //   onChange={handleAddressChange}
                {...getInputProps({
                    placeholder: placeholder || 'Search Places ...',
                    className: 'location-search-inputs',
                  })}
                />
              </Paper>
          {/* <input
            {...getInputProps({
              placeholder: placeholder || 'Serch Places ...',
              className: 'location-search-inputs',
            })}
          /> */}
          <div className={styles.autocompletedropdowncontainer}>
            {/* {loading && <div>Loading...</div>} */}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? `${styles.suggestionActive}`
                : `${styles.suggestion}`
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' }
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <p className={styles.locationDropdownList}><LocationOnIcon className={styles.listLocationMark} sx={{ color: "text.secondary" }} />{suggestion.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  </>
);

};

export default MapSerach;