import React from 'react'
import styles from './Specification.module.css'
function Specification() {
    return (
        <>
            <div className={styles.container}>
                <h2 className={styles.heading}>Specification</h2>

                <div >


                    {data.map((item) => {
                        return (

                            <div >
                                <div className={styles.details}>

                                    <p className={styles.name}>{item.name}</p>
                                    <p className={styles.desc}>{item.desc}</p>
                                </div>
                                <hr className={styles.hr}/>

                            </div>)
                    })}




                </div>


            </div>





        </>
    )
}

export default Specification
const data = [
    {
        id: 1,
        name: "Brand ",
        desc: "mPower Lithium "
    },
    {
        id: 2,
        name: " Capacity",
        desc: " mPower 25200mAh"
    }, {
        id: 3,
        name: " Cells",
        desc: " Cyclindrical"
    },
    {
        id: 4,
        name: " Charge current: upto 1c",
        desc: " 25A"
    },
    {
        id: 5,
        name: " Charging Voltage",
        desc: "25.2V "
    },
    {
        id: 6,
        name: " Compatible With",
        desc: " Any 10L drone"
    },
    {
        id: 7,
        name: "Configuration ",
        desc: "6S6P/21.6V "
    },
    {
        id: 8,
        name: "Connectors ",
        desc: "XT90-S "
    },
    {
        id: 9,
        name: " Cooling Type",
        desc: "Air "
    },
    {
        id: 10,
        name: "Country Of Origin ",
        desc: "India "
    },
    {
        id: 11,
        name: " Discharge Plug",
        desc: " XT90-s"
    },
    {
        id: 12,
        name: " Discharge Rate",
        desc: " 11C"
    },
    {
        id: 13,
        name: " Lower cut off voltage",
        desc: "15V "
    }, {
        id: 14,

        name: "Max. Dis Current ",
        desc: "270A "
    }, {
        id: 15,

        name: "Nominal Voltage ",
        desc: " 21.6V"
    },
    {
        id: 16,
        name: "Series ",
        desc: " 6S"
    },
    {
        id: 17,
        name: " Set Charging Voltage",
        desc: "25.2V "
    },
    {
        id: 18,
        name: "Set Discharge Voltage ",
        desc: "18V"
    },
]