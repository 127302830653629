import React, { useState } from 'react'
import styles from './index.module.css'
import PersonIcon from '@mui/icons-material/Person'
import LocalMallIcon from '@mui/icons-material/LocalMall'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ProfileComponent from '../../components/AccountOverviewComponent/ProfileCompnent/ProfileComponent'
import ProfileSetting from '../../components/AccountOverviewComponent/ProfileSetting/ProfileSetting'
import Order from '../../components/AccountOverviewComponent/OrdersComponent/Order'
import Whishlist from '../../components/AccountOverviewComponent/WhishlistComponent/Whishlist'
import JobComponent from '../../components/AccountOverviewComponent/JobComponent/JobComponent'
import EditProfile from '../../components/AccountOverviewComponent/EditProfile/EditProfile'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

function AccountOverview() {
  const [activeTab, setActiveTab] = useState(0)
  const navigate = useNavigate()

  const handleTabClick = (tabId) => {
    setActiveTab(tabId)
  }
  console.log(activeTab, 'activeTab')

  const profileTab = [
    {
      id: 1,
      icons: <PersonIcon />,
      tabName: 'Profile Setting',
      tabLink:'profile-setting',
      comp : ProfileSetting,
    },
    {
      id: 2,
      icons: <LocalMallIcon />,
      tabName: 'Orders',
      tabLink:'orders',
      comp : Order,
    },
    {
      id: 3,
      icons: <FavoriteIcon />,
      tabName: 'Wishlist',
      tabLink:'wishlist',
      comp : Whishlist,
    },
    {
      id: 4,
      icons: <BusinessCenterIcon />,
      tabName: 'Jobs',
      tabLink:'jobs',
      comp : JobComponent,
    },
  ]

  console.log(styles.tabListing, 'tabination')

  return (
    <section className={styles.mainAccountOverview}>
      <div className={styles.leftsideTabContainer}>
        <h1 className={styles.pageHeading}>Account Overview</h1>
        <div className={styles.tabListing}>
          {profileTab.map((info) => {
            console.log(activeTab, 'activeTab')
            return (
              <>
                <div
                  className={`${styles.tabName} ${
                    info.id === activeTab ? styles.tabActive : ''
                  }`}
                  key={info.id}
                  onClick={() => handleTabClick(info.id)}
                >
                  {/* <NavLink to={info.tabLink} onClick={() => navigate(info.navLink)}> */}
                  <p className={styles.tabIcon}>{info.icons}</p>
                  <p className={styles.tabText}>{info.tabName}</p>
                  {/* </NavLink> */}
                </div>
              </>
            )
          })}
        </div>
      </div>
      <div className={styles.rightsideContainer}>

        {/* <Outlet /> */}
        {activeTab === 0 && <ProfileComponent />}
        {activeTab === 1 && <ProfileSetting onButtonClick={handleTabClick}/>}
        {activeTab === 2 && <Order/>}
        {activeTab === 3 && <Whishlist />}
        {activeTab === 4 && <JobComponent />}
        {activeTab === 5 && <EditProfile />}
      </div>
    </section>
  )
}

export default AccountOverview
