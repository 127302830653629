import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import dronImg from "../../assets/images/productMainImg.png";
import Share from "../../components/Share/Share";
import dronShot from "../../assets/images/dronShot.png";
import droneImg1 from "../../assets/images/img-1.png";
import droneImg2 from "../../assets/images/img-2.png";
import droneImg3 from "../../assets/images/img-3.png";
import droneImg4 from "../../assets/images/Img-4.png";
import accessoriesimg1 from "../../assets/images/access-1.png";
import accessoriesimg2 from "../../assets/images/access-2.png";
import accessoriesimg3 from "../../assets/images/access-3.png";
import CustomAccordion from "../../components/CustomAccordion/CustomAccordion";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import reviewImg from "../../assets/images/ReviewYourData.png"
import Membership from "../../components/MembershipContainer/index"
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { api_call } from "../../Utils/Network";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

function ProductDetails() {
  const [productData, setProductData] = useState({})
  const [likeValues, setLikeValues] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [starValue, setStarValue] = useState(1);
  const [bannerImg, setBannerImg] = useState();
  const { id } = useParams()
  // const likeValue = likeValues[index] || false;
  const likebtnHandel = () => {
    setLikeValues(!likeValues);
  };
  console.log(id, "detailsPage");
  const getProductDetails = () =>{
    api_call
    .get(`products/v1/product/detail/${id}/`)
    .then((res) =>{
      // console.log(res?.data?.data?.product_image, "getProductDetails");
      setProductData(res?.data?.data)
      setBannerImg(res?.data?.data?.product_image)
    })
    .catch((err) =>{
      console.log(err);
    })
  }
  console.log(bannerImg, "getProductDetails");
  useEffect(() =>(
   
    getProductDetails()
    
  ),[id]);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <section className={styles.topSection}>
        <div className={styles.leftsideContainer}>
          <div className={styles.droneLikeShare}>
            <button className={styles.likeBtn} onClick={() => likebtnHandel()}>
              {likeValues ? (
                <FavoriteIcon style={{ color: "red" }} />
              ) : (
                <FavoriteBorderOutlinedIcon />
              )}
            </button>
            <Share />
          </div>
          <div className={styles.imgContainer}>
          <MultiCarousel
            autoPlay
            autoPlaySpeed={3000}
            infinite
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
                // partialVisibilityGutter: 40,
                slidesToSlide: 1,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1,
                slidesToSlide: 1,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1,
              },
            }}
            // itemClass={styles.customCarouselItem}
          >
            {bannerImg?.length > 0 ? 
              bannerImg?.map((v) =>{
                return(
                 <div key={v?.id} style={{margin:"0 auto", textAlign:"center"}}>
                  <img src={v?.images}   />
                </div>
                )
              })  :
              <p>no Image</p>
            }
      
             </MultiCarousel>
            
            </div>
          {/* <div className={styles.buttonContainer}>
            <button className={`${styles.classicbtn} ${styles.activebtn}`}>
              Photos
            </button>
            <button className={`${styles.classicbtn}`}>Videos</button>
            <button className={`${styles.classicbtn}`}>3D</button>
          </div> */}
        </div>

        <div className={styles.rightsideContainer}>
          <div className={styles.mobileShow}>
          {/* <h4 className={styles.colorHeading}> Colours Available</h4>
          <div className={styles.droneColorBar}>
            {colordata?.map((colorValue) => (
              <div
                className={styles.droneColor}
                style={{ backgroundColor: colorValue?.color }}
                key={colorValue?.id}
              ></div>
            ))}
          </div> */}
          </div>
          <h1 className={styles.droneName}>{productData?.title}</h1>
          {/* <h2 className={styles.droneprice}> ₹16,000.00 </h2> */}
          <div className={styles.mobileShow}>
          <Button disabled className={styles.cardBtn} variant="contained">Buy</Button>
          {/* <button disabled className={styles.cardBtn}>Buy</button> */}
          </div>
          <ul>
            <li>Cutting-edge aerial technology brought to your fingertips</li>
          </ul>
          <div className={styles.showinpc}>
          <h4 className={styles.colorHeading}> Colours Available</h4>
          <div className={styles.droneColorBar}>
            {colordata?.map((colorValue) => (
              <div
                className={styles.droneColor}
                style={{ backgroundColor: colorValue?.color }}
                key={colorValue?.id}
              ></div>
            ))}
          </div>
          <Button disabled className={styles.cardBtn} variant="contained">Buy</Button>
          {/* <button className={styles.cardBtn}>Buy</button> */}
          </div>
        </div>
      </section>
      {/* <section className={styles.videoContainer}>
        <h2 className={styles.sectionheading}>
          Take a Closer Look at the <span>Features</span>
        </h2>
        <div className={styles.imgbox}>
          <img src={dronShot} alt="dron-Shot" />
          <h5>1080 px camera for ultra high videos </h5>
        </div>
      </section> */}
      <section className={styles.aboutSection} >
        <h2 className={styles.aboutsectionheading}>
          About {productData?.title}
        </h2>
        {/* <h4 className={styles.aboutHeading}>
          F
        </h4> */}
        <div dangerouslySetInnerHTML={{__html:productData?.about_us}}></div>
      </section>
      <section className={styles.multiImgcontainer}>
        {imgData?.map((v) => {
          return (
            <div className={styles.imgBoxes}>
              <img src={v?.Img} />
            </div>
          );
        })}
      </section>
      {/* <section className={styles.accessoriesName}>
        <h4 className={styles.accessoriesHeading}>Accessories Included</h4>
        <div className={styles.imgaccessListing}>
          {accessData?.map((v) => {
            return (
              <div>
                <div className={styles.accessoriesimgBox}>
                  <img src={v?.Img} />
                </div>
                <p className={styles.accessName}>{v?.title}</p>
              </div>
            );
          })}
        </div>
      </section> */}

      <section className={styles.droneFetures}>
      <h4 className={styles.accessoriesHeading}>Explore More Features of the Drone</h4>
      <div className={styles.fetureaListing}>
        
      </div>
      </section>
      <section className={styles.textSection}>
        <h4 className={styles.textSectionHeading}>
          About the Company
        </h4>
        <p>
         {productData?.description}
        </p>
        {/* <p>
          Equipped with high-resolution cameras, our drone captures stunning
          aerial photos and videos with remarkable clarity and detail. Whether
          you're a professional photographer, an aspiring filmmaker, or an
          adventure enthusiast, this drone unleashes your creativity and enables
          you to capture stunning moments from above.
        </p>
        <p>
          Safety is our utmost priority, and our drone is packed with
          intelligent features that enhance flight security and prevent
          accidents. With obstacle detection and avoidance technology, you can
          fly confidently, knowing that the drone will navigate its surroundings
          and avoid potential hazards automatically.
        </p>
        <p>
          Controlling the drone is a breeze, thanks to the user-friendly
          interface and intuitive remote controller. Whether you're a beginner
          or an experienced pilot, our drone offers a seamless flying
        </p> */}
      </section>
      <section className={styles.textSection}>
        <h4 className={styles.textSectionHeading}>
          Frequently asked questions
        </h4>
        {Accordiondata?.map((v) => {
          return (
            <CustomAccordion
              key={v.id}
              title={v.title}
              para={v.para}
              isExpanded={expanded === v.id}
              onChange={() => {
                if (expanded === v.id) {
                  // If the same accordion is clicked, close it
                  setExpanded(null);
                } else {
                  setExpanded(v.id);
                }
              }}
            />
          );
        })}
      </section>
      <section className={styles.textSection}>
        <h4 className={styles.textSectionHeading}>
          Explore More Features of the Drone
        </h4>
        <div className={styles.reviewSections}>
          <div className={styles.reviewCount}>
            <div className={styles.mainCount}>
              <Box
                sx={{
                  "& > legend": { mt: 2 },
                  "& .MuiRating-icon": {
                    fontSize: "86px", // Adjust the font-size to set the custom size
                  },
                  "@media (max-width: 768px)": { // Adjust the screen width as needed
                    "& .MuiRating-icon": {
                      fontSize: "48px", // Custom font size for mobile screens
                    },
                  },
                }}
              >
                {/* <Typography component="legend">Read only</Typography> */}
                <Rating name="read-only" value={starValue} readOnly max={1} />
              </Box>
              <h3>4.5</h3>
            </div>
            <p className={styles.totalcount}>200 reviews</p>
          
              <p className={styles.reviewFheading}>Review by feature</p>
              {reviewbyfeature?.map((v) => {
                return (
                  <div className={styles.reviewbyfeature} key={v?.id}>
                    <p>{v?.title}</p>
                    <Rating name="read-only" precision={0.5} value={v?.values} readOnly />
                    <p  className={styles.rbfReating}style={{textAlign:"center"}}>{v?.values}</p>
                  </div>
                );
              })}
          </div>

          <div className={styles.reviewListing}>
            {
              reviewsData?.map((v, i) =>{
                return(
                  <div style={{margin:"20px 0 "}}>
                    <div className={styles.reviewProfile}>
                      <div className={styles.rPimg}>
                        <img src={v?.img == "" ? "xyz" : v?.img} alt={v?.title} />
                      </div>
                      <h4>{v?.title}</h4>
                    </div>
                    <Rating name="read-only" precision={0.5} value={v?.rating} readOnly />
                    <p style={{marginBottom:"10px"}}>{v?.review}</p>
                    <span className={styles.reviwdates}>{v?.date}</span>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
      <div style={{paddingBottom:" 20px"}}>
      <Membership membership={membership}/>
      </div>
    </>
  );
}

export default ProductDetails;

const colordata = [
  {
    id: 1,
    color: "red",
  },
  {
    id: 2,
    color: "yellow",
  },
  {
    id: 3,
    color: "green",
  },
  {
    id: 4,
    color: "blue",
  },
];

const imgData = [
  {
    id: 1,
    Img: droneImg1,
  },
  {
    id: 2,
    Img: droneImg2,
  },
  {
    id: 3,
    Img: droneImg3,
  },
  {
    id: 4,
    Img: droneImg4,
  },
];

const accessData = [
  {
    id: 1,
    Img: accessoriesimg1,
    title: "accessores-1",
  },
  {
    id: 2,
    Img: accessoriesimg2,
    title: "accessores-2",
  },
  {
    id: 3,
    Img: accessoriesimg3,
    title: "accessores-3",
  },
  {
    id: 4,
    Img: accessoriesimg1,
    title: "accessores-4",
  },
  {
    id: 5,
    Img: accessoriesimg2,
    title: "accessores-5",
  },
  {
    id: 6,
    Img: accessoriesimg3,
    title: "accessores-6",
  },
];

const Accordiondata = [
  {
    id: 1,
    title: "Question 01",
    para: "Supported by revolutionary drone technology, artificial intelligence, and a versatile number of softwares, our users are guaranteed to procure precise and secure data even from the most vulnerable locations. We continue to achieve centimeter level accuracy successfully through cutting-edge technology and equipment. Supported by revolutionary drone technology, artificial intelligence, and a versatile number of softwares, our users are guaranteed to procure precise and secure data even from the most vulnerable locations. We continue to achieve centimeter level accuracy successfully through cutting-edge technology and equipment.",
  },
  {
    id: 2,
    title: "Question 02",
    para: "Supported by revolutionary drone technology, artificial intelligence, and a versatile number of softwares, our users are guaranteed to procure precise and secure data even from the most vulnerable locations. We continue to achieve centimeter level accuracy successfully through cutting-edge technology and equipment. Supported by revolutionary drone technology, artificial intelligence, and a versatile number of softwares, our users are guaranteed to procure precise and secure data even from the most vulnerable locations. We continue to achieve centimeter level accuracy successfully through cutting-edge technology and equipment.",
  },
  {
    id: 3,
    title: "Question 03",
    para: "Supported by revolutionary drone technology, artificial intelligence, and a versatile number of softwares, our users are guaranteed to procure precise and secure data even from the most vulnerable locations. We continue to achieve centimeter level accuracy successfully through cutting-edge technology and equipment. Supported by revolutionary drone technology, artificial intelligence, and a versatile number of softwares, our users are guaranteed to procure precise and secure data even from the most vulnerable locations. We continue to achieve centimeter level accuracy successfully through cutting-edge technology and equipment.",
  },
];

const reviewbyfeature = [
  {
    id: 1,
    title: "Value for money",
    values: 4,
  },
  {
    id: 2,
    title: "Light Weight",
    values: 3.5,
  },
  {
    id: 3,
    title: "Range",
    values: 4.5,
  },
];

const reviewsData = [
  {
    id:1,
    img:"",
    title:"abc pqr",
    rating:3.5,
    review:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknow.",
    date:"4th September 2021",
  },
  {
    id:2,
    img:reviewImg,
    title:"abc pqr",
    rating:4.5,
    review:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknow.",
    date:"4th September 2021",
  },
  {
    id:3,
    img:reviewImg,
    title:"abc pqr",
    rating:5,
    review:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknow.",
    date:"4th September 2021",
  },
]

const membership ={
 id:1,
 title:"Membership & Services Plans",
 redirection_text:"Membership & Services Plans",
 description:"View our membership and services plan to keep your product up-to date",
 banner:"http://143.110.253.237:8001/files/Rectangle_31002_2_4DkVwlV.png"
}