import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import bannerdesc from "../../assets/CouresDetile/CouresDetailBanner.png";
import lanbanner from "../../assets/CouresDetile/langDetils.png";
import coursetype1 from "../../assets/CouresDetile/course_type.png"
import coursL from "../../assets/CouresDetile/location.png"
import coursS from "../../assets/CouresDetile/coursStated.png"
import coursD from "../../assets/CouresDetile/courseduration.png"
import TarinerCards from "../../components/CouresDetailsComponente/TrainerCard/TarinerCards";
import CourseAccordion from "../../components/CouresDetailsComponente/NormalDropDown/CourseAccordion";
import FrequentlyAsked from "../../components/CouresDetailsComponente/Frequentlyasked/FrequentlyAsked";
import { api_call } from "../../Utils/Network";
import { useParams } from "react-router-dom";
import offerTag from "../../assets/CouresDetile/offertag.png"
import { Button, Grow } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import CloseIcon from "@material-ui/icons/Close";
// import Grow from "@material-ui/core/Grow";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
// import { Snackbar, Switch } from "@material-ui/core";
import Snackbar from '@mui/material/Snackbar';
import { InputAdornment } from "@mui/material";
// import { TextField } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  // alignItems:"center"
};

function CouresDetailsPage() {
  const [courseDetails, setCoursedetails] = useState();
  const [finelOpne, setFinelOpne] = useState(false);
  let {ids} = useParams();
  const [msg, setMsg] = useState("");
  const viewVideoClose = () => setFinelOpne(false);
  const [orderData, setOrderData] = useState({
    name: "",
    phone: "",
    email: "",
    course: [],
  });
  var temp = {};

  const [productError, setProductError] = useState({ name: "" });

useEffect(() =>{
  getCourserDetails()
},[ids])

  const getCourserDetails = () =>{
    api_call
    .get(`course/${ids}`)
    .then((res) =>{
      if(res?.status == 200 || res?.status == 201 || res?.status == 204){
        console.log(res?.data);
        setCoursedetails(res?.data)
      }
    })
    .catch((err) =>{
      console.log(err);
    })
  }

  console.log(courseDetails?.locations ,"setCoursedetails ");

  const handleProduct = (e) => {
    const { name, value } = e.target;
    setOrderData({ ...orderData, [name]: value });
    setProductError({ ...productError, [name]: "" });
  };

  const handleChangeChips = (e, chipType) => {
    if (chipType === "product") {
      setOrderData({
        ...orderData,
        kit_id: typeof e === "string" ? e.split(",") : e,
      });
    }
  };


  const productvalidate = () => {
    let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let returnValue = true;
    temp.name = orderData.name === "" ? "Please enter Your Name" : "";
    temp.phone = num.test(orderData.phone) ? "" : "Please Enter valid number";
    temp.email = orderData.email.match(validEmail)
      ? ""
      : "Please Enter valid email";
    // temp.c_message = userData.c_message === "" ? "Please Enter valid Message" : "";
    // temp.c_school = userData.c_school === "" ? "Please Enter School Name" : "";
    // temp.kit_id = orderData.kit_id != 0 ? "" : "Please Choose atleast one product to order";

    setProductError({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      console.log(temp);
      returnValue = false;
    }
    return returnValue;
  };

  const placeOrder = () => {
    if (!productvalidate()) return null;
    // const productIds = orderData?.kit_id?.map((kit_id) => kit_id?.id);
    let data = {
      ...orderData,
      name: orderData?.name,
      email: orderData?.email,
      mobile: orderData?.phone,
      course: +ids,
      // message:"test"
    };
    console.log(data, "neworderproduct");
    api_call
      .post(`course/enquiry/`, data)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          console.log("apihit succefull");
          setMsg("Thank You! Your submission has been sent.");
          setOrderData({
            name: "",
            phone: "",
            email: "",
            course: [],
          });
          document.getElementById("outlined-name-order").value = "";
          // document.getElementById("outlined-email").value = "";
          document.getElementById("outlined-phone-order").value = "";
          document.getElementById("outlined-email-order").value = "";
          setFinelOpne(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const vieworderboxOpne = (video) => {
    // setSelectedVideo(video);
    setFinelOpne(true);
  };

  return (
    <>
      <section className={`${styles.BannerConatiner} `}>
        <img className={styles.desktopImg} src={courseDetails?.banner} alt="banner" />
        <div className={`${styles.textContainer} `}>
          <h1>{courseDetails?.name}</h1>
          <div className={styles.desc}  dangerouslySetInnerHTML={{__html : courseDetails?.descriptions}}></div>
          <div>
            <img src={lanbanner} /> <span>{courseDetails?.languages}</span>
          </div>
          <div>
            <span>{courseDetails?.modes}</span>
          </div>
        </div>
      </section>
      <section className={`${styles.overallDetails}`}>
        <div className={styles.leftsideDetails}>
          <div className={styles.youwillLearn}>
            <h2>What you'll learn</h2>

            <ul className={styles.listofTopics}>
              {/* <li>Improve flying skills in order to not rely on automation</li> */}
              {
                courseDetails?.you_will_learn?.map((v, i) => {

                  return(
                    <li key={i}>{v}</li>
                  )
                } )
              }
            </ul>
          </div>
          <div className={styles.aboutCourse}>
            <h2>About Course</h2>
            <div className={styles.desc2}  dangerouslySetInnerHTML={{__html : courseDetails?.descriptions}}></div>
          </div>

          <div>
            <h2>Course Details</h2>

            <div className={styles.courDetails}>
                <div className={styles.innerBox}>
                    <div>
                        <img src={coursetype1}/>
                    </div>
                    <div>
                        <h4>Course Type</h4>
                        <h5>{courseDetails?.type?.title}</h5>
                    </div>
                </div>
                <div className={styles.innerBox}>
                    <div>
                        <img src={coursL}/>
                    </div>
                    <div>
                        <h4>Location</h4>
                        <h5>{courseDetails?.locations}</h5>
                    </div>
                </div>
                <div className={styles.innerBox}>
                    <div>
                        <img src={coursS}/>
                    </div>
                    <div>
                        <h4>Course Start Date</h4>
                        <h5>{courseDetails?.startdate}</h5>
                    </div>
                </div>
                <div className={styles.innerBox}>
                    <div>
                        <img src={coursD}/>
                    </div>
                    <div>
                        <h4>Course Duration</h4>
                        <h5>{courseDetails?.duration}</h5>
                    </div>
                </div>
            </div>
          </div>
          <div className={styles.CourseReq}>
            <h2>Course Eligibility/Requirements</h2>
            <ol>
              {/* {
                courseDetails?.eligibility?.map((v,i) => {
                  return(
                    <li key={i}>{v}</li>
                  )
                })
              } */}
                {/* <li>Age - 18 to 65 years</li> */}
            </ol>
          </div>
          <div >
            <h2>Course Trainer</h2>

            <TarinerCards  data={courseDetails?.instructors}/>
          </div>
          <div className={styles.coursedetailsAccordion}>
            <CourseAccordion  data={courseDetails?.course_module}/>
          </div>

          <div>
            <h2 style={{color:"#216FB9"}}> Frequently asked questions</h2>
          </div>
            <FrequentlyAsked data={courseDetails?.frequently_asked_questions}/>
        </div>

        {/* Price Box start */}
        <div className={styles.rightsidePriceBox}>
          <div className={styles.courseFeebox}>
            <div className={styles.offerStatus}>
              <span>Offer Closing soon</span>
            </div>
            <h3 className={styles.courseTitle}>Course Fee</h3>
            <div className={styles.pricingcontainer}>
              <h4>₹ {courseDetails?.lowest_mrp_price}</h4>
              <h2> ₹ {courseDetails?.lowest_price}</h2>
              <div className={styles.extracut}>
                <span>60% Off</span>
                <span> + 18% GST</span>
              </div>
            </div>
            {/* <div className={styles.availableOffers}>
              <h3>Available offers</h3>
              <p>
                <img src={offerTag} /> <span>Bank Offer </span>10% Instant Discount on ICICI Credit Card, upto ₹100.
              </p>
            </div> */}
            <div className={styles.btnContainer}>
              <Button className={styles.enrollBtn} onClick={vieworderboxOpne} variant="contained">Enroll for this Course</Button>
            </div>
          </div>
        </div>
        <div className={styles.pricePopup}>
          <div className={styles.priceleftnew}>
            <h2> ₹ {courseDetails?.lowest_price}</h2>
          </div>
          <div className={styles.btnContainer}>
              <Button className={styles.enrollBtn} onClick={vieworderboxOpne} variant="contained">Book now</Button>
            </div>
      </div>
      </section>
      
      {msg && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setMsg("")}
          autoHideDuration={3000}
          message={msg}
          TransitionComponent={Grow}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width:"100%"
          }}
        >
          {/* <CheckIcon className={styles.formsubmited} /> */}
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">{msg}</Alert>
        </Snackbar>
      )}

      <Modal
        open={finelOpne}
        onClose={viewVideoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleTwo} className={styles.boxForMobile}>
          <div
            className={styles.closebtn}
            style={{ textAlign: "right", cursor: "pointer" }}
          >
            <CloseIcon onClick={viewVideoClose} />
          </div>
          {/* <div>{selectedVideo?.id}</div> */}
          <div className={styles.fromContainer}>
            <h4 className={styles.sectionHeading}>{courseDetails?.name}</h4>

            <div className={styles.textfieldContainer}>
              <div className={styles.innerbox}>
                <TextField
                  id="outlined-name-order"
                  variant="outlined"
                  label="Enter name"
                  placeholder="Enter name"
                  name="name"
                  type="text"
                  className={styles.fullwidth}
                  onChange={handleProduct}
                  // required
                />
                <p style={{ margin: "0", color: "red" }}>
                  {productError.name}
                </p>
              </div>
              <div className={styles.innerbox}>
                <TextField
                  id="outlined-phone-order"
                  variant="outlined"
                  label="Mobile Number"
                  // placeholder="Mobile Number"
                  type="number"
                  name="phone"
                  className={styles.fullwidth}
                  style={{ textAlign: "left" }}
                  onChange={handleProduct}
                  // maxRows="10"
                  InputProps={{
                    startAdornment: (
                      // <input type="text" value="+91 |" readOnly style={{border:"none", background:'transparent'}} />
                      <InputAdornment position="start">+91 |</InputAdornment>
                    ),
                  }}
                  // required
                />
                <p style={{ margin: "0", color: "red" }}>{productError.phone}</p>
              </div>

              <div className={styles.innerbox}>
                <TextField
                  id="outlined-email-order"
                  variant="outlined"
                  label="Enter email"
                  placeholder="Enter email"
                  type="text"
                  className={styles.fullwidth}
                  name="email"
                  onChange={handleProduct}
                  autoComplete="false"
                  // required
                />
                <p style={{ margin: "0", color: "red" }}>{productError.email}</p>
              </div>
              {/* <div className={styles.innerbox}>
                <CustomMultiSelectChips
                  listArray={buyKit}
                  // id=""
                  label="product"
                  name="product"
                  onChange={(e) => handleChangeChips(e, "product")}
                  handleDelete={(id) => handleDeleteChips(id, "product")}
                  value={orderData?.kit_id}
                  size={"small"}
                  className={styles.fullwidth}
                  // error={productError}
                />
                <p style={{ margin: "0", color: "red" }}>{productError.kit_id}</p>
              </div> */}
            </div>

            <Button
              variant="contained"
              className={styles.fromsubmitbtn}
              onClick={placeOrder}
            >
              Enroll
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default CouresDetailsPage;


