import React from 'react'
import styles from './CartPage.module.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Total from '../../components/Total/Total';
import Product from '../../components/Product/Product';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
function CartPage() {
  return (
    <div className={styles.container}>
      <div className={styles.BackNavigate}>

        <Breadcrumbs />
      </div>

      <h2 className={styles.head}>Your Cart</h2>


      <section className={styles.productPrice}>
        <Product />

        <Total />
      </section>












    </div >
  )
}

export default CartPage