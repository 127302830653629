import React from 'react'
import styles from './DroneCard.module.css'
function DroneCard({ data }) {
    return (
        <div className={styles.container}>

            <div className={styles.img}>
                <img src={data.img}></img>
            </div>

            <div className={styles.desc}>
                <h3 >{data.head}</h3>
                <p>{data.name}</p>
            </div>
        </div>
    )
}

export default DroneCard