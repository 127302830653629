import React, { useEffect, useState } from 'react'

import {
  GoogleMap,
  MarkerF,
  InfoWindowF,
  Circle,
  MarkerClusterer,
} from '@react-google-maps/api'
// import { GoogleMap, Marker, InfoWindowF,  Circle, withGoogleMap } from 'react-google-maps'

const MapWithMarkers = ({ selectedLatLng, containerElement, mapElement }) => {
  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  }
  console.log(selectedLatLng, 'SSSSSSSSSssq')
  const [selectedMarker, setSelectedMarker] = useState(null)
  // const radius = 5000
  // const radiusOptions = [5000, 10000, 50000];
  const COORDINATE_PRECISION = 6

  const radiusOptions = [
    {
      id: 1,
      radius: 5000,
      color: 'red',
    },
    {
      id: 2,
      radius: 10000,
      color: '#4ecb71',
    },
    {
      id: 3,
      radius: 50000,
      color: '#ffcd29',
    },
  ]
  const [center, setCenter] = useState(null)

  const markers = [
    { lat: 37.7749, lng: -122.4194 }, // Marker 1 coordinates
    { lat: 37.3382, lng: -121.8863 }, // Marker 2 coordinates
    { lat: 34.0522, lng: -118.2437 },
    { lat: 28.7040592, lng: 77.10249019999999 }, // Marker 3 coordinates
    // Add more markers as needed
  ]

  useEffect(() => {
    setCenter(selectedLatLng)
    setSelectedMarker(null)
  }, [selectedLatLng])
  console.log('PrintCenter', center)

  function onMarkerClick(marker) {
    setSelectedMarker(marker)
    console.log(selectedMarker, 'markerclicked')
  }
  //  console.log("markerclicked", selectedMarker);
  const onMapClick = () => {
    setSelectedMarker(null)
    console.log('mapclicked')
  }
  return (
    <>
      <GoogleMap
        // defaultZoom={10}
        zoom={10}
        // defaultCenter={center}
        mapContainerStyle={mapContainerStyle}
        center={center}
        onClick={onMapClick} // Handle map click to close InfoWindowF
        // onLoad={(map) => handleMapLoad(map)}
        // onTilesLoaded={handleMapLoad}
        // clickableIcons={true}
        defaultOptions={{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
        // onLoad={(map) => handleMapLoad(map)}
      >
        <MarkerClusterer>
          {(clusterer) => (
            <>
              {markers.map((marker, index) => {
                return (
                  <>
                    <MarkerF
                      key={index}
                      position={marker}
                      onClick={() => onMarkerClick(marker)}
                      clusterer={clusterer}
                    />
                    {selectedMarker && (
                      <InfoWindowF
                        position={selectedMarker}
                        onCloseClick={() => setSelectedMarker(null)}
                      >
                        <div
                          style={
                            {
                              // width: '400px',
                              // height: '400px',
                              // backgroundColor: 'red',
                            }
                          }
                        >
                          <h3>Marker Details</h3>
                          <p>Latitude: {selectedMarker.lat}</p>
                          <p>Longitude: {selectedMarker.lng}</p>
                        </div>
                      </InfoWindowF>
                    )}
                  </>
                );
              })}
            </>
          )}
        </MarkerClusterer>

        {center &&
          radiusOptions.map((info) => {
            console.log(center, 'needtoupdate')
            return (
              <>
                <Circle
                  key={info.id}
                  center={center}
                  radius={info.radius}
                  visible={true}
                  clickableIcons={true}
                  options={{
                    strokeColor: info.color, // Red outline
                    strokeOpacity: 0.8,
                    strokeWeight: 4,
                    fillColor: '#FF0000', // Red fill color
                    fillOpacity: 0,
                  }}
                />
              </>
            )
          })}
      </GoogleMap>
    </>
  )
}

export default MapWithMarkers
