import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import styles from "./CustomDrawer.module.css"
import { useTheme } from '@mui/material/styles';

const Anchor = 'top' | 'left' | 'bottom' | 'right';

// interface Props {
//     side?: Anchor;
//     children: React.ReactNode,
//     open: boolean,
//     callBackFunction: any,
//     status?: any
// }

export default function CustomDrawer({ side = "right", children, open = false, callBackFunction, status= false }) {
    const theme = useTheme();

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    React.useEffect(() => {
        setState({ ...state, [side]: open })
    }, [open])

    const toggleDrawer =
        (anchor, open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event.key === 'Tab') ||
                        (event.key === 'Shift'))
                ) {
                    return;
                }
                setState({ ...state, [anchor]: open });
                callBackFunction(open)
            };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? '100%' : '90%', padding:{ lg: "15px",md:"15px", sm: "0px"  }, margin: "0 auto" }}
            role="presentation"
            className = {styles?.mobileRes}
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            {children}
        </Box>
    );

    return (
        <div>
            {([side]).map((anchor) => (
                <React.Fragment key={anchor} sx={{margin: "0 auto"}}  >
                    {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
                    <Drawer 
        // style={{ zIndex: "1300" }}
                       PaperProps={{
                        sx: {
                          width: '25%',
                          [theme.breakpoints.down('md')]: {
                            width: '100%',
                            // zIndex: "9999"
                          },
                          [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            // zIndex: "9999"
                            // margin: "0 auto"
                          },
                        },
                      }}
                        anchor={anchor}
                        open={state[anchor]}
                        // onClose={toggleDrawer(anchor, false)}
                        onClose={status === false? toggleDrawer(anchor, false) : null}

                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}

