import React from 'react'
import styles from './SpecificationCards.module.css'
import SpecificationIcon from '../../assets/store/SpecificationIcon.png'
function SpecificationCards({ data }) {
    return (
        <div className={styles.container}>

            <p>₹ 4,56,892</p>
            <span>Cost</span>

            <div className={styles.img}>
                <img src={SpecificationIcon}></img>
            </div>
        </div>
    )
}

export default SpecificationCards