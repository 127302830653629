import React from 'react'
import styles from './index.module.css'
import JobRequest from '../../JobPageComponent/JobRequest/JobRequest'
import UpcomingJobs from '../../JobPageComponent/UpcomingJob/UpcomingJobs'
import CompletedJobTable from '../../JobPageComponent/CompletedJobTable/CompletedJobTable'

function JobComponent() {

  const jobDetils =[
    {
      id:1,
      jobDone:121,
      upcoming: 5,
      earning:"₹24000",
    }
  ]
  return (
    <div className={styles.JobListingContainer}>
      <div className={styles.topDetailsBar}>
          
          <div className={styles.jobBox}>
            <p>Jobs Completed</p>
            <h3>{jobDetils[0].jobDone}</h3>
          </div>
          <div className={styles.jobBox}>
            <p>Upcoming Jobs</p>
            <h3>{jobDetils[0].upcoming}</h3>
          </div>
          <div className={styles.jobBox}>
            <p>Earning</p>
            <h3>{jobDetils[0].earning}</h3>
          </div>
        </div>
      <div className={styles.centerJoblisting}>
        <div className={styles.jobRequest}><JobRequest /></div>
        <div className={styles.upcomingJobs}><UpcomingJobs /></div>
      </div>
      <div className={styles.completedJobTable}>< CompletedJobTable/></div>
    </div>
  )
}

export default JobComponent