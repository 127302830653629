import React,{useState, useEffect} from 'react'
import SeconderyBannerImage from '../../assets/images/secondBanner.png'
import styles from './index.module.css'
import { api_call } from '../../Utils/Network';

const MembershipServices = ({membership}) => {
  const [loading, setLoading] = useState(false);
  // const [membership, setMembership] = useState({})
  console.log(membership, "sdbaygakjdbahkdadjbad");
  // const fetchData = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await api_call.get("products/v1/home_screen");

  //     const homePageData = response?.data?.data;
  //     // console.log(homePageData, 'homePageData');
  //     if (homePageData !== undefined || homePageData !== null) {
        
  //       setMembership(homePageData[3]?.data[0])
        
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);
  console.log(membership, "hdajfhgsdkfjhs");
  return (
    <>
      <div className={styles.membershipServices}>
        <div className={styles.background} style={{ backgroundImage: membership ? `url(${membership?.banner})` : "none" }}>
          <h3>{membership?.title?.replace('&amp;', '&')}</h3>
          <p>
            {membership?.description}
          </p>
          <button className={styles.acceptButton}>{membership?.redirection_text}</button>
        </div>
      </div>
    </>
  )
}

export default MembershipServices
