import React, { useState } from "react";
import styles from "./courseacco.module.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  // flexDirection: 'row-reverse',
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CourseAccordion({ data }) {
  const [expanded, setExpanded] = useState(0);

  const handleChange = (i) => (event, newExpanded) => {
    setExpanded(newExpanded ? i : false);
  };

  console.log(data, "CourseAccordion");

  return (
    <>
     
        {data?.map((v, i) => {
          return (
            <Accordion
              expanded={expanded === i}
              onChange={handleChange(i)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography> <div dangerouslySetInnerHTML={{__html : v?.course_module_title}}></div></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <div dangerouslySetInnerHTML={{__html : v?.course_module_description}}></div>
                  {/* {v?.course_module_description} */}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}

     
    </>
  );
}

export default CourseAccordion;
