import React from 'react'
import styles from './ProductSpecification.module.css'
import SpecificationCards from '../SpecificationCards/SpecificationCards'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-prev-arrow-banner"
      className={`${styles.customarrow} ${styles.customprevarrow}`}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
    </button>
  );
}


function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-next-arrow-banner"
      className={`${styles.customarrow} ${styles.customnextarrow}`}
      onClick={onClick}
    >
      <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
    </button>
  );
}

var settings = {
  className: styles.slickstyles,
  dots: true,
  // autoplay: true,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  adaptiveHeight: false,
  arrows: false,
  // fade: true,
  autoplaySpeed: 2000,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
  ],
};
function ProductSpecification() {
  return (<>
    <div className={styles.container}>
      <h2 className={styles.heading}>Product Specification</h2>
      <p className={styles.desc}>AG 365 stands out in the industry with its versatile multi-utility platform, offering users remarkable flexibility and adaptability in their operations.</p>
      <div className={styles.cardCon}>
        {speciality.map((item) => {
          return (
            <SpecificationCards />
          )
        })}








      </div>

      <div className={styles.mobile}>

        <Slider {...settings}>

          {speciality.map((item) => {
            return (
              <SpecificationCards />
            )
          })}


        </Slider>







      </div>





    </div>
  </>)
}

export default ProductSpecification
const speciality = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  },
  {
    id: 5
  },
  {
    id: 6
  },
  {
    id: 7
  },
  {
    id: 8
  },



]