import React, { useEffect, useState } from 'react'
import styles from "./index.module.css"
import DroneasService from '../../components/DroneasService/DroneasService'
import daas1 from "../../assets/NewHomePage/daas1.png"
import daas2 from "../../assets/NewHomePage/daas2.png"
import daas3 from "../../assets/NewHomePage/daas3.png"
import { api_call } from '../../Utils/Network'

function ServiceListingPage() {
  const [servicedata, setServiceData] = useState([]);

  useEffect(() =>{
    getServiceListing();
  },[])

  const getServiceListing = () => {
    api_call
    .get(`drone_service`)
    .then((res) => {
      if(res?.status == 200 || res?.status == 201 || res?.status == 204){
        setServiceData(res?.data?.data)
        console.log(res?.data?.data ,"getServiceListing");

      }
    })
    .catch((err) =>{
      console.log(err);
    })
  }


  return (
    <div className={styles.ServiceListingContainer}>
      {
        servicedata?.map((info,i) =>{
            return(
                <>
                    <DroneasService  data={info}/>
                </>
            )
        })
      }
    </div>
  )
}

export default ServiceListingPage



const apidata =
    {
        page: 1,
        next_page : null,
        next_page_link: null,
        previous_page: null,
        previous_page_link: null,
        count : 3,
        max_pages: 1,
        total_count: 3,
        data: [
          {
            id: 1,
            img: daas1,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 4.3,
            dronevalue: " ₹200"
          },
          {
            id: 2,
            img: daas2,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 4.3,
            dronevalue: " ₹200"
          },
        
          {
            id: 3,
            img: daas3,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 2.3,
            dronevalue: " ₹200"
          },
          {
            id: 1,
            img: daas1,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 4.3,
            dronevalue: " ₹200"
          },
          {
            id: 2,
            img: daas2,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 4.3,
            dronevalue: " ₹200"
          },
        
          {
            id: 3,
            img: daas3,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 2.3,
            dronevalue: " ₹200"
          },
          {
            id: 1,
            img: daas1,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 4.3,
            dronevalue: " ₹200"
          },
          {
            id: 2,
            img: daas2,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 4.3,
            dronevalue: " ₹200"
          },
        
          {
            id: 3,
            img: daas3,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 2.3,
            dronevalue: " ₹200"
          },
          {
            id: 1,
            img: daas1,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 4.3,
            dronevalue: " ₹200"
          },
          {
            id: 2,
            img: daas2,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 4.3,
            dronevalue: " ₹200"
          },
        
          {
            id: 3,
            img: daas3,
            title: "Data - Driven Precision farming",
            serviceType: 'Agrispray',
            decs: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the...",
            rating: 2.3,
            dronevalue: " ₹200"
          },
        ]
    }

