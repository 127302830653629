import React from 'react'
import styles from "./index.module.css"

function OurDrone({ data }) {
    return (
        <>
            {/* <div>OurDrone</div> */}
            <div className={styles.cardConatainer}>
                <img src={ data?.img} alt={data?.title} />

                <div className={styles.textContainer}>
                    <h4>{data?.title}</h4>
                    <p>{data?.desc}</p>
                </div>
            </div>

        </>
    )
}

export default OurDrone