import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './pages/Home/index'
import Contact from './pages/Contact/index'
import DAS from './pages/DroneAsAService/DroneAsService'
import PilotsDetails from './pages/PilotsDetails/pilotsDetails'
import QuotePage from './pages/QuotePage/QuotePage'
import Login from './Authentication/Login'
import AccountOverview from './pages/AccountOverview/AccountOverview'
// import PageTest from './pages/pagetest/PageTest'
import Mainroutes from './Approutes/Mainroutes'
import './App.css'
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  return (
    <div className="App">
      <Mainroutes />
    </div>
      
  )
}

export default App
