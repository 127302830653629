import React, { useState } from "react";
import styles from "./index.module.css";
import presentation from "../../assets/images/presentation.png";
import book from "../../assets/images/apple.png";
import score from "../../assets/images/score.png";
import groundTraining from "../../assets/images/GroundTraining.png";
import simulatorTraining from "../../assets/images/SimulatorTraining.png";
import UAStraining from "../../assets/images/UASTraining.png";
import TheoryClasses from "../../assets/images/TheoryClasses.png";
import LecturesCard from "../../components/LecturesCard/LecturesCard";
import lectOne from "../../assets/images/lec-1.png";
import lectTwo from "../../assets/images/lec-2.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupsIcon from "@mui/icons-material/Groups";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ExclusiveEbooks from "../../components/ExclusiveEbooks/ExclusiveEbooks";
import ebook from "../../assets/images/ebooks.png";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TrainingProgramCard from "../../components/TrainingProgram/TrainingProgramCard";
import place from "../../assets/images/locationred.png"
import date from "../../assets/images/event_blue.png"
import t1 from "../../assets/images/t1.png"
import t2 from "../../assets/images/t2.png"
import t3 from "../../assets/images/t3.png"
import Contact from "../../components/ContactForm/index"

function ExcellenceCentre() {
  const [likeValues, setLikeValues] = useState(false);
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const likebtnHandel = (index) => {
    setLikeValues((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  return (
    <>
      <section className={styles.bannerContainer}>
        <div className={styles.bannerText}>
          <p className={styles.pageTitle}>EXCELLENCE CENTER</p>
          <h1 className={styles.tagLine}>
            Best Pilot Training Programs, Courses in India
          </h1>
          <button className={styles.enrollBtn}>Enroll Now</button>
        </div>
      </section>
      <section className={styles.Container}>
        <h3 className={styles.containerHeading}>Excellence Centre</h3>
        <div className={styles.cardContainer}>
          {topCards?.map((info) => {
            return (
              <div className={styles.cards}>
                <div className={styles.imgContainer}>
                  <img src={info?.img} alt="" />
                </div>
                <h4 className={styles.title}>{info?.title}</h4>
              </div>
            );
          })}
        </div>
      </section>
      <section className={styles.Container}>
        <div className={styles.innerContainer}>
          <div className={styles.leftSide}>
            <h3 className={styles.heading}>
              About Our <span>Training</span> Program
            </h3>
            <p>
              Introducing our photography drones, the perfect blend of
              aesthetics, advanced AI-driven technology, and exceptional camera
              capabilities. Available in a range of captivating colors, our
              drones not only capture stunning aerial shots but also elevate
              your visual experience. Powered by cutting-edge AI technology,
              these drones effortlessly analyze the environment, adjust
              settings, and deliver remarkable clarity and precision in every
              image
            </p>
            <p>
              Introducing our photography drones, the perfect blend of
              aesthetics, advanced AI-driven technology, and exceptional camera
              capabilities. Available in a range of captivating colors, our
              drones not only capture stunning aerial shots but also elevate
              your visual experience. Powered by cutting-edge AI technology,
              these drones effortlessly analyze the environment, adjust
              settings, and deliver remarkable clarity and precision in every
              image
            </p>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.abCardContainer}>
              {aboutImg?.map((info) => {
                return (
                  <div className={styles.abcards}>
                    <div className={styles.abimgContainer}>
                      <img src={info?.img} alt="" />
                    </div>
                    <h4 className={styles.abtitle}>{info?.title}</h4>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className={styles.Container} style={{ margin: "10px" }}>
        <h3 className={styles.containerHeading}>Attend the Live Lectures</h3>
        <div className={styles.LecturesCardContainer}>
          {/* <div className={styles.LecturesCardContainer}> */}
          <MultiCarousel
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                partialVisibilityGutter: 40,
                slidesToSlide: 3,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
                slidesToSlide: 2,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1,
              },
            }}
            itemClass={styles.customCarouselItem}
          >
            {lecturesData.map((info, index) => (
              <LecturesCard
                key={info.id}
                info={info}
                index={index}
                likeValues={likeValues}
                likebtnHandel={likebtnHandel}
              />
            ))}
          </MultiCarousel>
          {/* </div> */}
        </div>
      </section>
      <section className={styles.Container}>
        <ExclusiveEbooks eBooks={eBooks} />
      </section>
      <section className={styles.Container}>
        <h3 className={styles.containerHeading}>Training Program </h3>
        <MultiCarousel
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                partialVisibilityGutter: 40,
                slidesToSlide: 1,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
                slidesToSlide: 2,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1,
              },
            }}
            itemClass={styles.customCarouselItem}
          >
            {
          training?.map((info,index) => {
            return(
              <TrainingProgramCard 
              key={info.id}
                info={info}
                index={index}
              likeValues={likeValues}
              likebtnHandel={likebtnHandel}/>
            )
          })
        }
          </MultiCarousel>
        
        
      </section>
      <section className={styles.Container} style={{ margin: "10px" }}>
        <h3 className={styles.containerHeading}>Courses we offer</h3>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              centered
            >
              <Tab label="Item One" value="1" />
              <Tab label="Item Two" value="2" />
              {/* <Tab label="Item Three" value="3" /> */}
            </TabList>
            {/* </Box> */}
            <TabPanel value="1" sx={{ padding: "0" }}>
              {" "}
              <MultiCarousel
                responsive={{
                  desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                    partialVisibilityGutter: 40,
                    slidesToSlide: 3,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2,
                    slidesToSlide: 2,
                  },
                  mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                    slidesToSlide: 1,
                  },
                }}
                itemClass={styles.customCarouselItem}
              >
                {lecturesOnline.map((info, index) => (
                  <LecturesCard
                    key={info.id}
                    info={info}
                    index={index}
                    likeValues={likeValues}
                    likebtnHandel={likebtnHandel}
                  />
                ))}
              </MultiCarousel>
            </TabPanel>
            <TabPanel value="2">
              {" "}
              <MultiCarousel
                responsive={{
                  desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                    partialVisibilityGutter: 40,
                    slidesToSlide: 3,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2,
                    slidesToSlide: 2,
                  },
                  mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                    slidesToSlide: 1,
                  },
                }}
                itemClass={styles.customCarouselItem}
              >
                {lecturesOffline.map((info, index) => (
                  <LecturesCard
                    key={info.id}
                    info={info}
                    index={index}
                    likeValues={likeValues}
                    likebtnHandel={likebtnHandel}
                  />
                ))}
              </MultiCarousel>
            </TabPanel>
            {/* <TabPanel value="3">Item Three</TabPanel> */}
          </TabContext>
        </Box>
      </section>
      <section className={styles.researchContainer}>
        <div>
        <h4 className={styles.heading}>RESEARCH FACILITY</h4>
        <p className={styles.tagLine}>Let your students indulge AI driven Aerial Technology</p>
        </div>
      </section>
      <Contact />
    </>
  );
}

export default ExcellenceCentre;

const topCards = [
  {
    id: 1,
    img: presentation,
    title: "Attend",
  },
  {
    id: 2,
    img: book,
    title: "Learn",
  },
  {
    id: 3,
    img: score,
    title: "Assess yourself",
  },
];

const aboutImg = [
  {
    id: 1,
    img: groundTraining,
    title: "Ground Training",
  },
  {
    id: 2,
    img: simulatorTraining,
    title: "Simulator Training",
  },
  {
    id: 3,
    img: UAStraining,
    title: "UAS Training",
  },
  {
    id: 4,
    img: TheoryClasses,
    title: "Theory Classes",
  },
];

const lecturesData = [
  {
    id: 1,
    lecImg: lectOne,
    lecTitel: "aerial intelligence and its evolution ",
    techarName: "Mr. Amit Shah",
    lecdate: "3 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 1,
    clockIcon: <AccessTimeIcon />,
    seats: "70",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 2,
    lecImg: lectTwo,
    lecTitel: "aerial intelligence ",
    techarName: "Mr. Amit",
    lecdate: "5 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 5,
    clockIcon: <AccessTimeIcon />,
    seats: "80",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 3,
    lecImg: lectOne,
    lecTitel: "aerial evolution ",
    techarName: "Mr. Shah",
    lecdate: "3 May 20231",
    dateIcon: <CalendarMonthIcon />,
    hours: 2,
    clockIcon: <AccessTimeIcon />,
    seats: "100",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 4,
    lecImg: lectTwo,
    lecTitel: "aerial intelligence and its evolution ",
    techarName: "Mr. Amit Shah",
    lecdate: "3 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 1,
    clockIcon: <AccessTimeIcon />,
    seats: "70",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 5,
    lecImg: lectOne,
    lecTitel: "aerial intelligence ",
    techarName: "Mr. Amit",
    lecdate: "5 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 5,
    clockIcon: <AccessTimeIcon />,
    seats: "80",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 6,
    lecImg: lectTwo,
    lecTitel: "aerial evolution ",
    techarName: "Mr. Shah",
    lecdate: "3 May 20231",
    dateIcon: <CalendarMonthIcon />,
    hours: 2,
    clockIcon: <AccessTimeIcon />,
    seats: "100",
    seatsIcon: <GroupsIcon />,
  },
];

const eBooks = [
  {
    id: 1,
    title: "Exclusive e-books",
    subTitle: "On drones to enhance your learning journey.",
    desc: "Embark on a transformative journey into the world of drones! Enroll in our comprehensive drone courses and gain wings to your aspirations. As a special bonus, receive complimentary e-books on drones, empowering you with extensive knowledge and expertise. Don't miss this incredible offer, seize the opportunity to soar higher and reach new heights in your drone exploration",
    bookImg: ebook,
  },
];

const lecturesOnline = [
  {
    id: 1,
    lecImg: lectOne,
    lecTitel: "aerial intelligence and its evolution ",
    techarName: "Mr. Amit Shah",
    lecdate: "3 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 1,
    clockIcon: <AccessTimeIcon />,
    seats: "70",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 2,
    lecImg: lectTwo,
    lecTitel: "aerial intelligence ",
    techarName: "Mr. Amit",
    lecdate: "5 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 5,
    clockIcon: <AccessTimeIcon />,
    seats: "80",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 3,
    lecImg: "",
    lecTitel: "aerial evolution ",
    techarName: "Mr. Shah",
    lecdate: "3 May 20231",
    dateIcon: <CalendarMonthIcon />,
    hours: 2,
    clockIcon: <AccessTimeIcon />,
    seats: "100",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 4,
    lecImg: lectTwo,
    lecTitel: "aerial intelligence and its evolution ",
    techarName: "Mr. Amit Shah",
    lecdate: "3 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 1,
    clockIcon: <AccessTimeIcon />,
    seats: "70",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 5,
    lecImg: lectOne,
    lecTitel: "aerial intelligence ",
    techarName: "Mr. Amit",
    lecdate: "5 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 5,
    clockIcon: <AccessTimeIcon />,
    seats: "80",
    seatsIcon: <GroupsIcon />,
  },
];

const lecturesOffline = [
  {
    id: 1,
    lecImg: lectTwo,
    lecTitel: "aerial intelligence and its evolution ",
    techarName: "Mr. Amit Shah",
    lecdate: "3 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 1,
    clockIcon: <AccessTimeIcon />,
    seats: "70",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 2,
    lecImg: lectOne,
    lecTitel: "aerial intelligence ",
    techarName: "Mr. Amit",
    lecdate: "5 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 5,
    clockIcon: <AccessTimeIcon />,
    seats: "80",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 3,
    lecImg: lectTwo,
    lecTitel: "aerial evolution ",
    techarName: "Mr. Shah",
    lecdate: "3 May 20231",
    dateIcon: <CalendarMonthIcon />,
    hours: 2,
    clockIcon: <AccessTimeIcon />,
    seats: "100",
    seatsIcon: <GroupsIcon />,
  },
  {
    id: 4,
    lecImg: "",
    lecTitel: "aerial intelligence and its evolution ",
    techarName: "Mr. Amit Shah",
    lecdate: "3 May 2023",
    dateIcon: <CalendarMonthIcon />,
    hours: 1,
    clockIcon: <AccessTimeIcon />,
    seats: "70",
    seatsIcon: <GroupsIcon />,
  },
];

const training = [
  {
    id:1,
    trainingImg:t1,
    title:"Training Course 01",
    locationIcon:place,
    place:"Mumbai",
    eventIcon:date,
    dateinfo:"20 - 27 May 2023",
    trainingdec:"The best practices for capturing images of telecommunications equipment with a drone will be clear to you.",
  },
  {
    id:2,
    trainingImg:t2,
    title:"Training Course 02",
    locationIcon:place,
    place:"Pune",
    eventIcon:date,
    dateinfo:"27 - 29 May 2023",
    trainingdec:"The best practices for capturing images of telecommunications equipment with a drone will be clear to you.",
  },
  {
    id:3,
    trainingImg:t3,
    title:"Training Course 03",
    locationIcon:place,
    place:"Thane",
    eventIcon:date,
    dateinfo:"10 - 27 August 2023",
    trainingdec:"The best practices for capturing images of telecommunications equipment with a drone will be clear to you.",
  },
  {
    id:4,
    trainingImg:t2,
    title:"Training Course 04",
    locationIcon:place,
    place:"Thane",
    eventIcon:date,
    dateinfo:"20 - 27 May 2023",
    trainingdec:"The best practices for capturing images of telecommunications equipment with a drone will be clear to you.",
  },
  {
    id:5,
    trainingImg:t3,
    title:"Training Course 05",
    locationIcon:place,
    place:"Mumbai",
    eventIcon:date,
    dateinfo:"27 - 29 May 2023",
    trainingdec:"The best practices for capturing images of telecommunications equipment with a drone will be clear to you.",
  },
  {
    id:6,
    trainingImg:t1,
    title:"Training Course 06",
    locationIcon:place,
    place:"Pune",
    eventIcon:date,
    dateinfo:"10 - 27 August 2023",
    trainingdec:"The best practices for capturing images of telecommunications equipment with a drone will be clear to you.",
  },
]