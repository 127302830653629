import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import course1 from "../../assets/course Listing/course1.png"
import location from "../../assets/course Listing/location.png"
import language from "../../assets/course Listing/language.png"
import CouresCard from "../../components/CouresCard/CouresCard";
import { api_call } from "../../Utils/Network";

function CouresListingPage() {
  const [mode, setMode] = useState('');
  const [location, setLocation] = useState('');
  const [language, setLanguage] = useState('');
  const [coursLiting, setCourserListing] = useState([])
  const [filter, setFilter] = useState({
    // mode:"",
  })
  const [locationList, setLocationList] = useState([])
  const [languageList, setLanguageList] = useState([])

  const getLanguage = () => {
    api_call
      .get('/language')
      .then((res) => {
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          console.log(res?.data?.data);
          setLanguageList(res?.data)
        }
      })
      .catch((err) => {
        console.log(err);
      })
    console.log(locationList, "locationList")
  }
  useEffect(() => {
    getLanguage()
  }, [])







  const getLocations = () => {
    api_call
      .get('/location')
      .then((res) => {
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          console.log(res?.data?.data);
          setLocationList(res?.data)
        }
      })
      .catch((err) => {
        console.log(err);
      })
    console.log(locationList, "locationList")
  }
  useEffect(() => {
    getLocations()
  }, [])
  const handleChange = (e, key) => {
    // setAge(e.target.value);
    // debugger
    let value = e.target.value;
    // let obj = filter;
    setFilter(prevFilter => ({
      ...prevFilter,
      [key]: value
    }));

    if (key === 'mode') {
      setMode(value);
    } else if (key === 'location') {
      setLocation(value);
    } else if (key === 'language') {
      setLanguage(value);
    }
  };

  console.log(filter, "jdkhjwf87987");
  useEffect(() => {
    getCourserListing()
  }, [filter])

  const getCourserListing = (paramObj = { ...filter }) => {
    api_call
      .get(`course`, { params: { ...paramObj } })
      .then((res) => {
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          console.log(res?.data?.data);
          setCourserListing(res?.data?.data)
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className={`${styles.ListingPage}`}>
        <div className={`${styles.filtersection}`}>
          <div className={`${styles.filterdiv}`}>
            <h3>Filters</h3>
          </div>
          <div className={`${styles.filterdiv}`}>
            <h3>Mode</h3>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mode</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mode}
                label="Mode"
                onChange={(e) => handleChange(e, "mode")}
              >
                <MenuItem value={1}>Online</MenuItem>
                <MenuItem value={2}>Offline</MenuItem>
                <MenuItem value={3}>Hybrid</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={`${styles.filterdiv}`}>
            <h3>Location</h3>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Location</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={location}
                label="Location"
                onChange={(e) => handleChange(e, "location")}
              >
                {
                  locationList.map((item, idx) => {
                    return (
                      <MenuItem value={item.city}>{item.city}</MenuItem>

                    )
                  })
                }


              </Select>
            </FormControl>
          </div>
          <div className={`${styles.filterdiv}`}>
            <h3>Language</h3>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                label="Language"
                onChange={(e) => handleChange(e, "language")}
              >
                {languageList.map((item, id) => {
                  return (
                    <MenuItem value={item.id}>{item.title}</MenuItem>

                  )
                })}
                {/* <MenuItem value={1}>Hindi</MenuItem> */}
                {/* <MenuItem value={2}>English</MenuItem> */}

              </Select>
            </FormControl>
          </div>
        </div>
        <div className={`${styles.listingSection}`}>
          {
            coursLiting?.map((v, i) => {
              return (
                <CouresCard data={v} key={i} />
              )
            })
          }
        </div>
      </section>
    </>
  );
}

export default CouresListingPage;

const cardListing = [
  {
    id: 1,
    img: course1,
    title: "Drone Manufacturing",
    price: "₹ 2000",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    location: [
      {
        id: 1,
        place: "Mumbai",
      },
      {
        id: 1,
        place: "Gujrat",
      },
      {
        id: 3,
        place: "pune",
      }
    ],
    language: [
      {
        id: 1,
        lan: "English",
      },
      {
        id: 2,
        lan: "Hindi",
      }
    ],

    rating: 4.5,
    courseType: 2,

  },
]
