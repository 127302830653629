import React from 'react'
import styles from './Features.module.css'
import file from '../../assets/battery/file.png'

function Features() {
    return (
        <>
            <div className={styles.details}>
                <h2 className={styles.heading}>Key Feature</h2>


                <div>
                    <ul className={styles.desc}>
                        <li>2X the life cycle of Lithium Polymer Batteries.</li>
                    </ul>
                </div>

            </div>
            <div className={styles.details}>
                <h2 className={styles.heading}>Additional Details</h2>

                {
                    details.map((item) => {
                        return (


                            <div>
                                <ul className={styles.desc}>
                                    <li>{item.desc}</li>
                                </ul>
                            </div>
                        )
                    })
                }

            </div>
            <div className={styles.details}>
                <h2 className={styles.heading}>Documents</h2>
                {docs.map((item) => {
                    return (
                        <div className={styles.documents}>
                            <div className={styles.img}>
                                <img src={file}></img>
                            </div>



                            <div className={styles.downlod}>
                                <p className={styles.specification}>mPower 6S 25200 mAh Specification</p>
                                <p className={styles.btn}>Download</p>

                            </div>

                        </div>
                    )
                })}
            </div>

          

        </>
    )
}

export default Features
const details = [
    {
        id: 1,
        desc: " Made from high-energy density Lithium Ion chemistry"
    },
    {
        id: 2,
        desc: "Sprays up to 650 acres. Lowest cost per acre "
    },
    {
        id: 3,
        desc: " Shipped within 48 hours"
    },
    {
        id: 4,
        desc: "Made in India at its Chennai factory "
    },
    {
        id: 5,
        desc: " BIS certified"
    },
    {
        id: 6,
        desc: "High quality manufacturing for long life "
    },
    {
        id: 7,
        desc: " Warranty up to 6 month or 80% Depth of Discharge"
    },
    {
        id: 8,
        desc: " mPower batteries used by 35 drone manufacturers"
    },
]
const docs = [
    { id: 1 },
    { id: 2 }
]