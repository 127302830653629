import * as React from 'react';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./AccordionTransition.css";
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
export default function AccordionUsage({ data }) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            <Accordion expanded={expanded} onChange={handleChange}
                sx={{ backgroundColor: expanded ? "#80CAFF12" : "", padding: "10px" }}>

                <AccordionSummary
                    expandIcon={expanded ? <CloseIcon className={`close ${expanded ? "closeActive" :""}`} /> :
                     <AddIcon   className={`add ${expanded ? "addActive" :""}`} /> }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className={`accordion-summary ${expanded ? 'expanded' : ''}`}
                >
                    <Typography
                    className={`typo ${expanded ? 'on' : ''}`}
                    
                    >{data.no}</Typography>
                    {data.name}
                </AccordionSummary>
                <AccordionDetails
                    className={`accordion-Details ${expanded ? 'active' : ''}`}>
                    {data.desc}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
