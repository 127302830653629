import React from "react";
import styles from "./index.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactPlayer from "react-player";

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-prev-arrow-banner"
      className={`${styles.customarrow} ${styles.customprevarrow}`}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
    </button>
  );
}

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      // className="custom-arrow-banner custom-next-arrow-banner"
      className={`${styles.customarrow} ${styles.customnextarrow}`}
      onClick={onClick}
    >
      <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
    </button>
  );
}

var settings = {
  className: styles.slickstyles,
  // dots: true,
  autoplay: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: false,
  arrows: true,
  // fade: true,
  autoplaySpeed: 2000,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

function SuccessStories() {
  return (
    <>
    
      {/* <section className={styles.SuccessStories}> */}
        {/* <h3 className={styles.sectionHeading}>Success stories</h3> */}
        <div className={styles.videolisting}>
          <Slider {...settings}>
            {videodata?.map((v) => {
              return (
                <>
                  <div className={styles.videoCard}>
                    <ReactPlayer url={v?.url} width="100%" />
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
    
      {/* </section> */}
    </>
  );
}

export default SuccessStories;

const videodata = [
  {
    id: 1,
    url: "https://youtube.com/shorts/eO9KKe_aVgY?si=aN3dTUxoEh3s82mP",
  },
  {
    id: 2,
    url: "https://youtube.com/shorts/rAHajOKAG2E?si=9-CA-c6ZoPf8pPj6",
  },
  {
    id: 3,
    url: "https://youtube.com/shorts/O0T5SYWTKQM?si=8XVrT7-_tyV3fTdj",
  },
  {
    id: 4,
    url: "https://youtube.com/shorts/YMeg2S2DVjE?si=8FjNsNJcnad5b2bi",
  },
  {
    id: 5,
    url: "https://youtube.com/shorts/D_Eqis-_qWU?si=FHRPaehugsbHcQ-I",
  },
  {
    id: 6,
    url: "https://youtube.com/shorts/_59DrEQ3UVI?si=3o-wLFFVKymScw9F",
  },
];
