import axios from "axios";

export var base_url = "";



if (window.location.href.includes("localhost")) {
  
  // base_url = "http://15.207.54.146:8000/";
  base_url = "https://prod-api.aerobott.com/";
} else if (window.location.href.includes("aerobott")) {
  base_url = "https://prod-api.aerobott.com/";
}else{
  // base_url = "http://15.207.54.146:8000/";
  base_url = "https://prod-api.aerobott.com/";
}

export var appToken = "";

function checkLocal() {
  if (typeof window == 'undefined') return

  if (localStorage.getItem("aerobott_token") == null) {
    return null
  } else {
    return localStorage.getItem("aerobott_token")
  }
}

const _access_token = localStorage.getItem("aerobott_token");
console.log(_access_token, "aerobott_token");
var headers =
  _access_token === null || _access_token === undefined
    ? {
        "Content-Type": "application/json",
      }
    : {
        "Content-Type": "application/json",
        Authorization: `Bearer ${_access_token}`,
      };

export const api_call = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: { "Content-Type": "application/json" },
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

const fetchClient = () => {
  let instance = axios.create({
    baseURL: base_url,
    timeout: 300000,
    headers: { "Content-Type": "application/json" },
    validateStatus: (status) => status < 500,
    transformRequest: [
      function (data, headers) {
        return JSON.stringify(data);
      },
    ],
  });
  instance.interceptors.request.use(function (config) {
    // const token = JSON.parse(localStorage.getItem("phoolchand-access-token"));
    const token = JSON.parse(localStorage.getItem("aerobott_token"));
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });
  instance.interceptors.response.use((res) => {
    if (res.status === 401) {
      // localStorage.removeItem("ed_user");
      // localStorage.removeItem("phoolchand-access-token");
      localStorage.removeItem("aerobott_token")
      window.location.reload();
    }
    return res;
  });
  return instance;
};
export const api_call_token = fetchClient();

// export async function formDataApi(
//   apiName = "",
//   formData,
//   patch = false,
//   apiUrl = "container",
//   auth = false,
// ) {
//   let data = {};
//   // const token = JSON.parse(localStorage.getItem("network_access_token"));
//   if (patch)
//     await axios
//       .patch(base_url + apiUrl + apiName, formData, {
//         // headers: { "content-type": "multipart/form-data" },
//         headers: {
//           Authorization: token_a ? `Bearer ${token_a}` : "",
//           "content-type": "multipart/form-data",
//         },
//         validateStatus: (status) => status < 500,
//       })
//       .then((response) => {
//         if (response.status === 200 || response.status === 201)
//           return (data = response.data);
//         return (data = response.data);
//       })
//       .catch((e) => console.log(e));
//   else
//     await axios
//       .post(base_url + apiUrl + apiName, formData, {
//         // headers: { "content-type": "multipart/form-data" },
//         headers: {
//           Authorization: auth ? token_a ? `Bearer ${token_a}` : "" :"",
//           "content-type": "multipart/form-data",
//         },
//         validateStatus: (status) => status < 500,
//       })
//       .then((response) => {
//         if (response.status === 200 || response.status === 201)
//           return (data = response);
//         return (data = response);
//       })
//       .catch((e) => console.log(e));
//   return data;
// }

export async function formDataApi(
  apiName = "",
  formData,
  patch = false,
  apiUrl = "container",
  auth = false
) {
  let data = {};
  await axios
    .post(base_url + apiUrl + apiName, formData, {
      // headers: { "content-type": "multipart/form-data" },
      headers: {
        "content-type": "multipart/form-data",
      },
      validateStatus: (status) => status < 500,
    })
    .then((response) => {
      if (response.status === 200 || response.status === 201)
        return (data = response);
      return (data = response);
    })
    .catch((e) => console.log(e));
  return data;
}

var token_api = axios.create({
  baseURL: base_url,
  timeout: 300000000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${checkLocal()}`,
  },
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export { token_api };  


export function setToken(_token) {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem("token", _token);
      appToken = _token
      token_api = axios.create({
        baseURL: base_url,
        timeout: 300000000,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        transformRequest: [
          function (data, headers) {
            return JSON.stringify(data);
          },
        ],
      });

      resolve("Successfully set agrimguru token.");
    } catch {
      reject("Error to ser agrimguru token");
    }
  });
}