import React from 'react'
import styles from  './CustomCards.module.css'
import { Button } from '@mui/material'
import battery from '../../assets/BuyDrones/battery.png'
function CustomCards({border}) {
  return (
    // <div className={styles.container}>

    //   <h2 className={styles.head}>Buy Drone <span> Battries</span> </h2>
      <div className={styles.cardCon} >

   
        {/* {
          data.map((item) => {
            return ( */}


              <div className={styles.card} style={{border: border}}>
                <div className={styles.img}>
                  <img src={battery}></img>
                </div>
                <div className={styles.desc}>


                  <p>mPower 6S 25200 mAh Lithium Ion Drone Battery Pack with XT90-S Anti Spark Connector</p>
                  <div className={styles.bottom}>
                    <p className={styles.price}>   ₹2000 <span>   ₹3000 </span></p>
                    <Button variant='contained' className={styles.btn}>Buy Now</Button>
                  </div>
                </div>

              </div>

            {/* )
          })} */}
        
       </div>
    // </div>
  )
}

export default CustomCards
const data = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
]