import React, { useState } from 'react'
import styles from './index.module.css'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { eventWrapper } from '@testing-library/user-event/dist/utils'

function QuotePage() {
  const [jobType, setJobType] = useState('')
  const [otherText, setOtherText] = useState('')
  const [jobDuration, setJobDuration] = useState('oneday')
  const [dates, setDates] = useState('')
  const [customJobDue, setCustomJobDue] = useState('')
  const [customTime, setCostomTime] = useState('')
  // job nedeed //////////////////////////////////////////
  const handleJobTypeChange = (event) => {
    setJobType(event.target.value)
  }

  const handleOtherTextChange = (event) => {
    setOtherText(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    // Handle the form submission here
    // You can access the selected job type with 'jobType'
    // and the additional text input with 'otherText'
  }

  ///////////////////////////////////////////////////////////

  //////////JOB duration ///////////////////////////////////
  const handelJobDureation = (event) => {
    setJobDuration(event.target.value)
  }
  /////////////////////////////////////////////////////////
  const handleOtherCustomChange = (event) => {
    console.log(event.target.value, 'jobDeuCustom')
    setCustomJobDue(event.target.value)
  }
  // ///////////////////////////////////////////////////
  const handleTimeCustomChange = (event) => {
    console.log(event.target.value, 'jobDeuCustom')
    setCostomTime(event.target.value)
  }

  return (
    <>
      <section className={styles.mainQuoteContainer}>
        <h1 className={styles.topHeading}>
          Get the best Quotes with high quality services
        </h1>
        <form>
        <div className={styles.fillForm}>
          <h3 className={styles.formTitle}>Fill the following details</h3>
          <div className={styles.pincodeCheck}>
            <h4 className={styles.filedHeading}>
              Enter pincode to check for avalibility
            </h4>
            <TextField
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
            />
          </div>
          <div className={styles.jobNedded}>
            <h4 className={styles.filedHeading}>What type of job is needed</h4>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              // defaultValue="female"
              name="radio-buttons-group"
              value={jobType}
              onChange={handleJobTypeChange}
            >
              <div className={styles.radiosBtns}>
                {jobNeededData.map((info) => {
                  return (
                    <>
                      <FormControlLabel
                        value={info.values}
                        control={<Radio />}
                        label={info.labelName}
                        className={styles.radioBtn}
                      />
                    </>
                  )
                })}
              </div>

              {jobType === 'other' && (
                <div className={styles.otherFiled}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Multiline"
                    multiline
                    style={{ width: '100%' }}
                    rows={4}
                    value={otherText}
                    onChange={handleOtherTextChange}
                  />
                </div>
              )}
            </RadioGroup>
          </div>
          <div className={styles.jobDuration}>
            <h4 className={styles.filedHeading}>Duration of the job</h4>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="oneday"
              name="radio-buttons-group"
              onChange={handelJobDureation}
            >
              <div className={styles.radiosBtns}>
                {jobDeu.map((info) => {
                  return (
                    <>
                      <FormControlLabel
                        value={info.values}
                        control={<Radio />}
                        label={info.labelName}
                        className={styles.radioBtn}
                      />
                    </>
                  )
                })}
              </div>
              {jobDuration === 'Single day' && (
                <div className={styles.selectDates}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="For a day"
                      inputFormat="MM/DD/YYYY"
                      // value={value}
                      // onChange={handleChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              )}
              {jobDuration === 'Project Bases' && (
                <div className={styles.selectDates}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="From Date"
                      inputFormat="MM/DD/YYYY"
                      // value={value}
                      // onChange={handleChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="To Date"
                      inputFormat="MM/DD/YYYY"
                      // value={value}
                      // onChange={handleChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              )}
              {jobDuration === 'Customised' && (
                <>
                  <div className={styles.selectDates}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="From Date"
                        inputFormat="MM/DD/YYYY"
                        // value={value}
                        // onChange={handleChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="To Date"
                        inputFormat="MM/DD/YYYY"
                        // value={value}
                        // onChange={handleChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className={styles.costomizeDetails}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      // defaultValue="oneday"
                      name="radio-buttons-group"
                      onChange={handleOtherCustomChange}
                    >
                      <div className={styles.radiosBtns}>
                        {jobDeuCustom.map((info) => {
                          return (
                            <>
                              <FormControlLabel
                                value={info.values}
                                control={<Radio />}
                                label={info.labelName}
                                className={styles.radioBtn}
                              />
                            </>
                          )
                        })}
                      </div>
                      <div className={styles.otherFiled}>
                        {customJobDue === 'CustomOther' && (
                          <TextField
                            id="outlined-multiline-static"
                            label="Multiline"
                            multiline
                            style={{ width: '100%' }}
                            // rows={4}
                            value={otherText}
                            // onChange={}
                          />
                        )}
                      </div>
                    </RadioGroup>
                  </div>
                </>
              )}
            </RadioGroup>
          </div>
          <div className={styles.jobLength}>
            <h4 className={styles.filedHeading}>Length of the job</h4>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              // defaultValue="female"
              name="radio-buttons-group"
              // value={}
              onChange={handleTimeCustomChange}
            >
              <div className={styles.radiosBtns}>
                {jobLengthData.map((info) => {
                  return (
                    <>
                      <FormControlLabel
                        value={info.values}
                        control={<Radio />}
                        label={info.labelName}
                        className={styles.radioBtn}
                      />
                    </>
                  )
                })}
              </div>

              {customTime === 'CustomisedTime' && (
                <div className={styles.otherFiled}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Multiline"
                    multiline
                    style={{ width: '100%' }}
                    rows={4}
                    value={otherText}
                    onChange={handleOtherTextChange}
                  />
                </div>
              )}
            </RadioGroup>
          </div>
          <div className={styles.jobLength}>
            <h4 className={styles.filedHeading}>Multiple Pilots Needed?</h4>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              // defaultValue="female"
              name="radio-buttons-group"
              // value={}
              // onChange={handleTimeCustomChange}
            >
              <div className={styles.radiosBtns}>
                {yesNo.map((info) => {
                  return (
                    <>
                      <FormControlLabel
                        value={info.values}
                        control={<Radio />}
                        label={info.labelName}
                        className={styles.radioBtn}
                      />
                    </>
                  )
                })}
              </div>
            </RadioGroup>
          </div>
          <div className={styles.jobLength}>
            <h4 className={styles.filedHeading}>Drone Required</h4>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              // defaultValue="female"
              name="radio-buttons-group"
              // value={}
              // onChange={handleTimeCustomChange}
            >
              <div className={styles.radiosBtns}>
                {yesNo.map((info) => {
                  return (
                    <>
                      <FormControlLabel
                        value={info.values}
                        control={<Radio />}
                        label={info.labelName}
                        className={styles.radioBtn}
                      />
                    </>
                  )
                })}
              </div>
            </RadioGroup>
          </div>
          <div className={styles.jobLength}>
            <h4 className={styles.filedHeading}>Address for the Site</h4>
            <div className={styles.address}>
              <TextField
                id="outlined-multiline-static"
                label="Multiline"
                multiline
                style={{ width: '100%' }}
                rows={4}
                value={otherText}
                onChange={handleOtherTextChange}
              />
            </div>
          </div>
          <div className={styles.formDataautoFill}>
            <h4 className={styles.filedHeading}>Where to send the quote</h4>
            <div className={styles.formFilled}>
              {
                pilotdata.map((info)=>{
                  return(
                  <>
                    <TextField disabled id="standard-basic" label="First Name" variant="standard" value={info.f_name} className={styles.defaultFillValue}/>
                    <TextField disabled id="standard-basic" label="First Name" variant="standard" value={info.l_name} className={styles.defaultFillValue}/>
                    <TextField disabled id="standard-basic" label="First Name" variant="standard" value={info.email} className={styles.defaultFillValue}/>
                    <TextField disabled id="standard-basic" label="First Name" variant="standard" value={info.number} className={styles.defaultFillValue}/>
                  </>
                  )
                })
              }
            {/* <TextField id="standard-basic" label="First Name" variant="standard" className={styles.defaultFillValue}/>
            <TextField id="standard-basic" label="Last Name" variant="standard" className={styles.defaultFillValue} />
            <TextField id="standard-basic" label="Email" variant="standard" className={styles.defaultFillValue}/>
            <TextField id="standard-basic" label="Phone Number" variant="standard" className={styles.defaultFillValue} /> */}
            </div>
          </div>
        </div>
        <div className={styles.formBtns}>
          <button className={styles.cancel}>Cancel</button>
          <button className={styles.submit}>Submit Form</button>
        </div>
        </form>
      </section>
    </>
  )
}

export default QuotePage

const jobNeededData = [
  {
    id: 1,
    values: 'aerial-mapping',
    labelName: 'Aerial Mapping',
  },
  {
    id: 2,
    values: 'aerial-photography',
    labelName: 'Aerial Photography',
  },
  {
    id: 3,
    values: 'aerial-videography',
    labelName: 'Aerial Videography',
  },

  {
    id: 4,
    values: 'TV/Film',
    labelName: 'TV/Film',
  },
  {
    id: 5,
    values: 'land-survey',
    labelName: 'Land Survey',
  },

  {
    id: 6,
    values: 'agriculture-survey',
    labelName: 'Agriculture Survey',
  },

  {
    id: 7,
    values: 'crop-health-analysis',
    labelName: 'Crop Health Analysis',
  },
  {
    id: 8,
    values: 'GIS',
    labelName: 'GIS Survey',
  },
  {
    id: 9,
    values: 'aerial-inspection',
    labelName: 'Aerial Inspection',
  },
  {
    id: 10,
    values: 'thermal-imaging',
    labelName: 'Thermal Imaging',
  },
  {
    id: 11,
    values: 'other',
    labelName: 'Other',
  },
]

const jobDeu = [
  {
    id: 1,
    values: 'Single day',
    labelName: 'Single day',
  },
  {
    id: 2,
    values: 'Project Bases',
    labelName: 'Project Bases',
  },
  {
    id: 3,
    values: 'Customised',
    labelName: 'Customised',
  },
]

const jobDeuCustom = [
  {
    id: 1,
    values: 'Once a Week',
    labelName: 'Once a Week',
  },
  {
    id: 2,
    values: 'Once a Month',
    labelName: 'Once a Month',
  },
  {
    id: 3,
    values: 'Once a Year',
    labelName: 'Once a Year',
  },
  {
    id: 4,
    values: 'CustomOther',
    labelName: 'Other',
  },
]

const jobLengthData = [
  {
    id: 1,
    values: '1-2 Hours',
    labelName: '1-2 Hours',
  },
  {
    id: 2,
    values: 'Half Day',
    labelName: 'Half Day',
  },
  {
    id: 3,
    values: 'Full Day',
    labelName: 'Full Day',
  },
  {
    id: 4,
    values: 'CustomisedTime',
    labelName: 'Customised',
  },
]

const yesNo = [
  {
    id: 1,
    values: 'yes',
    labelName: 'Yes',
  },
  {
    id: 2,
    values: 'no',
    labelName: 'No',
  },
]

const pilotdata =[
  {
    f_name: "abc",
    l_name: "pqr",
    email: "abc@gmail.com",
    number: "9875897578",
  }
]