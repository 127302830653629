import React, { useState } from 'react'
import styles from './BatteryPrice.module.css'
import { Button, Input } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import shipping from '../../assets/battery/shipping.png'
function BatteryPrice() {
    const pattern = /^\d{6}$/
    const [pincode, setPincode] = useState({ input: "" })
    const [verifyPincode, setVerifyPincode] = useState({

        condition: "",
        show: false,
        text: "",
    })
    const [number, setNumber] = useState(0)

    console.log(pincode, "ffffff")

    const HandleChange = (e) => {
        const value = e.target.value;
        setPincode({ input: value });
        setVerifyPincode({
            condition: "",
            show: false,
            text: "",
        })
    };
    console.log(pincode, "pincode")
    const CheckInput = () => {
        if (pattern.test(pincode.input)) {
            setVerifyPincode({
                condition: "Success",
                show: true,
                text: "its Avalible"
            })
        }
        else {
            setVerifyPincode({
                condition: "Error",
                show: true,
                text: "Not Avalible"
            })
        }
    }
    const Increment = () => {
        setNumber(number + 1)
    }
    const Decrement = () => {
        setNumber(number - 1)
    }
    return (
        <div className={styles.container}>

            <div className={styles.price}>
                <p className={styles.name}>Price</p>
                <p className={styles.amount}>   ₹ 49,000  <span>  ₹ 38,232  </span> /Piece (GST Inc.)   </p>
            </div>
            <div className={styles.price}>
                <p className={styles.name}>Delivery</p>
                <p className={styles.name}>Free</p>


            </div>
            <hr className={styles.hr}></hr>

            <div>
                <p className={styles.subname}>
                    Delivery location pincode
                </p>
                <div className={styles.inputs}>
                    <input type='text' placeholder='Pincode' value={pincode.input} onChange={HandleChange}></input>
                    <Button variant='text' onClick={CheckInput}>Check Pincode</Button>

                </div>

                {
                    verifyPincode.show && (
                        verifyPincode?.condition === "Success" ? (<div className={styles.pincode}><CheckCircleIcon color='success' /><p>{verifyPincode?.text}</p></div>) :
                            verifyPincode?.condition === "Error" ? (<div className={styles.pincode}><ErrorOutlineIcon color='warning' /><p>{verifyPincode?.text}</p></div>) : ""
                    )
                }
                <hr className={styles.hr}></hr>

            </div>
            <div className={styles.quantity}>
                <p className={styles.subname}>
                    Select quantity
                </p>
                <div className={styles.numbers}>

                    <button onClick={Decrement}>-</button>  {number}   <button onClick={Increment}>+</button>
                </div>
            </div>
            <hr className={styles.hr}></hr>
            <div>



                <p className={styles.order}>Maximum Order Quantity 6 Set. Need a <span className={styles.links}>Bulk Order </span>or <span className={styles.links}>Custom order?</span></p>
                <p className={styles.order}>Need a custom order or have any doubts?<span className={styles.links}>Call Us </span>or <span className={styles.links}> Whatsapp</span></p>

            </div>
            <div className={styles.formBottom}>
                <div className={styles.shipping}>
                    <div className={styles.truck}>
                        <img src={shipping}></img>
                    </div>
                    <p className={styles.hours}>Ships within 48 hrs after receiving order</p>
                </div>
                <Button variant='contained' className={styles.buy}>Buy Now</Button>
            </div>

        </div>
    )
}

export default BatteryPrice