import styles from "./index.module.css";
import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import searchLogo from "../../assets/images/searchIcon.png";
import profileImg from "../../assets/images/ProfileImg.png";
import nouser from "../../assets/images/nouser.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, NavLink } from "react-router-dom";
import CallIcon from '@mui/icons-material/Call';
// import { Button } from '@mui/material'

const Header = () => {
  const [userLogin, setUserLogin] = useState(true);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState({
    top: false, // Only 'right' is needed
  });
  const localStorageValue = localStorage.getItem("aerobott_token");

  console.log(localStorageValue, "localStorageValue");
  // if(localStorageValue !== ""){
  //   setUserLogin(false)
  // }
  const open = Boolean(anchorEl);
  const handleLoginClick = () => {
    setUserLogin(true);
    return navigate("/login");
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogoutClick = () => {
    localStorage.removeItem("aerobott_token");
    localStorage.removeItem("aerobott_user");
    setUserLogin(true);
    setAnchorEl(null);
  };
  // const handleLogoutClose = () => {
  //   setAnchorEl(null)
  // }
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "right" ? 250 : "auto" }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <List > */}
      {/* {['Products', 'Excellency Center', 'Drone as a Service', 'Contact'].map((text, index) => ( */}
      {/* {menuList.map((text, index) => (
          <ListItem key={text} onClick={toggleDrawer(anchor, false)} disablePadding style={{textAlign:"center", justifyContent:"center"}}>
            <ListItemButton> */}
      {/* <ListItemText primary={text?.navmenu} /> */}
      {/* <NavLink to={text.navLink} style={{color:"#000", textAlign:"center"}} onClick={() => {navigate(text.navLink)}}>{text.navmenu} </NavLink>
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <div className={styles.mobilenavList}>
        <div className={styles.mlogoClose}>
          <div className={styles.mlogoImg}>
            <img src={logo} alt="logo" />
          </div>
          <div style={{ textAlign: "right" }}>
            <CloseIcon onClick={toggleDrawer(anchor, false)} />
          </div>
        </div>
        {menuListnew.map((text, index) => {
          return (
            <div
              style={{
                textAlign: "center",
                margin: "20px 0",
                textDecoration: "none",
              }}
              onClick={toggleDrawer(anchor, false)}
            >
              <NavLink
                to={text.navLink}
                style={{
                  color: "#000",
                  textAlign: "center",
                  textDecoration: "none",
                }}
                onClick={() => {
                  navigate(text.navLink);
                }}
              >
                {text.navmenu}{" "}
              </NavLink>
            </div>
          );
        })}
      </div>
    </Box>
  );

  return (
    <>
    <div className={styles.topnavtextScroll}>
          <marquee>
          <a
            href="tel:+91 9004711184"
            className={styles.callUs}
            style={{
              display: "flex",
              // color: "white",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CallIcon style={{ marginRight: "10px" }} /> +91 9004711184 Call us for the best drone services in India.
          </a>
          </marquee>
        </div>
      <header>
        
        <div className={styles.siteLogo}>
          <NavLink to="">
            {" "}
            <img src={logo} alt="logo " />
          </NavLink>
        </div>
        <div className={styles.rightNav}>
          <nav className={styles.headerNavigation}>
            <ul>
              {menuListnew.map((info, index) => {
                console.log(info);
                return (
                  <>
                    <li key={index}>
                      <NavLink
                        to={info.navLink}
                        onClick={(e) => {
                          if (!info.navLink) {
                            e.preventDefault(); // Prevent the default behavior (navigation)
                            // Optionally, you can add some logic here for the case when there's no navigation link
                            console.log(
                              "No navigation link provided. Staying on the same page."
                            );
                          } else {
                            navigate(info.navLink); // Navigate to the specified link
                          }
                        }}
                      >
                        {info.navmenu}
                        <ul className={styles.submenubox}>
                          {info?.subMenu?.map((v, i) => {
                            return (
                              <>
                                <li className={styles.submenulist}>
                                  <NavLink
                                    to={v?.navsmenu}
                                    onClick={(e) => {
                                      if (!v?.navsLink) {
                                        e.preventDefault(); // Prevent the default behavior (navigation)
                                        // Optionally, you can add some logic here for the case when there's no navigation link
                                        console.log(
                                          "No navigation link provided. Staying on the same page."
                                        );
                                      } else {
                                        navigate(info.navLink); // Navigate to the specified link
                                      }
                                    }}
                                  >
                                    
                                    {v?.navsmenu}
                                  </NavLink>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </NavLink>
                    </li>
                  </>
                );
              })}
            </ul>
          </nav>
          <div className={styles.searchProfileBox}>
            {/* <div className={styles.navSearch}>
              <img src={searchLogo} alt="" />
            </div> */}

            {/* <button
                className={styles.profilebox}
                style={{ backgroundColor: 'transparent', border: 'none' }}
                onClick={handleLoginClick}
              >
                <img src={nouser} alt="" />
              </button> */}
            {localStorageValue == null ? (
              <>
                <button
                className={styles.profilebox}
                style={{ backgroundColor: 'transparent', border: 'none' }}
                onClick={handleLoginClick}
              >
                <img src={nouser} alt="" />
              </button>
               {/* uncomment when login function is done */}
             
              </>
            ) : (
              <>
                <button
                  className={styles.profilebox}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  style={{ backgroundColor: "transparent", border: "none" }}
                  onClick={handleMenuClick}
                >
                  <img src={profileImg} alt="" />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    // onClose={handleLogoutClick}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem>Profile</MenuItem>
                    <MenuItem>My account</MenuItem>
                    <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
                  </Menu>
                </button>
              </>
            )}
          </div>
        </div>

        {/* NAVIGATION FOR MOBILE */}
        <div className={styles.rightNavMobile}>
          <div>
            <button
              className={styles.mobileLoginbtn}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleMenuClick}
            >
              Login
            </button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleLogoutClick}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>Profile</MenuItem>
              <MenuItem>My account</MenuItem>
              <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
            </Menu>
          </div>

          <div key="top">
            {/* Only render for the 'right' anchor */}
            {/* <React.Fragment key="right"> */}
            <Button onClick={toggleDrawer("top", true)}>
              <div className={styles.burgermenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Button>
            <Drawer
              anchor="top"
              open={state.top}
              onClose={toggleDrawer("top", false)}
            >
              {list("top")}
            </Drawer>
            {/* </React.Fragment> */}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

const menuList = [
  // {
  //   id:1,
  //   navmenu:"Product",
  //   // navLink :"product",
  // },
  {
    id: 1,
    navmenu: "Product",
    // navLink :"product-details",
  },
  {
    id: 2,
    navmenu: "Excellency Center",
    // navLink :"excellence-center",
  },

  {
    id: 3,
    navmenu: "Careers",
    navLink: "careers",
  },
  {
    id: 4,
    navmenu: "Contact",
    navLink: "contact",
  },
];

const menuListnew = [
  {
    id: 1,
    navmenu: "Home",
    navLink :"/",
  },
  {
    id: 2,
    navmenu: "Training",
    navLink :"courses",
    // subMenu: [
    //   {
    //     id: 10,
    //     navsmenu: "RPTO",
    //     // navsLink: "careers",
    //   },
    //   {
    //     id: 11,
    //     navsmenu: "Excellency Center",
    //     // navsLink: "careers",
    //   },
    // ],
  },

  // {
  //   id: 3,
  //   navmenu: "Our Drones",
  //   // navLink: "careers",
  //   subMenu: [
  //     {
  //       id: 20,
  //       navsmenu: "Training Drone",
  //       // navsLink: "careers",
  //     },
  //     {
  //       id: 21,
  //       navsmenu: "Agriculture Drone",
  //       // navsLink: "careers",
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   navmenu: "DAAS",
  //   // navLink: "contact",
  //   subMenu: [
  //     {
  //       id: 30,
  //       navsmenu: "Agriculture Services",
  //       // navsLink: "careers",
  //     },
  //     {
  //       id: 31,
  //       navsmenu: "Survey & Mapping",
  //       // navsLink: "careers",
  //     },
  //     {
  //       id: 32,
  //       navsmenu: "Photography & VideoGraphy",
  //       // navsLink: "careers",
  //     },
  //     {
  //       id: 33,
  //       navsmenu: "Drone Parts & Repairs",
  //       // navsLink: "careers",
  //     },
  //     {
  //       id: 34,
  //       navsmenu: "Survey & Mapping",
  //       // navsLink: "careers",
  //     },
  //     {
  //       id: 35,
  //       navsmenu: "Commercial Inspection Services",
  //       // navsLink: "careers",
  //     },
  //     {
  //       id: 36,
  //       navsmenu: "Data Analytics",
  //       // navsLink: "careers",
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   navmenu: "Hire A Pilot",
  //   // navLink: "contact",
  // },
  {
    id: 6,
    navmenu: "About us",
    // navLink: "contact",
  },
  // {
  //   id:7,
  //   navmenu:"Login",
  //   // navLink :"contact",
  // },
];
