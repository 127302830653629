import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import logo from "../../assets/images/logo.png";
import pdf from "../../assets/images/dummy.pdf";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { api_call, formDataApi } from "../../Utils/Network";
// import snackbarComponent from "../../components/SnackBarComponent/snackbarComponent";
// import snackbarComponent from "../../components/SnackBarComponent/SnackbarComponent";
import SnackbarComponent from "../../components/SnackBarComponent/SnackbarComponent";
import careers from "../../assets/images/careers.png"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Carrers() {
  const [open, setOpen] = useState(false);
  const [jp, setJp] = useState("");
  const [jobData, setJobData] = useState([]);
  const [jposition, setJposition] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    // first_name: "",
    // last_name: "",
    full_name: "",
    email: "",
    phone: "",
    city: "",
    job_id: "",
  });
  const [fullNameError, setFullNameError] = useState(false);
  //   const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [jobPositionError, setJobPositionError] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [page, setPage] = useState(1);
  const [lastElement, setLastElement] = useState(null);
  const [maxpage, setmaxPage] = useState(1)


  const observer = useRef(typeof window != 'undefined' && new IntersectionObserver(
    (entries) => {
      const first = entries[0];
      if (first.isIntersecting && page <= maxpage) {
        setPage((no) => no + 1);
      }
    }));
 
  useEffect(() => {
    if (page <= maxpage) {
      getJobListing();
    
    }
  }, [page]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentObserver == false) return;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);



  const handleChange = (event) => {
    setJp(event.target.value);
    // Reset validation errors when user starts typing
    // if (name === "first_name") {
    //   setFullNameError(false);
    //   // } else if (name === "last_name") {
    //   //   setLastNameError(false);
    // } else if (name === "email") {
    //   setEmailError(false);
    // } else if (name === "phone") {
    //   setPhoneError(false);
    // }
  };

  const handleChangeApply = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  console.log("formrmrm", formData);

  // Regular expressions for phone number and email validation
  const phoneRegex = /^\d{10}$/; // Assumes a 10-digit phone number format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form submission or other actions with the form data

    // Validate required fields
    if (formData.first_name === "") {
      setFullNameError(true);
      return;
    }
    // if (formData.last_name === "") {
    //   setLastNameError(true);
    //   return;
    // }
    if (formData.email === "" || !emailRegex.test(formData.email)) {
      setEmailError(true);
      return;
    }
    if (formData.phone === "" || !phoneRegex.test(formData.phone)) {
      setPhoneError(true);
      return;
    }
    console.log(formData, "posthit");

    api_call.post("base/v1/contact_us/", formData);

    // Clear the form after submission
    setFormData({
      full_name: "",
      email: "",
      phone: "",
      city: "",
      job_id: "",
    });

    // Reset the error state variables
    setFullNameError(false);
    // setLastNameError(false);
    setEmailError(false);
    setPhoneError(false);
  };
  //   const handleChange = (event) => {
  //     setAge(event.target.value);
  //   };
  const handleOpen = (jobId) => {
    // console.log("JObID" , jobId);
    setFormData({
      ...formData,
      job_id: jobId,
    });

    setOpen(true);
  };

  const handleClose = () => {
    console.log("closebtnclick");
    setOpen(false);
  };

  const getJobListing = () => {
    api_call
      .get(`career/v1/jobs/?page=${page}`)
      .then((res) => {
        // setJobData(res?.data?.data);
        console.log(res?.data?.data, "getJobListion");
        const maxpage = res?.data?.max_pages
          setmaxPage(maxpage);
        const data = res.data.data;
        setJobData(prevState => {
            const updatedState = [...prevState, ...data];
            return updatedState;
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getpositionListing = () => {
    api_call
      .get(`base/v1/job/position/listing/`)
      .then((res) => {
        setJposition(res?.data?.data);
        console.log(res?.data?.data, "getJobpListion");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // getJobListing();
    getpositionListing();
  }, []);

  const handelopnePdf = () => {
    window.open(pdf);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("dsdsddwdwf", file);
    setSelectedFile(file);
    setFileError(false);
  };

  //   ////////////////////////////

  // //////////////////////////////

  const submitData = async (e) => {
    e.preventDefault();
    console.log(e.value, "findAWHAT");
    // Reset all error states
    setFullNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setFileError(false);
    setJobPositionError(false);

    const phoneRegex = /^\d{10}$/; // Assumes a 10-digit phone number format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.first_name === "") {
      setFullNameError(true);
      return;
    }
    // if (formData.last_name === "") {
    //   setLastNameError(true);
    //   return;
    // }
    
    if (formData.phone === "" || !phoneRegex.test(formData.phone)) {
      setPhoneError(true);
      return;
    }
    if (formData.email === "" || !emailRegex.test(formData.email)) {
      setEmailError(true);
      return;
    }
    // Validate file input
    if (selectedFile == null) {
      setFileError(true);
      return;
    }

    // Validate job_position
    if (formData.job_position == "") {
      setJobPositionError(true);
      return;
    }

    // if(error) return
    const formdata = new FormData();

    formdata.append("full_name", formData?.full_name);
    formdata.append("email", formData?.email);
    formdata.append("phone", formData?.phone);
    formdata.append("city", formData?.city);
    formdata.append("job_id", formData?.job_id);
    formdata.append("resume", selectedFile);

    const apiEnd = "";
    const apiUrl = "career/v1/apply/jobs/";
    console.log("sadadad", formDataApi);

    try {
      const res = await formDataApi(apiEnd, formdata, false, apiUrl);
      console.log(res, "fromsubmited");
      if (res?.status == 200 || res?.status == 201) {
        setSelectedFile();
        handleClose();
        //   setFormSumbimtedOpne(true)
        setFormData({
          full_name: "",
          email: "",
          phone: "",
          city: "",
          job_id: "",
        });
        setFullNameError(false);
        setEmailError(false);
        setPhoneError(false);
        setShowSnackbar(true);
      }
    } catch (error) {
      // setDisabledData(false);
      console.log(error);
    }
  };

  return (
    <section className={styles.mainContainer}>
      <div className={styles.topHeadings}>
        {/* <div className={styles.headingImgContainer}>
          <img src={logo}></img>
        </div> */}
        {/* <div className={styles.topText}>
          <h2 className={styles.hireHeading}> We're Hiring </h2>
          <h1 className={styles.Heading}> Join Our Team</h1>
          <p>
            Aerobott is a leading drone manufacturing and training company that
            creates professional drones for Industrial, Agriculture and Survey
            purposes. The company is established to set standards in the
            aviation industry. As a firm we intend to design, develop and
            manufacture drones and along with this we also provide Pilot
            Training services (RPTO) which are approved by DGCA.
          </p>
        </div> */}
        <div className={styles.leftsideInfobox}>
        <h1 className={styles.Heading}> Join Our Team</h1>
        <p>
            Aerobott is a leading drone manufacturing and training company that
            creates professional drones for Industrial, Agriculture and Survey
            purposes. The company is established to set standards in the
            aviation industry. As a firm we intend to design, develop and
            manufacture drones and along with this we also provide Pilot
            Training services (RPTO) which are approved by DGCA.
          </p>
          <h3 className={styles.PointerHeading}>
          Why you should join Aerobott:- 
          </h3>
          <ul className={styles.pointerList}>
            <li>Be at the forefront of a rapidly growing industry.</li>
            <li>Unlock opportunities for career growth.</li>
            <li>Work with cutting-edge technology.</li>
            <li>Collaborate with a diverse and passionate team.</li>
            <li>Make a real impact on the future.</li>
          </ul>
        </div>
        <div className={styles.rightSideinfo}>
            <img src={careers} />
        </div>
      </div>
      <h3 className={styles.opnePostion}>
        Currently, we are looking to fill multiple positions PAN India, Please
        find the below the position and No. of openings:
      </h3>
      <div className={styles.JobListingContrainer}>
        <div className={styles.joblistingHeading}>
          <p>ROLE</p>
          <p>LOCATION</p>
        </div>
        {jobData?.map((info) => {
          return (
            <div className={styles.jobCard} ref={setLastElement}>
              <div className={styles.jobDesc}>
                <h5 className={styles.jobTitle}>{info?.title}</h5>
                <p className={styles.jobExp}>
                  ({info?.experience}){/* <span>$120k - $200k</span> */}
                </p>
                <p className={styles.timePosted}>
                  <span style={{ fontWeight: "700", color: "#000" }}>
                    Qulification :
                  </span>{" "}
                  {info?.qualification}
                </p>
                <p className={styles.timePosted}>
                  <span style={{ fontWeight: "700", color: "#000" }}>
                    Openings :
                  </span>{" "}
                  {info?.number_of_opening}
                </p>
                <p className={styles.timePosted}>
                  <span style={{ fontWeight: "700", color: "#000" }}>
                    Skills :
                  </span>

                  <div dangerouslySetInnerHTML={{ __html: info?.skills }} />
                  {/* <p>{info?.skills}</p> */}
                </p>
              </div>
              <p className={styles.locationType}>{info?.location}</p>
              {/* <button className={styles.jobDescBtn} onClick={handelopnePdf}>
                Get job insights
              </button> */}
              <button
                onClick={() => {
                  handleOpen(info?.id);
                }}
                className={styles.fromapplydata}
              >
                Apply
              </button>
            </div>
          );
        })}

        <div>
          {/* <button onClick={handleOpen}>Apply</button> */}
          {/* <button onClick={handleOpen} className={styles.fromapplydata}>Apply</button> */}
        </div>
      </div>

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={styles.modelMobile} autoComplete="off">
            <div style={{ textAlign: "right" }}>
              <CloseIcon onClick={handleClose} />
            </div>
            <from className={styles.from}>
              <TextField
                required
                style={{ width: "100%", margin: "10px 0" }}
                name="full_name"
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                value={formData?.full_name}
                onChange={handleChangeApply}
                error={fullNameError}
                helperText={fullNameError ? "Name is required" : ""}
              />
              {/* <TextField
              required
              style={{width:"100%" , margin:"10px 0"}}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              /> */}
              <TextField
                required
                style={{ width: "100%", margin: "10px 0" }}
                id="outlined-number"
                name="phone"
                label="Mobile Number"
                value={formData?.phone}
                onChange={handleChangeApply}
                inputProps={{maxLength:10, inputMode:'numeric',pattern:'[0-9]*'}}
                type="number"
                variant="outlined"
                error={phoneError}
                helperText={phoneError ? "Number is required" : ""}
              />
              <TextField
                required
                style={{ width: "100%", margin: "10px 0" }}
                id="outlined-basic"
                name="email"
                label="Email ID"
                value={formData?.email}
                onChange={handleChangeApply}
                type="email"
                variant="outlined"
                error={emailError}
                helperText={emailError ? "Email is required" : ""}
              />
              <Box
                sx={{ minWidth: 120 }}
                style={{ width: "100%", margin: "10px 0" }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Job Position
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={jp}
                    name="job_position"
                    label="Job Position"
                    value={formData?.job_position}
                    onChange={handleChangeApply}
                    error={jobPositionError}
                    helperText={jobPositionError ? "Enter Job Position" : ""}
                  >
                    {jposition?.map((v) => {
                      return <MenuItem value={v?.id}>{v?.position}</MenuItem>;
                    })}
                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
              </Box>
              <TextField
                style={{ width: "100%", margin: "10px 0" }}
                name="city"
                id="outlined-basic"
                label="City living in"
                variant="outlined"
                value={formData?.city}
                onChange={handleChangeApply}
              />
              <label for="uploadFile" className={styles.fileName}>
                {/* Upload Resume */}
                <span> {selectedFile ? selectedFile.name : "Upload Your Resume"}</span>
                <input
                  className="uploadFile"
                  onChange={handleFileChange}
                  name="resume"
                  type="file"
                  id="uploadFile"
                  accept=".pdf, .doc, .txt"
                  placeholder="Upload Resume"
                ></input>
              </label>
              {fileError && (
                <div className={styles.fromerrorMessage}>
                  Please select a file (PDF, DOC, or TXT).
                </div>
              )}
              <div>
                <button className={styles.submitBtn} onClick={submitData}>
                  Submit
                </button>
              </div>
            </from>
          </Box>
        </Modal>
        {showSnackbar && (
      <SnackbarComponent
        severity="success"
        message="Thank You for filling the form"
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
      />
    )}
      </div>
    </section>
  );
}

export default Carrers;
