import * as React from "react";
import ContactCard from "../../components/ContactCard/contactCard";
import ContactForm from "../../components/ContactForm";
import BannerImage from "../../assets/images/image_13.png";
import SeconderyBannerImage from "../../assets/images/secondBanner.png";
import styles from "./index.module.css";
import MembershipServices from "../../components/MembershipContainer";
import Container from "@mui/material/Container";
const Contact = () => {
  return (
    <div className={styles.contact_page}>
      <div className={styles.background}>
        <div className={styles.bannerText}>
          <h1>Aerobot Support Desk</h1>
          <p>We are here to Help you</p>
        </div>
      </div>

      <section className={styles.product_support}>
        <Container maxWidth="xl">
          <div className={styles.product_title}>
            <h2>Product Support</h2>
          </div>
          <div className={styles.d_flex}>
            {
              product_support?.map((info) => {
                return(
                  <ContactCard 
                  key={info.id}
                  {...info}
                  />
                )
              })
            }
            {/* <ContactCard /> */}
          </div>
        </Container>
      </section>
      <section className={styles.request_inquiry}>
        <Container maxWidth="xl">
          <div className={styles.request_inquiry}>
            <h2>Request & Inquiry</h2>
          </div>
          <div className={styles.d_flex}>
          {
            request_inquiry?.map((info) =>{
              return(
                <ContactCard 
                  key={info.id}
                  {...info}
                />
              )
            })
          }
          </div>
          {/* <div className={styles.mt2}></div>
          <ContactCard></ContactCard> */}
        </Container>
      </section>
      <div className={styles.mt4}></div>
      <MembershipServices />
      {/* <div
        className="background"
        style={{
          backgroundImage: `url(${SeconderyBannerImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '400px',
        }}
      >
        <h1>Membership & Services Plans</h1>
        <p>
          View our membership and services plan to keep your product up-to date
        </p>
        <button className="acceptButton">Learn More</button>
      </div> */}
      <div className={styles.mb4}></div>
      <section className={styles.Contact_login}>
        <Container maxWidth="xl">
          <div className={styles.login_title}>
            <h1>Ask us Questions</h1>
            <p>Write to us about your requirements and we shall get it done!</p>
          </div>
          <ContactForm />
        </Container>
      </section>
    </div>
  );
};

export default Contact;

const product_support = [
  {
    id:1,
    heading: "Video Tutorials",
    description:
      "Learn with our step-by-step tutorial to get started with drone flying",
    buttonText: "Learn More",
  },
  {
    id:2,
    heading: "How-to Guide",
    description: "Read through our drone material to get started with flying",
    buttonText: "Learn More",
  },
  {
    id:3,
    heading: "FAQs",
    description: "Frequently asked questions to solve your doubts easily",
    buttonText: "Learn More",
  },
];

const request_inquiry = [
  {
    id:1,
    heading: "Video Tutorials",
    description:
      "Learn with our step-by-step tutorial to get started with drone flying",
    buttonText: "Learn More",
  },
  {
    id:2,
    heading: "How-to Guide",
    description: "Read through our drone material to get started with flying",
    buttonText: "Learn More",
  },
  {
    id:3,
    heading: "FAQs",
    description: "Frequently asked questions to solve your doubts easily",
    buttonText: "Learn More",
  },
  {
    id:4,
    heading: "Video Tutorials",
    description:
      "Learn with our step-by-step tutorial to get started with drone flying",
    buttonText: "Learn More",
  },
  {
    id:5,
    heading: "How-to Guide",
    description: "Read through our drone material to get started with flying",
    buttonText: "Learn More",
  },
  {
    id:6,
    heading: "FAQs",
    description: "Frequently asked questions to solve your doubts easily",
    buttonText: "Learn More",
  },
];