import React from 'react'
import styles from './BatteryDetailsPage.module.css'
import SubHader from '../../components/SubHader/SubHader'
import back from '../../assets/store/back.png'
import BatteryCard from '../../components/BatteryCard/BatteryCard'
import BatteryPrice from '../../components/BatteryPrice/BatteryPrice'
import Specification from '../../components/Specification/Specification'
import Features from '../../components/Features/Features'
import Faqs from '../../components/Faqs/Faqs'
import Mpower from '../../assets/battery/Mpower.png'

function BatteryDetailsPage() {
  return (
    <>
      <SubHader />
      <div className={styles.container}>
        <div className={styles.top}>

          <div className={styles.back}>
            <img src={back}></img>
          </div>
          <div>

            <span className={styles.backText}>   Back to all</span>

            <p className={styles.desc}>  <span>Batteries</span> / mPower 6S 25200 mAh Lithium </p>
          </div>



        </div>
        <hr />

        <section className={styles.baterySection}>
          <BatteryCard />
          <BatteryPrice />
        </section>

        {/* <section>
          <Specification />
        </section>
        <section>
          <Features />
        </section> */}

      </div>

      <section>
        <Faqs />
      </section>
      <div className={styles.details}>


        <h2 className={styles.heading}>Sold By -</h2>

        <div className={styles.Mpower}>
          <img src={Mpower}></img>
        </div>

      </div>
    </>
  )
}

export default BatteryDetailsPage