import React from 'react'
import styles from './index.module.css'
import DateRangeIcon from '@mui/icons-material/DateRange'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PlaceIcon from '@mui/icons-material/Place'

const UpcomingJobs = () => {
  return (
    <div className={styles.JobRequestContainer}>
      <h3 className={styles.heading}> Upcoming Jobs</h3>
      {upcomingJobtapi?.map((info) => {
        return (
          <div className={styles.jobBox}>
            <div className={styles.leftSide}>
              <h3 className={styles.jobName}>{info?.jobName}</h3>
              <p className={styles.jobDays}>
                {info?.iconCal} {info?.dateDetails}
              </p>
              <p className={styles.jobDays}>
                {info?.Iconloc} {info?.clientAdd}
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default UpcomingJobs


const upcomingJobtapi = [
  {
    id: 1,
    jobName: 'Aerial Photography',
    iconCal: <DateRangeIcon />,
    dateDetails: '12 June’23  - 16 June’23',
    Iconloc: <PlaceIcon />,
    clientAdd:
      'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
  },
  {
    id: 2,
    jobName: 'School Photography',
    iconCal: <DateRangeIcon />,
    dateDetails: '16 August’23  - 16 August’23',
    Iconloc: <PlaceIcon />,
    clientAdd:
      'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
  },
  {
    id: 3,
    jobName: 'Aerial School Photography',
    iconCal: <DateRangeIcon />,
    dateDetails: '10 September’23  - 12 September’23',
    Iconloc: <PlaceIcon />,
    clientAdd:
      'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
  },
]
