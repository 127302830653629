import React, { useEffect, useState } from 'react'
import styles from "./index.module.css"
import imgDesk from "../../assets/NewHomePage/DesktopBanner.png"
import imgTab from "../../assets/NewHomePage/TabBanner.png"
import imgMob from "../../assets/NewHomePage/MobileBanner.png"
import imgDesk1 from "../../assets/NewHomePage/DesktopBanner1.png"
import imgDesk2 from "../../assets/NewHomePage/DesktopBanner2.png"
import imgDesk3 from "../../assets/NewHomePage/DesktopBanner3.png"
import imgTab1 from "../../assets/NewHomePage/TabBanner1.png"
import imgTab2 from "../../assets/NewHomePage/TabBanner2.png"
import imgTab3 from "../../assets/NewHomePage/TabBanner3.png"
import imgMob1 from "../../assets/NewHomePage/MobileBanner1.png"
import imgMob2 from "../../assets/NewHomePage/MobileBanner2.png"
import imgMob3 from "../../assets/NewHomePage/MobileBanner3.png"
import newdesk1 from "../../assets/NewHomePage/Banner9.jpg"
import newdesk2 from "../../assets/NewHomePage/Banner6.jpg"
import MultiCarousel from "../MultiCarousel/MultiCarousel";
import { api_call } from '../../Utils/Network'

function HomeBanner() {
    const[bannerdata, setbannerdata] = useState();

    const handleRoute = (bannerItem) => {
        console.log(bannerItem, "hariBaneerrr")
        
        

    };

    useEffect(() =>{
        gethomebanner();
    }, [])

    const gethomebanner = () =>{
        api_call
        .get(`base/banners`)
        .then((res) => {
            console.log(res?.data?.data, "gethomebanner" );
            if(res?.status == 200 || res?.status == 201 || res?.status == 204){
                setbannerdata(res?.data?.data)

            }
        })
        .catch((err) =>{
            console.log(err);
        })
    }
    return (
        <>
            <div className={styles.deskContainer}>
                <MultiCarousel cardsPerSlide={1} arrows={true}>
                    {bannerdata?.map((banner, index) => {
                        return (
                            <div
                                className={styles.heroContainer}
                                style={{ backgroundImage: `url(${banner?.web})`, cursor: "pointer" }}
                                key={index}
                                onClick={() => {
                                    handleRoute(banner?.web);
                                }}
                            ></div>
                        );
                    })}
                </MultiCarousel>
            </div>

            <div className={styles.tabBannerContainer}>
                <MultiCarousel cardsPerSlide={1} arrows={true}>
                    {bannerdata?.map((banner, index) => {
                        return (
                            <div
                                className={styles.tabBannerItem}
                                style={{ backgroundImage: `url(${banner?.tab})`, cursor: "pointer" }}
                                key={index}
                                onClick={() => { handleRoute(banner) }}
                            ></div>
                        );
                    })}
                </MultiCarousel>
            </div>
            <div className={styles.mobBannerCon}>
                <MultiCarousel cardsPerSlide={1} arrows={true}>
                    {bannerdata?.map((banner, index) => {
                        return (
                            <div style={{ width: "100%" }} key={index}>
                                <div
                                    className={styles.firstImgCon}
                                    style={{ backgroundImage: `url(${banner?.mobile})`, cursor: "pointer" }} onClick={() => { handleRoute(banner) }}
                                ></div>
                            </div>
                        );
                    })}
                </MultiCarousel>
            </div>
        </>
    )
}

export default HomeBanner

const bannerData = [
    {
        id: 1,
        status_str: "Active",
        redirection_str: "Drones",
        web:newdesk1,
        tab: imgTab,
        mobile: imgMob,
        alt_text: "Drones 1",
        redirection: 3,
        status: 1,
        index: 0,
        detail: {
            id: 1,
            slug: "Drone_1",
            title: "Drone_1"
        }
    },
    {
        id: 2,
        status_str: "Active",
        redirection_str: "Drones",
        web: newdesk2,
        tab: imgTab1,
        mobile: imgMob1,
        alt_text: "Drones 1",
        redirection: 3,
        status: 1,
        index: 0,
        detail: {
            id: 2,
            slug: "Drone_1",
            title: "Drone_1"
        }
    },
    // {
    //     id: 3,
    //     status_str: "Active",
    //     redirection_str: "Drones",
    //     web: imgDesk2,
    //     tab: imgTab2,
    //     mobile: imgMob2,
    //     alt_text: "Drones 1",
    //     redirection: 3,
    //     status: 1,
    //     index: 0,
    //     detail: {
    //         id: 3,
    //         slug: "Drone_1",
    //         title: "Drone_1"
    //     }
    // },
    // {
    //     id: 4,
    //     status_str: "Active",
    //     redirection_str: "Drones",
    //     web: imgDesk3,
    //     tab: imgTab3,
    //     mobile: imgMob3,
    //     alt_text: "Drones 1",
    //     redirection: 3,
    //     status: 1,
    //     index: 0,
    //     detail: {
    //         id: 4,
    //         slug: "Drone_1",
    //         title: "Drone_1"
    //     }
    // },
]