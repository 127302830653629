import React from 'react'
import { Outlet } from 'react-router-dom'

export  function DASLayout() {
  return (
    <Outlet />
  )
}

export  function AccountOverviewLayout() {
  return(
    <Outlet />
  )
}

export function AccountOverviewLayoutSide(){
  return(
    <Outlet />
  )
}