import React from 'react'
import styles from './index.module.css'
import DateRangeIcon from '@mui/icons-material/DateRange'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PlaceIcon from '@mui/icons-material/Place'

const JobRequest = () => {
  return (
    <div className={styles.JobRequestContainer}>
      <h3 className={styles.heading}> Job Request</h3>
      {JobRequestapi?.map((info) => {
        return (
          <div className={styles.jobBox}>
            <div className={styles.leftSide}>
              <div className={styles.boxHeading}>
                <h3 className={styles.jobName}>{info?.jobName}</h3>
                <h3 className={styles.jobName}> Budget :{info?.clientPaying}</h3>
              </div>
              <p className={styles.jobDays}>
                {info?.iconCal} {info?.dateDetails}
              </p>
              <p className={styles.jobtiming}>
                {info?.iconclock} {info?.forTimeSlot}
                <sapn className={styles.userRequierment}>
                  {info?.workFormat}
                </sapn>
              </p>
              <p className={styles.jobDays}>
                {info?.Iconloc} {info?.clientAdd}
              </p>
            </div>
            <div className={styles.rightSide}>
              <h3 className={styles.jobName}> Budget :{info?.clientPaying}</h3>
              <div className={styles.btnGroup}>
                <button className={styles.declineBtn}>Decline</button>
                <button className={styles.acceptBtn}> Accept</button>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default JobRequest

const JobRequestapi = [
  {
    id: 1,
    jobName: 'Aerial Photography',
    iconCal: <DateRangeIcon />,
    dateDetails: '12 June’23  - 16 June’23',
    iconclock: <AccessTimeIcon />,
    forTimeSlot: '05 Hrs ',
    workFormat: 'Daily',
    Iconloc: <PlaceIcon />,
    clientAdd:
      'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
    clientPaying: '₹ 2,500.00',
  },
  {
    id: 2,
    jobName: 'School Photography',
    iconCal: <DateRangeIcon />,
    dateDetails: '16 August’23  - 16 August’23',
    iconclock: <AccessTimeIcon />,
    forTimeSlot: '08 Hrs ',
    workFormat: 'Customize',
    Iconloc: <PlaceIcon />,
    clientAdd:
      'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
    clientPaying: '₹ 1,500.00',
  },
  {
    id: 3,
    jobName: 'Aerial School Photography',
    iconCal: <DateRangeIcon />,
    dateDetails: '10 September’23  - 12 September’23',
    iconclock: <AccessTimeIcon />,
    forTimeSlot: '02 Hrs ',
    workFormat: 'Daily',
    Iconloc: <PlaceIcon />,
    clientAdd:
      'Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016',
    clientPaying: '₹ 4,500.00',
  },
]
