import React from 'react'
import styles from './Video.module.css'
import video from '../../assets/store/video.mp4'
function Video() {
  return (
    <div className={styles.Con}>
    <div className={styles.videoCon}>

        <video src={video} controls>
        </video>
    </div>
    </div>)
}

export default Video