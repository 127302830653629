import React from "react";
import styles from "./index.module.css";
// import lectOne from "../../assets/images/lec-1.png";
// import lectTwo from "../../assets/images/lec-2.png";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import GroupsIcon from "@mui/icons-material/Groups";
import Share from "../../components/Share/Share";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";

function LecturesCard({ info, index, likeValues, likebtnHandel })  {
  // const [likeValues, setLikeValues] = useState({});

  // const likebtnHandel = (index) => {
  //   setLikeValues((prevState) => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //   }));
  // };

  const likeValue = likeValues[index] || false;
  return (
    // <div className={styles.LecturesCardContainer}>
    //   {lecturesData?.map((info, index) => {
    //     const likeValue = likeValues[index] || false;
    //     return (
          <div className={styles.card} key={info?.id}>
            <div className={styles.lecImg}>
              <img src={info?.lecImg} alt="" />
            </div>
            <div className={styles.infoBox}>
              <h3 className={styles.lecturesTitle}>{info.lecTitel}</h3>
              <h6 className={styles.techarName}>{info.techarName}</h6>
              <div className={styles.innerBox}>
                <p className={styles.infoIcontext}>
                  <sapn>{info?.dateIcon}</sapn>
                  {info?.lecdate}
                </p>
                <p className={styles.infoIcontext}>
                  <sapn>{info?.seatsIcon}</sapn>
                  {info?.seats} seats
                </p>
                <p className={styles.infoIcontext}>
                  <sapn>{info?.clockIcon}</sapn>
                  {info?.hours} hr
                </p>
              </div>
              <div className={styles.btnGroup}>
                <div className={styles.droneLikeShare}>
                  <button
                    className={styles.likeBtn}
                    onClick={() => likebtnHandel(index)}
                  >
                    {likeValue ? (
                      <FavoriteIcon style={{ color: "red" }} />
                    ) : (
                      <FavoriteBorderOutlinedIcon />
                    )}
                    {/* <FavoriteBorderOutlinedIcon /> */}
                  </button>
                  <Share />
                </div>
                <button className={styles.register}>Register</button>
              </div>
            </div>
          </div>
    //     );
    //   })}
    // </div>
  );
}

export default LecturesCard;

// const lecturesData = [
//   {
//     id: 1,
//     lecImg: lectOne,
//     lecTitel: "aerial intelligence and its evolution ",
//     techarName: "Mr. Amit Shah",
//     lecdate: "3 May 2023",
//     dateIcon: <CalendarMonthIcon />,
//     hours: 1,
//     clockIcon: <AccessTimeIcon />,
//     seats: "70",
//     seatsIcon: <GroupsIcon />,
//   },
//   {
//     id: 2,
//     lecImg: lectTwo,
//     lecTitel: "aerial intelligence ",
//     techarName: "Mr. Amit",
//     lecdate: "5 May 2023",
//     dateIcon: <CalendarMonthIcon />,
//     hours: 5,
//     clockIcon: <AccessTimeIcon />,
//     seats: "80",
//     seatsIcon: <GroupsIcon />,
//   },
//   {
//     id: 3,
//     lecImg: lectOne,
//     lecTitel: "aerial evolution ",
//     techarName: "Mr. Shah",
//     lecdate: "3 May 20231",
//     dateIcon: <CalendarMonthIcon />,
//     hours: 2,
//     clockIcon: <AccessTimeIcon />,
//     seats: "100",
//     seatsIcon: <GroupsIcon />,
//   },
// ];
