import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from './StoreSlider.module.css'
import StoreCards from '../StoreCards/StoreCards';

import store1 from '../../assets/store/store1.png'
import store2 from '../../assets/store/store2.png'

function CustomPrevArrow(props) {
    const { onClick } = props;
    return (
        <button
            // className="custom-arrow-banner custom-prev-arrow-banner"
            className={`${styles.customarrow} ${styles.customprevarrow}`}
            onClick={onClick}
        >
            <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
        </button>
    );
}


function CustomNextArrow(props) {
    const { onClick } = props;
    return (
        <button
            // className="custom-arrow-banner custom-next-arrow-banner"
            className={`${styles.customarrow} ${styles.customnextarrow}`}
            onClick={onClick}
        >
            <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
        </button>
    );
}

var settings = {
    className: styles.slickstyles,
    dots: true,
    // autoplay: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    // fade: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};
function StoreSlider() {
    return (
        <>
            <div className={styles.container}>
                <Slider {...settings}>
                    {
                        data.map((item) => {
                            return (
                                <StoreCards data={item} />
                            )
                        })
                    }


                </Slider>

            </div>
        </>
    )
}

export default StoreSlider
const data = [
    {
        id: 1,
        img: store1,
    },
    {
        id: 2,
        img: store2,
    },

]