import React from 'react'
import styles from './index.module.css'

function ExclusiveEbooks({eBooks}) {
    console.log(eBooks,"ebooks");
  return (
    <div className={styles.ExclusiveEbooksCard} key={eBooks[0]?.id}>
        <div className={styles.ebookDesc}>
            <h2 className={styles.heading}>{eBooks[0]?.title}</h2>
            <h4 className={styles.subHeading}>{eBooks[0]?.subTitle}</h4>
            <p>{eBooks[0]?.desc}</p>
        </div>
        <div className={styles.bookImg}>
            <img src={eBooks[0]?.bookImg} alt=''/>
        </div>
    </div>
  )
}

export default ExclusiveEbooks