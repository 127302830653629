import React from "react";
import styles from "./index.module.css";
import profilePic from "../../../assets/CouresDetile/TrainerProfile.png";

function TarinerCards({ data }) {
  return (
    <>
      {data?.map((v, i) => {
        return (
          <div className={styles.CardContainer}>
            <div className={styles.topContainer}>
              <div className={styles.profileDetails}>
                <div className={styles.leftside}>
                  <div className={styles.imgContainer}>
                    <img src={v?.image ? v?.image : v?.default_avatar } alt="pic" />
                  </div>
                  <div>
                    <h3>{v?.name}</h3>
                    <h4>{v?.qualification}</h4>
                  </div>
                </div>
                <div>
                  <h3>{v?.experience} years experience</h3>
                </div>
              </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: v?.about }}></div>
          </div>
        );
      })}
    </>
  );
}

export default TarinerCards;
