import React, { useState, useRef } from "react";
import styles from "./index.module.css";
import logo from "../assets/images/loginPageLogo.png";
import logoMobile from "../assets/images/logo-s.png";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import bgImage from "../assets/images/loginpagebg.png";
// import GetOTP from "./GetOTP/GetOTP";
import { api_call, setToken, base_url } from "../Utils/Network";
import { type } from "@testing-library/user-event/dist/type";
import { useNavigate } from "react-router-dom";

function Login() {
  const [tabValue, setTabValue] = useState("1");
  const [showPass, setShowPass] = useState(false);
  const [changeOtp, setChangeOtp] = useState(false);
  const [changeregOtp, setChangeregOtp] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [isPending, setisPending] = useState(false);
  const list = [...Array(6).keys()];
  const inputRef = useRef([]);
  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    phone: null,
    otp: [],
    isd_code: "",
  });

  const [inputRegidata, setInputRegiData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: null,
    user_type: null,
    // otp: [],
    // isd_code:"",
  });
  const [loginErr, setLoginErr] = useState({
    loginErrmsg: "",
    loginStatus: false,
  });

  const [otpError, setOtpErr] = useState({
    otpErrMsg: "",
    otpErrStatus: false,
  });

  const [regFnameErr, setRegFnameErr] = useState({
    fnameErrMsg: "",
    fnameErrStatus: false,
  });

  const [regLnameErr, setRegLnameErr] = useState({
    lnameErrMsg: "",
    lnameErrStatus: false,
  });

  const [regEmailErr, setRegEmailErr] = useState({
    emailErrMsg: "",
    emailErrStatus: false,
  });

  const [userExists ,setUserExists] = useState({
    userExistsMsg:"",
    userExistsStatus:false,
  })
  // const [inputData, setInputData] = useState({
  //   phone: null,
  //   otp: [],
  //   isd_code: "",
  // });
  const handler = (e, idx) => {
    const next = inputRef;
    const { value } = inputRef.current[idx];
    var otp_number = [...inputData.otp];
    otp_number[idx] = value;
    // const abc[idx] = value
    setInputData({ ...inputData, otp: otp_number });
    if (value?.length !== 0 && idx < 5 && next) {
      next.current[idx + 1].focus();
    }
  };

  const handleKeyChange = (e, idx) => {
    const next = inputRef;
    if (idx == 6) {
      return null;
    }
    if (idx == 0 && e.key !== "Backspace" && e.key !== "Delete") {
      return null;
    }
    if (e?.key == "Backspace" || e?.key == "Delete") {
      if (idx == 0) {
      } else {
        next?.current[idx - 1].focus();
      }
    }
  };
  const handleRegisterChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue =
      type === "radio" ? value : type === "checkbox" ? checked : value;

    setInputRegiData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
    const inputValue = event.target.value;
    // const regex = /^\d{10}$/;
    const regex = /^[6789]\d{9}$/;
    if (name === "phone") {
      if (regex.test(inputValue)) {
        setInputRegiData({ ...inputRegidata, phone: inputValue });
        setPhoneErr(false);
      } else {
        setPhoneErr(true);
        setInputRegiData({ ...inputRegidata, phone: inputValue });
      }
    }
    if (name === "email") {
      const regex = /^[^@]+@[^@]+\.[^@]+$/;
      if (regex.test(inputValue)) {
        // setInputRegiData({ ...inputRegidata, email: inputValue });
        setEmailErr(false);
      } else {
        setEmailErr(true);
        // setInputRegiData({ ...inputRegidata, email: inputValue });
      }
    }
    if (name === "first_name") {
      if (value.trim()) {
        setFirstNameErr(false)
        setRegFnameErr({
          fnameErrMsg: "",
          fnameErrStatus: false,
        });
      } else {
        setFirstNameErr(true)
        setRegFnameErr({
          fnameErrMsg: "First Name is required",
          fnameErrStatus: true,
        });
      }
    }
    if (name === "last_name") {
      if (value.trim()) {
        setLastNameErr(false)
        setRegLnameErr({
          lnameErrMsg: "",
          lnameErrStatus: false,
        });
      } else {
        setLastNameErr(true)
        setRegLnameErr({
          lnameErrMsg: "Last Name is required",
          lnameErrStatus: true,
        });
      }
    }

  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setChangeOtp(false);
    setChangeregOtp(false);
    setInputRegiData({
      first_name: "",
      last_name: "",
      email: "",
      phone: null,
      user_type: null,
    });
  };

  const handleValidation = (type) => {
    if (type === "number") {
    }
    if (type === "otp") {
    }
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^\d{10}$/;
    if (regex.test(inputValue)) {
      setInputData({ ...inputData, phone: inputValue });
      setPhoneErr(false);
    } else {
      setPhoneErr(true);
      setInputData({ ...inputData, phone: inputValue });
    }
  };

  const handleMobileverify = (e) => {
    e.preventDefault();

    setisPending(true);
    const _data = {
      phone: inputData.phone,
    };
    // fetch(`${base_url}/auth/v1/student/verify_user/`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(_data),
    // })
    api_call
      .post(
        `${base_url}auth/v1/student/generate_otp/?phone=${_data?.phone}&isd_code=91`
      )
      .then((response) => {
        const { status } = response;
        if (status === 204 || status === 200) {
          // handleGenerate();
          setChangeOtp(true);
          setLoginErr({
            loginErrmsg: "",
            loginStatus: false,
          });
        }

        if (status == 400 || status === 404 || status == 401) {
          setLoginErr({
            loginErrmsg:
              "This number is not registered with Aerobott, redirecting to register",
            loginStatus: true,
          });
          setisPending(false);

          setTimeout(() => {
            setLoginErr({
              loginErrmsg: "",
              loginStatus: false,
            });
            // setView(3);
            setTabValue("2");
          }, 3000);
        }
      })
      .catch((error) => {
        setLoginErr({
          loginErrmsg:
            "This number is not registered with Aerobott, redirecting to register",
          loginStatus: true,
        });
        setisPending(false);
      });
  };
  const handleGenerate = () => {
    setisPending(true);
    const _data = {
      isd_code: 91,
      phone: inputData.phone,
    };
    api_call
      .post(
        `auth/v1/student/generate_otp/?phone=${_data?.phone}&isd_code=${_data?.isd_code}`
      )
      .then((response) => {
        setisPending(false);

        const { status } = response;
        if (status === 204 || status === 200) {
          // setView(2);
          setChangeOtp(true);
          setLoginErr({ loginErrMsg: "", loginStatus: false });
        }

        if (status == 400) {
          setLoginErr({
            loginErrmsg:
              "This number is already registered as an Institute / Admin. Please enter another number to proceed.",
            loginStatus: true,
          });
        }
      })
      .catch((error) => {
        setisPending(false);
        setLoginErr({
          loginErrmsg:
            "This number is already registered as an Institute / Admin. Please enter another number to proceed.",
          loginStatus: true,
        });
      });
  };
  const handleVerify = (e) => {
    e.preventDefault();
    var otpString = parseInt(inputData.otp.join(""));
    const _data = {
      isd_code: parseInt("91"),
      phone: parseInt(inputData.phone),
      otp: parseInt(otpString),
    };
    console.log(_data, "verifyotp");
    fetch(
      `${base_url}auth/v1/student/validate_otp/?phone=${_data?.phone}&isd_code=${_data?.isd_code}&otp=${_data?.otp}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(_data),
      }
    )
      // api_call
      //   .post(`${base_url}auth/v1/student/validate_otp/?phone=${_data?.phone}&isd_code=${_data?.isd_code}&otp=${_data?.otp}`)
      .then((response) => response.json())
      .then((datas) => {
        const { token, user } = datas.data;
        console.log(user, "uswertoken");
        if (user) {
          // localStorage.removeItem("fcmtoken");
          localStorage.setItem("aerobott_token", JSON.stringify(token?.access));
          localStorage.setItem("aerobott_user", JSON.stringify(user));

          // setToken(token.access);
          // setView(1);
          setInputData({
            phone: null,
            otp: [],
            isd_code: "",
          });
          return navigate("/");
          //     // setOpen(false);
          //     dispatch(handleLoginUser(token.access));
          //     if (redirect == true) {
          //       handleRedirect();
          //     }
          //     handleName();
        }

        setOtpErr({
          otpErrMsg: "Please Enter Valid OTP",
          otpErrStatus: true,
        });
      })
      .catch((err) => {
        console.log(err, "OTPErr");
        setOtpErr({
          otpErrMsg: "Please Enter Valid OTP",
          otpErrStatus: true,
        });
      });
  };

  ////////////////////////// new user register /////////////////////////

  const handleNewUserRegister = (e) => {
    e.preventDefault();
    if(firstNameErr || lastNameErr || emailErr ||phoneErr){
      console.log("validation error");
      return;
    }
    console.log(inputRegidata, "inputRegidata");
    api_call
      .post(
        `${base_url}auth/v1/student/new_user_generate_otp/?phone=${inputRegidata.phone}&isd_code=91`
      )
      .then((res) => {
        const { status } = res;
        const {message} =res?.data?.data
        console.log(status, "regStatus");
        if (status == 200) {
          setChangeregOtp(true);
          setLoginErr({
            loginErrmsg: "",
            loginStatus: false,
          });
        }
        console.log(message);
        if (status == 400) {
          // alert("something went wrong");
          console.log(message);
          setUserExists({
            userExistsMsg:message,
            userExistsStatus:true
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleRegisterVerify = (e) => {
    e.preventDefault()
    var otpString = parseInt(inputData.otp.join(""));
    // console.log(inputRegidata, "handleRegisterVerify");
    // console.log(otpString, "handleRegisterVerifyotpString");
    // console.log(inputData.otp,);
    api_call
    .post(`${base_url}auth/v1/student/new_user_registration/?phone=${inputRegidata.phone}&isd_code=91&otp=${otpString}&user_type=${inputRegidata.user_type}&first_name=${inputRegidata.first_name}&last_name=${inputRegidata.last_name}&email=${inputRegidata.email}`)
    // .then((response) => response.json())
    .then((datas) => {
      const { token, user } = datas.data.data;
      console.log(datas?.data, "handleRegisterVerify");
      if(user){
        localStorage.setItem("aerobott_token", JSON.stringify(token?.access));
          localStorage.setItem("aerobott_user", JSON.stringify(user));

          setInputRegiData({
            first_name: "",
            last_name: "",
            email: "",
            phone: null,
            user_type: null,
          })
          return navigate("/");
      }
    })

    .catch((err) =>{
      console.log(err);
    })
  }

  ////////////////////////// end of new user///////////////////////////

  return (
    <>
      <section className={styles.MainContianer}>
        <div className={styles.leftSideContainer}>
          <img src={logo} alt="" />
          <h1 className={styles.mainPageHeading}>
            Start learning <br /> with Aerobott
          </h1>
        </div>
        <div className={styles.rightSideContainer}>
          <div className={styles.topLogoHeadingMobiles}>
            <img src={logoMobile} alt="" />
            <h1 className={styles.mainPageHeadingMobile}>
              Start learning with Aerobott
            </h1>
          </div>
          <div className={styles.autheticationBox}>
            <Box sx={{ width: "100%" }}>
              <TabContext value={tabValue}>
                <Box style={{ justifyContent: "center" }}>
                  <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                    centered
                    // variant="fullWidth"
                  >
                    <Tab label="Login" value="1" />
                    <Tab label="Register" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1" style={{ textAlign: "center" }}>
                  {/* <form
                    type="submit"
                    className={styles.loginForm}
                    onSubmit={(e) => handleMobileverify(e)}
                  > */}
                  {/* <h3 className={styles.formHeading}>Sign In</h3> */}
                  {!changeOtp && (
                    <form
                      type="submit"
                      className={styles.loginForm}
                      onSubmit={(e) => handleMobileverify(e)}
                    >
                      <h3 className={styles.formHeading}>Sign In</h3>
                      <TextField
                        required
                        id="outlined-basic"
                        error={phoneErr}
                        // type="text"
                        label="Phone Number"
                        variant="outlined"
                        // inputProps={{ maxLength: 10 }}
                        sx={{ width: "100%", marginBottom: "20px" }}
                        onChange={handleChange}
                        onBlur={() => handleValidation("number")}
                        // autoComplete="off"
                      />
                      <p style={{ color: "#B5182C", textAlign: "center" }}>
                        {loginErr?.loginStatus && loginErr?.loginErrmsg}
                      </p>
                      {phoneErr && (
                        <p style={{ color: "#B5182C", textAlign: "center" }}>
                          Please Enter a valid Phone Number
                        </p>
                      )}
                      <button
                        className={styles.submitBtn}
                        disabled={phoneErr || isPending}
                        // onClick={(e) => handleMobileverify(e)}
                      >
                        Sign In
                      </button>
                    </form>
                  )}
                  {changeOtp && (
                    // <GetOTP
                    //   onValidation={(result) =>
                    //     handleValidation("otp", result)
                    //   }
                    //   onVerify={handleVerify}
                    // />
                    <form
                      type="submit"
                      className={styles.loginForm}
                      onSubmit={(e) => handleVerify(e)}
                    >
                      <h3 className={styles.formHeading}>Sign In</h3>
                      <div className={styles.otpContainer}>
                        {list?.map((v, id) => (
                          <div key={id}>
                            <input
                              type="text"
                              key={v}
                              ref={(el) => (inputRef.current[v] = el)}
                              onChange={(e) => handler(e, v)}
                              onKeyUp={(e) => handleKeyChange(e, v)}
                              className="otp_box"
                              name={`otp_box${id}`}
                              maxLength={1}
                              onBlur={() => handleValidation("otp")}
                            />
                          </div>
                        ))}
                      </div>
                      <div className={styles.invalidepart}>
                        {/* <p className={styles.errorShow}>Invalid Otp</p> */}
                        <button className={styles.resendOtp}>Resend OTP</button>
                      </div>
                      <p style={{ color: "#B5182C", textAlign: "center" }}>
                        {otpError?.otpErrStatus && <>{otpError?.otpErrMsg}</>}
                      </p>
                      <button className={styles.submitBtn}> Login</button>
                    </form>
                  )}
                  {/* </form> */}
                </TabPanel>
                <TabPanel value="2" style={{ textAlign: "center" }}>
                  {/* <form className={styles.loginForm}>
                    <h3 className={styles.formHeading}>Register</h3> */}
                  {!changeregOtp && (
                    <form
                      className={styles.loginForm}
                      type="submit"
                      onSubmit={(e) => handleNewUserRegister(e)}
                    >
                      <h3 className={styles.formHeading}>Register</h3>
                      <TextField
                        required
                        id="outlined-basic"
                        error={firstNameErr}
                        label="First Name"
                        name="first_name"
                        placeholder="eg. Abc"
                        value={inputRegidata.first_name}
                        variant="outlined"
                        // type="text"
                        sx={{ width: "100%", marginBottom: "20px" }}
                        onChange={handleRegisterChange}
                      />
                      <p style={{ color: "#B5182C", textAlign: "center" }}>
                        {regFnameErr?.fnameErrStatus && <>{regFnameErr.fnameErrMsg}</>}
                      </p>
                      <TextField
                        required
                        id="outlined-basic"
                        error={lastNameErr}
                        label="Last Name"
                        name="last_name"
                        placeholder="eg. Pqr"
                        value={inputRegidata.last_name}
                        variant="outlined"
                        type="text"
                        sx={{ width: "100%", marginBottom: "20px" }}
                        onChange={handleRegisterChange}
                      />
                      <p style={{ color: "#B5182C", textAlign: "center" }}>
                        {regLnameErr?.lnameErrStatus && <>{regLnameErr.lnameErrMsg}</>}
                      </p>
                      <TextField
                        required
                        id="outlined-basic"
                        error={emailErr}
                        label="Email"
                        name="email"
                        placeholder="eg. abc@gmail.com"
                        value={inputRegidata.email}
                        variant="outlined"
                        type="email"
                        sx={{ width: "100%", marginBottom: "20px" }}
                        onChange={handleRegisterChange}
                      />
                      
                      <TextField
                        required
                        id="outlined-number"
                        label="Number"
                        error={phoneErr}
                        name="phone"
                        value={inputRegidata.phone}
                        variant="outlined"
                        type="number"
                        sx={{ width: "100%" }}
                        onChange={handleRegisterChange}
                      />

                      <div className={styles.registreationbox}>
                        <h5 className={styles.chooseHeading}>Register As</h5>

                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="user_type"
                          value={inputRegidata.user_type}
                          onChange={handleRegisterChange}
                          defaultValue={1}
                        >
                          {userinfo?.map((c) => {
                            return (
                              <FormControlLabel
                                value={c?.id}
                                key={c?.id}
                                control={<Radio />}
                                label={c?.user}
                              />
                            );
                          })}
                        </RadioGroup>
                      </div>
                      <p style={{ color: "#B5182C", textAlign: "center" }}>
                        {userExists?.userExistsStatus && <>{userExists?.userExistsMsg}</>}
                      </p>
                      <button className={styles.registerBtn}>Register</button>
                    </form>
                  )}
                  {changeregOtp && (
                    // <GetOTP
                    //   onValidation={(result) =>
                    //     handleValidation("otp", result)
                    //   }
                    //   onVerify={handleVerify}
                    // />
                    <form className={styles.loginForm} type="submit" onSubmit={(e) => handleRegisterVerify(e)}>
                      <h3 className={styles.formHeading}>OTP</h3>
                      <div className={styles.otpContainer}>
                        {list?.map((v, id) => (
                          <div key={id}>
                            <input
                              type="text"
                              key={v}
                              ref={(el) => (inputRef.current[v] = el)}
                              onChange={(e) => handler(e, v)}
                              onKeyUp={(e) => handleKeyChange(e, v)}
                              className="otp_box"
                              name={`otp_box${id}`}
                              maxLength={1}
                              onBlur={() => handleValidation("otp")}
                            />
                          </div>
                        ))}
                      </div>
                      <button className={styles.submitBtn}> send</button>
                    </form>
                  )}
                  {/* </form> */}
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;

const userinfo = [
  {
    id: 1,
    user: "Student",
  },
  {
    id: 2,
    user: "Pilot",
  },
  {
    id: 3,
    user: "Client",
  },
];
