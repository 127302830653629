import React from 'react'
import styles from './index.module.css'
import pilotimg from '../../assets/images/pilotImg.png'
import BackComponent from '../../components/BackButton/BackButton'
import SpecializationComponent from '../../components/SpecializationComponent/SpecializationComponent'

function pilotsDetails() {
  return (
    <>
      <section className={styles.pilotsDetailsContainer}>
        <div className={styles.forMobileChange}>
          <BackComponent />
          <div className={styles.showMobile}>
            <h2 className={styles.pilotName}>Arminal Luios</h2>
            <h4 className={styles.pilotLicence}>Licence No: #12345</h4>
          </div>
        </div>
        <div className={styles.pilotsProfile}>
          <div className={styles.pilotPic}>
            <img src={pilotimg} alt="abc" />
          </div>
          <div className={styles.pilotDesc}>
            <div className={styles.showDesktop}>
              <h2 className={styles.pilotName}>Arminal Luios</h2>
              <h4 className={styles.pilotLicence}>Licence No: #12345</h4>
            </div>
            <p className={styles.pilotPara}>
              Aerial and ground based Images and video, Commercial Real Estate ,
              Acreage, construction, real estate and ranches. Clients success is
              our success! Aerial and ground based Images and video, Commercial
              Real Estate , Acreage, construction, real estate and ranches.
              Clients success is our success!
            </p>
            <div className={styles.pilotTextBox}>
              <p className={styles.pilotText}>Avg Rate: ₹2000 / per hour</p>
              <p className={styles.pilotText}>Insurance Coverage: ₹1200.00</p>
              <p className={styles.pilotText}>Mumbai, maharashtra</p>

              <p className={styles.pilotText}>Owns a Drone: Yes</p>
            </div>

            <div className={styles.btnContainer}>
              <button className={styles.quoteBtn}>Get a Quote</button>
            </div>
          </div>
        </div>

        <SpecializationComponent
            specializesData={specializesData}
            // styles={styles}
        />
        {/* <div className={styles.specializes}>
          <h2 className={styles.pilotName}>Specializes in </h2>
          <div className={styles.specializeBoxs}>
            {specializesData.map((info) => (
              <div className={styles.specializeBox}>
                <p>{info.spcial}</p>
              </div>
            ))}
          </div>
        </div> */}
      </section>
    </>
  )
}

export default pilotsDetails

const specializesData = [
  {
    id: 1,
    spcial: 'Aerial School Photography',
  },
  {
    id: 2,
    spcial: 'Aerial Wedding Videography',
  },
  {
    id: 3,
    spcial: 'Construction Survey',
  },
  {
    id: 4,
    spcial: 'Aerial School Photography',
  },
  {
    id: 5,
    spcial: 'Construction Survey',
  },
]
