import * as React from 'react';
import styles from './index.module.css';

const ContactCard = (info) => { 
    return (
        // <div className={styles.d_flex}>
            <div className={styles.cookieCard}>
                <p className={styles.cookieHeading}>{info?.heading}</p>
                <p className={styles.cookieDescription}>{info?.description}</p>
                <button className={styles.acceptButton}>Learn More</button>
            </div>
            // {/* <div className={styles.cookieCard}>
            //     <p className={styles.cookieHeading}>How-to Guide</p>
            //     <p className={styles.cookieDescription}>Read through our drone material to get started with flying</p>
            //     <button className={styles.acceptButton}>Learn More</button>
            // </div>
            // <div className={styles.cookieCard}>
            //     <p className={styles.cookieHeading}>FAQs.</p>
            //     <p className={styles.cookieDescription}>Frequently asked questions to solve your doubts easily.</p>
            //     <button className={styles.acceptButton}>Learn More</button>
            // </div> */}
        // </div>

    
    )
}

export default ContactCard
