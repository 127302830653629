import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import imgdes from "../../assets/Service/serviceBanner.png";
import imgmob from "../../assets/Service/servicebannerM.png";
import FrequentlyAsked from "../../components/CouresDetailsComponente/Frequentlyasked/FrequentlyAsked";
import { useParams } from "react-router-dom";
import { api_call } from "../../Utils/Network";

function ServiceDetails() {
  const {slug} = useParams();
  const [details, setDetails] = useState();

  useEffect(() =>{
    if(slug) {
      servicelisting(slug);
    }
  }, [slug])

  const servicelisting = (slug) => {
    api_call
    .get(`drone_service/${slug}`)
    .then((res) => {
        console.log(res?.data);
        setDetails(res?.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }
  return (
    <>
      <section className={`${styles.bannerSection}`}>
        <img className={`${styles.imgDesc} `} src={details?.banner} />
        <img className={`${styles.imgMob} `} src={details?.banner} />
        <div className={`${styles.serviceName} ${styles.positionA}`}>
          <h1>{details?.title}</h1>
        </div>
      </section>
      <section className={styles.mainConatainer}>
        <div className={styles.descrptionContainer}>
          <h3 className={`${styles.sectionheading} ${styles.borderbottom}`}>
            Description
          </h3>
          <div className={styles.descriptionBox}  dangerouslySetInnerHTML={{__html : details?.description}}></div>
          {/* <p className={styles.descriptionBox}>
            An agricultural drone is an unmanned aerial vehicle used in
            agriculture operations, mostly in yield optimization and in
            monitoring crop growth and crop production.We provide agri spray for
            all types of crope at a very nominal price to enhance the
            productivity of farmers across India.
          </p> */}

          <div className={styles.faqCOntainer}>
            <h3 className={`${styles.sectionheading}`}>
              Frequently asked questions
            </h3>

            <FrequentlyAsked />
          </div>
        </div>

        <div className={styles.priceConatiner}></div>
      </section>
    </>
  );
}

export default ServiceDetails;
