import React from 'react'
import styles from './index.module.css'
import { da } from 'date-fns/locale';
import Rating from '@mui/material/Rating';
import { useNavigate } from 'react-router-dom';

function DroneasService({ data }) {
  const navigate = useNavigate();
  console.log(data, "DroneasService");

  const handleserviceNav = (slug) => {
    navigate(`/service/${slug}`)
  }
  return (
    <>
      {/* <div>DroneasService</div> */}
      <div className={styles.cardContianer}>
        <img src={data?.banner} alt={data?.title} />
        <div className={styles.detailsContainer}>
            <div className={styles.priceContainer}>
              <p>
                Starting at <br /><span> ₹200 </span> per acre
              </p>
              <button className={styles.bookNow} onClick={() => handleserviceNav(data?.slug)}> BOOK NOW</button>
            </div>
            <div style={{background:"#fff", padding:"10px", boxSizing:"border-box"}}>
              <div className={styles.servicePRating}>
                <h5>{data?.serviceType}</h5>
                <p><Rating  readOnly size="small" name="half-rating" defaultValue={data?.ratings} precision={0.5} max={1} /> {data?.ratings}</p>
              </div>
              <h3 className={styles.serviceTitle}>{data?.title}</h3>
              {/* <p className={styles.serviceDecs}>{data?.decs}</p> */}
              <div className={styles.serviceDecs}  dangerouslySetInnerHTML={{__html : data?.description}}></div>
            </div>
        </div>
      </div>
    </>
  )
}

export default DroneasService